import { merge } from 'lodash';

const env = process.env.NODE_ENV || 'development';

interface Config {
  API_URL: string;
  COLORS: {
    PRIMARY: string;
    PRIMARY_DARK: string;
    PRIMARY_CONTRAST: string;
    SECONDARY: string;
    SECONDARY_CONTRAST: string;
    BACKGROUND_DARK: string;
  };
  ORIGIN: string;
  SPLASH_SCREEN_LOGO?: string;
}

interface CustomWindow extends Window {
  // add you custom properties and methods
  CONFIG: Config;
}
declare const window: CustomWindow;

const defaults: Config = {
  API_URL: 'http://localhost:2000',
  COLORS: {
    PRIMARY: '#ba0c30',
    PRIMARY_CONTRAST: '#ffffff',
    PRIMARY_DARK: '#5d0605',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#354654'
  },
  ORIGIN: window.location.origin
};

if (env === 'development') {
  defaults.ORIGIN = 'http://127.0.0.1:5021';
}

const config = merge<Config, Config>(defaults, window.CONFIG);

export default config;

export const COLORS = config.COLORS;
