import { useEffect, useState } from 'react';
import { PageContainer } from 'components';
import { Alert, Button, CircularProgress, Grid, Typography } from '@mui/material';
import subscriptionService from '../../services/subscription.service';
import Subscription from 'types/Subscription';
import { useTranslation } from 'react-i18next';
import campaignService from 'services/campaign.service';
import Campaign from 'types/Campaign';
import SubscriptionCard from './SubscriptionCard/SubscriptionCard';
import logger from 'logger/logger';
import useAlert from 'hooks/useAlert';
import FlexBox from 'components/FlexBox/FlexBox';
import PaperCard from 'components/PaperCard/PaperCard';
import theme from 'styles/theme';
import LogoPaperHeader from 'components/LogoPaperHeader/LogoPaperHeader';
import PaperContent from 'components/PaperContent/PaperContent';
import useIsMobile from 'hooks/useIsMobile';
import gaService from 'services/ga.service';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import OrderSubscriptionState from 'types/OrderSubscriptionState';
import Order, { NewOrder } from 'types/Order';
import routes from 'navigation/routes';
import orderService from 'services/order.service';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useQuery from 'hooks/useQuery';
import analyticsService from 'services/analytics.service';
import styles from './styles';
import UnhandledOrder from './UnhandledOrder';
import { Stack } from '@mui/system';

const OrderSubscriptionPage = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const isMobile = useIsMobile();
  const { addAlert } = useAlert();
  const locationState = useLocation().state as OrderSubscriptionState;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const returnUrl = searchParams.get('returnUrl') || locationState?.returnUrl;
  const user = useSelector((state: RootState) => state.user);
  const [unhandledOrder, setUnhandledOrder] = useState<Order>();
  const newsroomApp = useSelector((state: RootState) => state.newsroomApp);

  const { t } = useTranslation();

  const query = useQuery();
  const returnUrlParam = query.get('returnUrl');
  const campaignUuidParam = query.get('campaignUuid');
  const subscriptionIdParam = query.get('subscriptionId');

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (subscriptionIdParam && !locationState) {
      getSubscriptionFromParamsInit();
    } else {
      getSubscriptionsInit();
    }
  };

  const getSubscriptionsInit = async (_locationState?: OrderSubscriptionState) => {
    setLoading(true);

    try {
      // First handle potential unhandled vipps order
      if (user) {
        const { data: unhandledOrder } = await orderService.userHasUnhandleOrder();
        if (unhandledOrder && unhandledOrder.paymentSolution === 'vipps') {
          setUnhandledOrder(unhandledOrder);
        } else {
          await setupOrder(_locationState);
        }
      } else {
        await setupOrder(_locationState);
      }
    } catch (error) {
      logger.error('Get subscriptions failed', error);
      addAlert('error', t('alerts.somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  const setupOrder = async (_locationState?: OrderSubscriptionState) => {
    if (locationState && locationState?.selectedSubscription) {
      createOrder(locationState.selectedSubscription, locationState.selectedCampaign);
    } else if (_locationState && _locationState?.selectedSubscription) {
      createOrder(_locationState.selectedSubscription, _locationState.selectedCampaign);
    } else {
      const ps = [];

      ps.push(subscriptionService.getSubscriptions());
      ps.push(campaignService.get());

      const res = await Promise.all(ps);
      const subscriptionRes = res[0].data;
      const campaignRes = res[1].data;

      subscriptionRes.map((subscription: Subscription) => {
        subscription.campaigns = campaignRes.filter((campaign: Campaign) => {
          return campaign.subscriptionId === subscription.id;
        });
      });

      setSubscriptions(res[0].data);

      gaService.gtagEvent('nrs_purchase_started', {
        nrs_purchase_return_url: returnUrl,
        nrs_purchase_started: 1
      });
      analyticsService.event('purchase-started', { s1: returnUrl });
    }
  };

  const getSubscriptionFromParamsInit = async () => {
    setLoading(true);
    try {
      const { data: subscriptionRes } = await subscriptionService.getActiveSubscription(
        parseInt(subscriptionIdParam)
      );

      let campaignRes: Campaign;
      if (campaignUuidParam) {
        const { data } = await campaignService.getCampaign(campaignUuidParam);
        campaignRes = data;
      }

      const newLocationState: OrderSubscriptionState = {
        returnUrl: returnUrlParam || null,
        selectedSubscription: subscriptionRes,
        selectedCampaign: campaignRes || null
      };

      if (user) {
        getSubscriptionsInit(newLocationState);
      } else {
        navigate(routes.LOGIN.path, { state: newLocationState });
      }
    } catch (error) {
      console.error(error);
      getSubscriptionsInit();
    } finally {
      setLoading(false);
    }
  };

  const createOrder = async (subscription: Subscription, campaign: Campaign) => {
    setLoading(true);
    try {
      if (user) {
        // Create order then navigate to payment

        const newOrder: NewOrder = {
          campaignId: campaign ? campaign.id : undefined,
          subscriptionId: subscription ? subscription.id : undefined,
          type: 'SUBSCRIPTION'
        };

        const { data: order } = await orderService.add(newOrder);

        if (order.status === 'COMPLETED') {
          navigate(`${routes.ORDER_CONFIRMATION.path.replace(':uuid', order.uuid.toString())}`, {
            state: {
              returnUrl: returnUrl
            }
          });
        } else {
          navigate(
            `${routes.ORDER_SUBSCRIPTION_PAYMENT.path.replace(':uuid', order.uuid.toString())}`,
            {
              state: {
                returnUrl: returnUrl
              }
            }
          );
        }
      } else {
        navigate(routes.LOGIN.path, {
          state: {
            selectedSubscription: subscription,
            selectedCampaign: campaign,
            returnUrl
          } as OrderSubscriptionState
        });
      }
    } catch (error) {
      // TODO: give user error message
      logger.error('Could not create order', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer
      centerHorizontal
      centerVertical
      footerLight
      sx={styles.styledPageContainer}
      sxPageContent={{
        [theme.breakpoints.up('sm')]: {
          padding: '16px'
        }
      }}
      pageCard
    >
      <PaperCard pageCard sx={styles.styledPaperCard}>
        <LogoPaperHeader />
        <PaperContent sx={styles.styledPaperContent}>
          {!newsroomApp && (
            <>
              {unhandledOrder && <UnhandledOrder order={unhandledOrder} returnUrl={returnUrl} />}
              {!unhandledOrder && (
                <>
                  <FlexBox direction='column' alignItems='center' gap={8} sx={styles.styledTitle}>
                    <Typography variant={isMobile ? 'h4' : 'h3'}>
                      {t('subscription.ourSubscription')}
                    </Typography>
                  </FlexBox>
                  <Grid container spacing={2} flex={1} justifyContent='center' alignItems='center'>
                    {!loading &&
                      subscriptions.map((subscription: Subscription) => (
                        <Grid item key={subscription.id}>
                          <SubscriptionCard
                            subscription={subscription}
                            onSubscriptionSelect={createOrder}
                          />
                        </Grid>
                      ))}
                    {loading && (
                      <FlexBox justifyContent='center' sx={{ padding: '16px' }}>
                        <CircularProgress />
                      </FlexBox>
                    )}
                  </Grid>
                </>
              )}
            </>
          )}
          {newsroomApp && (
            <Stack gap={2} justifyContent={'center'} sx={{ flex: 1 }}>
              <Alert severity='info'>{t('common.cantBuySubscriptionInApp')}</Alert>
              <Button onClick={() => {
                navigate(routes.PROFILE.path);
              }} variant='outlined'>{t('navigation.backToMyPage')}</Button>
            </Stack>
          )}
        </PaperContent>
      </PaperCard>
    </PageContainer>
  );
};

export default OrderSubscriptionPage;