import Skin from 'types/Skin';

const vestnesavisa: Skin = {
  COLORS: {
    PRIMARY: '#015a6a',
    PRIMARY_DARK: '#019cb8',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#019cb8',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://www.vestnesavisa.no/wp-content/uploads/2020/08/fav.png',
    MAIN: 'https://www.vestnesavisa.no/wp-content/uploads/2020/08/Vestnesavisa-logo.png',
  },
};

export default vestnesavisa;