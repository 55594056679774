import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import userService from 'services/user.service';
import { redirectingActions, userActions } from 'store';
import useAlert from './useAlert';
import useReturnUrl from './useRedirect';
import userProfileService from 'services/user-profile.service';
import userAuthService from 'services/user-auth.service';
import userSubscriptionService from 'services/user-subscription.service';
import useAxiosAlert from './useAxiosAlert';
import logger from 'logger/logger';
import User from 'types/User';
import UserSubscription from 'types/UserSubscription';
import { ExtraRegisterFields } from 'pages/Login/components/PasswordForm';

const useUser = () => {
  const { addAlert, clearAlerts } = useAlert();
  const axiosAlert = useAxiosAlert();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleNewspaperRedirect, getReturnUrl } = useReturnUrl();

  const userSubscriptionHasAccess = (userSubscription: UserSubscription) => {
    if (!userSubscription) {
      return false;
    }
    if (userSubscription.status === 'CANCELED') {
      return false;
    }

    return true;
  };

  const fetchUser = async () => {
    try {
      const { data: user } = await userProfileService.getInfo();

      const validateUserSubscription = true;
      const { data: userSubscription } = await userSubscriptionService.getUserSubscription(
        validateUserSubscription
      );

      const updatedUser = {
        ...user,
        hasActiveUserSubscription: userSubscriptionHasAccess(userSubscription),
        userSubscription
      } as User;

      dispatch(userActions.setUser(updatedUser));
      return updatedUser;
    } catch (error) {
      logger.error('Fetch user failed', error);
      axiosAlert(error as AxiosError);
      throw error;
    }
  };

  return {
    fetchUser,
    getToken: () => {
      return localStorage.getItem('auth-token');
    },
    fetchAndUpdateCurrentUserSubscription: async () => {
      try {
        const { data: userSubscription } = await userSubscriptionService.getUserSubscription();
        dispatch(
          userActions.updateUserSubscription({
            hasActiveUserSubscription: userSubscription ? true : false,
            userSubscription
          })
        );
      } catch (error) {
        logger.error('Fetch user subscription failed', error);
        axiosAlert(error as AxiosError);
        throw error;
      }
    },
    signIn: async (phoneNumber: number, password: string, hasLocationState = false) => {
      try {
        const signInQuery = await userAuthService.signIn(phoneNumber, password);
        const {
          data: { token, user }
        } = signInQuery;

        // Set up token
        axios.defaults.headers.common['Authorization'] = token;
        localStorage.setItem('auth-token', token);

        if (getReturnUrl() && !hasLocationState) {
          dispatch(redirectingActions.setRedirecting(true));
          handleNewspaperRedirect();
        } else {
          const { data: userSubscription } = await userSubscriptionService.getUserSubscription();

          dispatch(
            userActions.setUser({
              ...user,
              hasActiveUserSubscription:
                userSubscription && userSubscription.status !== 'CANCELED' ? true : false,
              userSubscription
            })
          );
          clearAlerts();
        }
      } catch (error) {
        // No need to log this.
        //logger.error('Sign in failed', { phoneNumber });
        axiosAlert(error as AxiosError, [
          {
            status: 401,
            message: t('password.notValid')
          },
          {
            status: 409,
            message: t('password.duplicateRequest')
          }
        ]);
      }
    },

    changeName: async (newName: string) => {
      try {
        await userProfileService.changeName(newName);
        await fetchUser();
        addAlert('success', t('alerts.nameChanged'));
      } catch (error) {
        logger.error('Change name failed', error);
        addAlert('error', t('alerts.somethingWentWrong'));
        throw error;
      }
    },
    addPostCode: async (postCode: string) => {
      try {
        await userProfileService.addPostCode(postCode);
        await fetchUser();
        addAlert('success', t('alerts.addressChanged'));
      } catch (error) {
        logger.error('Change addrress failed', error);
        axiosAlert(error as AxiosError, [
          {
            status: 400,
            message: t('alerts.addressNotValid')
          }
        ]);
        throw error;
      }
    },
    changeAddress: async (address: string, postCode: string, postOffice: string) => {
      try {
        await userProfileService.changeAddress(address, postCode, postOffice);
        await fetchUser();
        addAlert('success', t('alerts.addressChanged'));
      } catch (error) {
        logger.error('Change addrress failed', error);
        axiosAlert(error as AxiosError, [
          {
            status: 400,
            message: t('alerts.addressNotValid')
          }
        ]);
        throw error;
      }
    },
    createNewUser: async (
      phoneNumber: number,
      name: string,
      password: string,
      extraFields: ExtraRegisterFields
    ) => {
      try {
        const result = await userService.createUser(phoneNumber, name, password, extraFields);
        const {
          data: { user, token }
        } = result;

        // Set up token
        axios.defaults.headers.common['Authorization'] = token;
        localStorage.setItem('auth-token', token);

        // Set user
        dispatch(userActions.setUser(user));
      } catch (error) {
        logger.error('Create new user failed', error);

        axiosAlert(error as AxiosError, [
          {
            status: 400,
            message: t('alerts.emailTaken')
          },
          {
            status: 409,
            message: t('alerts.emailTaken')
          }
        ]);

        throw error;
      }
    },
    changePhoneNumber: async (phone: string, password: string) => {
      try {
        const phoneNumber = parseInt(phone, 10);
        await userProfileService.changePhoneNumber(phoneNumber, password);
        await fetchUser();

        addAlert('success', t('alerts.phoneNumberChanged'));
      } catch (error) {
        logger.error('Change phone number failed', error);
        axiosAlert(error as AxiosError, [
          {
            status: 400,
            message: t('alerts.phoneNumberNotValid')
          },
          {
            status: 401,
            message: t('password.notValid')
          },
          {
            status: 409,
            message: t('alerts.phoneNumberTaken')
          }
        ]);
        throw error;
      }
    },
    changeEmail: async (email: string, password: string) => {
      try {
        await userProfileService.changeEmail(email, password);
        await fetchUser();

        addAlert('success', t('alerts.emailChanged'));
      } catch (error) {
        logger.error('Change phone number failed', error);
        axiosAlert(error as AxiosError, [
          {
            status: 400,
            message: t('alerts.emailNotValid')
          },
          {
            status: 401,
            message: t('password.notValid')
          },
          {
            status: 409,
            message: t('alerts.emailTaken')
          }
        ]);
        throw error;
      }
    },
    changePassword: async (newPassword: string, oldPassword: string) => {
      try {
        await userAuthService.changePassword(newPassword, oldPassword);

        addAlert('success', t('password.changed'));
      } catch (error) {
        logger.error('Change password failed', error);
        axiosAlert(error as AxiosError, [
          {
            status: 400,
            message: t('alerts.newPasswordNotValid')
          },
          {
            status: 401,
            message: t('alerts.oldPasswordNotCorrect')
          }
        ]);
        throw error;
      }
    },
    signOut: async () => {
      try {
        await userAuthService.signOut();
      } catch (error) {
        logger.error('Sign out failed', error);
      }
      // Remove token regardless of success
      localStorage.removeItem('auth-token');
      dispatch(userActions.setUser(null));
    }
  };
};

export default useUser;
