import Skin from 'types/Skin';

const avisalofoten: Skin = {
  COLORS: {
    PRIMARY: '#53a546',
    PRIMARY_DARK: '#326c29',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#53a546',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://dyplink.no/wp-content/uploads/2023/04/avisa-lofoten-icon.svg',
    MAIN: 'https://dyplink.no/wp-content/uploads/2023/04/avisa-lofoten-logo.png',
  },
};

export default avisalofoten;