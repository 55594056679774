import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import logger from 'logger/logger';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import notificationService from 'services/notification.service';
import CloseIcon from '@mui/icons-material/Close';
import NrsNotification from 'types/NrsNotification';

const Notifications = () => {
  const { t } = useTranslation();

  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    try {
      const notificationsQuery = await notificationService.getForUser();
      setNotifications(notificationsQuery.data);
    } catch (error) {
      logger.error(error);
    }
  };
  const actionClickHandler = async (notification: NrsNotification) => {
    try {
      await notificationService.actedOn(notification.id);
      window.location.href = notification.actionUrl;
    } catch (error) {
      logger.error(error);
    }
  };
  const dismissClickHandler = async (notification: NrsNotification) => {
    try {      
      await notificationService.dismiss(notification.id);
      const newNotifications = notifications.filter((n) => n.id !== notification.id);
      setNotifications(newNotifications);
    } catch (error) {
      logger.error(error);
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <div>
      {notifications.length > 0 && (
        <PaperCard sx={{ width: '900px' }}>
          <PaperHeader>
            <Typography variant='h4'>{t('common.notifications')}</Typography>
          </PaperHeader>
          <PaperContent>
            {notifications.map((notification, i) => (
              <Stack
                key={notification.id}
                direction='row'
                sx={{
                  borderBottom: i === notifications.length - 1 ? undefined : '1px solid #efefef'
                }}
              >
                <IconButton
                  sx={{ width: '40px' }}
                  onClick={() => dismissClickHandler(notification)}
                >
                  <CloseIcon />
                </IconButton>
                <Box sx={{ flex: 1, padding: '8px 20px' }}>
                  <Typography variant='body1'>{notification.message}</Typography>
                </Box>
                <Button onClick={() => actionClickHandler(notification)}>
                  {notification.actionTitle}
                </Button>
              </Stack>
            ))}
          </PaperContent>
        </PaperCard>
      )}
    </div>
  );
};

export default Notifications;
