import axios, { AxiosPromise } from 'axios';
import { VippsAgreementStatus } from 'components/Vipps/types/VippsAgreement';
import Order, { NewOrder, OrderStatus } from 'types/Order';
import PaymentSolution from 'types/PaymentSolution';

const orderService = {
  add: (newOrder: NewOrder): AxiosPromise<Order> => {
    return axios.post('/order', newOrder);
  },
  addPaymentSolutionChange: (
    orderId: string,
    recurringToken: string,
    price: number,
    paymentSolution: PaymentSolution
  ): AxiosPromise<Order> => {
    return axios.post('/order', {
      orderId,
      recurringToken,
      paymentSolution,
      price
    });
  },
  completeSubscriptionOrder: (orderId: string | number, recurringToken: string) => {
    return axios.post(`/order/complete-order/subscription/${orderId}`, {
      recurringToken: recurringToken
    });
  },
  completeChangePaymentMethodOrder: (orderId: number, recurringToken: string) => {
    return axios.post(`/order/complete-order/change-payment-method/${orderId}`, {
      recurringToken: recurringToken
    });
  },
  getByUser: (orderId: number): AxiosPromise<Order> => {
    return axios.get(`/order/user/${orderId}`);
  },
  getByExternalOrderId: (externalOrderId: string): AxiosPromise<Order> => {
    return axios.get(`/order/external/${externalOrderId}`);
  },
  getByUuid: (uuid: string): AxiosPromise<{ order: Order; hasExpired: boolean }> => {
    return axios.get(`/order/uuid/${uuid}`);
  },
  updateByUuid: (orderUuid: string, order: Order): AxiosPromise<Order> => {
    return axios.put(`/order/uuid/${orderUuid}`, order);
  },
  update: (orderId: number, order: Order): AxiosPromise<Order> => {
    return axios.put(`/order/current-user/${orderId}`, order);
  },
  getVippsAgreementStatus: (
    orderUuid: string
  ): AxiosPromise<{ orderStatus: OrderStatus; vippsAgreementStatus: VippsAgreementStatus }> => {
    return axios.get(`order/user/vipps-agreement-status/${orderUuid}`);
  },
  userHasUnhandleOrder: (): AxiosPromise<Order> => {
    return axios.get(`order/user/has-unhandled-order`);
  }
};

export default orderService;
