import axios, { AxiosPromise } from 'axios';
import UserToken from 'types/UserToken';

const userTokenService = {
  getUserTokens: (): AxiosPromise<UserToken[]> => {
    return axios.get('/user-token');
  },
  getCurrentUserMaxActiveLogins: (): AxiosPromise<number> => {
    return axios.get('/user-token/current-user/max-active-logins');
  },
  deleteUserToken: (id: number) => {
    return axios.delete(`/user-token/${id}`);
  },
  removeActive: (id: number) => {
    return axios.post(`/user-token/remove-active-device/${id}`);
  },
  setToActive: (id: number) => {
    return axios.post(`/user-token/set-active-device/${id}`);
  },
  logoutToken: (id: number) => {
    return axios.post(`/user-token/logout/${id}`);
  },
  updateUserToken: (id: number, userToken: UserToken) => {
    return axios.put(`/user-token/${id}`, userToken);
  },
  logoutAll: (password: string) => {
    return axios.post('/user-token/user/logout-all', {
      password
    });
  }
};

export default userTokenService;
