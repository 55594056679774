import { Typography } from '@mui/material';
import AccordionInfoBox from 'components/AccordionInfoBox/AccordionInfoBox';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const GiftSubscriptionInformation: FC = () => {
  const { t } = useTranslation();

  return (
    <AccordionInfoBox title={t('giftSubscription.information.title')}>
      <ul style={{ paddingInlineStart: '20px' }}>
        <li>
          <Typography>{t('giftSubscription.information.doesNotRenew')}</Typography>
        </li>
        <li>
          <Typography>{t('giftSubscription.information.noActiveSubscription')}</Typography>
        </li>
        <li>
          <Typography>{t('giftSubscription.information.existingGiftSubscription')}</Typography>
        </li>
        <li>
          <Typography>{t('giftSubscription.information.availible')}</Typography>
        </li>
      </ul>
    </AccordionInfoBox>
  );
};

export default GiftSubscriptionInformation;
