import { Alert, Button, CircularProgress, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import PhoneNumberForm from '../PhoneNumberForm';
import PasswordForm, { ExtraRegisterFields } from '../PasswordForm';
import { useLocation, useNavigate } from 'react-router';
import routes from 'navigation/routes';
import { Box, SxProps } from '@mui/system';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContentTitle from 'components/PaperContentTitle/PaperContentTitle';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';
import OrderSubscriptionState from 'types/OrderSubscriptionState';
import { formatPhoneNumber } from 'utils/_formatPhoneNumber';

type LoginFromProps = {
  borderBox?: boolean;
};

const LoginForm: FC<LoginFromProps> = ({ borderBox }) => {
  const [isNewUser, setIsNewUser] = useState(null);
  const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosAlert = useAxiosAlert();
  const [loading, setLoading] = useState<boolean>();
  const locationState = useLocation().state as OrderSubscriptionState;
  const hasLocationState = !!locationState;

  const { signIn, createNewUser } = useUser();

  const handlePasswordSubmit = async (passwordFields: ExtraRegisterFields) => {
    setLoading(true);
    try {
      const { password, name } = passwordFields;
      if (isNewUser && phoneNumberSubmitted) {
        await createNewUser(phoneNumber, name, password, passwordFields);
      } else {
        await signIn(phoneNumber, password, hasLocationState);
      }
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const forgotPasswordHanlder = () => {
    navigate(routes.FORGOT_PASSWORD.path);
  };

  const styledPaperCard: SxProps = {
    padding: borderBox ? '16px' : '4px',
    width: '100%',
    border: borderBox ? null : 'none'
  };

  const getTitle = () => {
    if (isNewUser) {
      return t('login.createUser');
    }

    if (phoneNumberSubmitted && !isNewUser) {
      return t('login.signIn');
    }

    return t('login.signInOrCreateUser');
  };

  return (
    <Box className={'login-form'}>
      <PaperCard variant='outlined' sx={styledPaperCard}>
        <PaperContentTitle>{getTitle()}</PaperContentTitle>
        <div className={'fields'}>
          <PhoneNumberForm
            onFormReset={() => {
              setPhoneNumberSubmitted(false);
              setIsNewUser(null);
              setPhoneNumber(null);
            }}
            onSubmit={(isNewUser, phoneNumber) => {
              setPhoneNumber(phoneNumber);
              setPhoneNumberSubmitted(true);
              setIsNewUser(isNewUser);
              if (!isNewUser) {
                // Focus password input
                setTimeout(() => {
                  document.getElementById('password-input').focus();
                }, 100);
              }
            }}
          />
          {isNewUser && (
            <Alert severity={'info'} sx={{ marginBottom: '16px' }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('alerts.cantFindNumberCreateUser', {
                    phone: formatPhoneNumber(phoneNumber as string)
                  })
                }}
              />
            </Alert>
          )}
          {phoneNumberSubmitted && (
            <PasswordForm loading={loading} isNewUser={isNewUser} onSubmit={handlePasswordSubmit} />
          )}
          {loading && (
            <Stack sx={{ width: '100%' }} flexDirection={'row'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>
          )}
        </div>
        {(!phoneNumberSubmitted || !isNewUser) && (
          <div className='actions'>
            <Button fullWidth onClick={forgotPasswordHanlder}>
              {t('common.forgotPassword')}
            </Button>
          </div>
        )}
      </PaperCard>
    </Box>
  );
};

export default LoginForm;
