import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Stack, SxProps } from '@mui/system';
import useSettings from 'hooks/useSettings';
import useUser from 'hooks/useUser';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import userAddressService from 'services/userAddress.service';
import { RootState } from 'store';
import CountryCode, { countryCodes } from 'types/CountryCode';
import UserAddress from 'types/UserAddress';
import { getCountryCodeFlag, validate } from 'utils';

type UserAddressFields = {
  address: string;
  postCode: string;
  postOffice: string;
  address2: string;
  country: CountryCode;
  state: string;
  careOf: string;
  entrance: string;
  floor: string;
};

type UserAddressFormProps = {
  userAddress: UserAddress;
  inModal?: boolean;
  sx?: SxProps;
  onSuccess: () => void;
  onCancel?: () => void;
};

const UserAddressForm: FC<UserAddressFormProps> = ({
  userAddress,
  inModal,
  onSuccess,
  onCancel,
  sx
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<UserAddressFields>({
    defaultValues: { ...userAddress, ...{ country: userAddress?.country ?? 'NO' } }
  });

  const [loading, setLoading] = useState(false);
  const { fetchUser } = useUser();
  const user = useSelector((state: RootState) => state.user);
  const country = watch('country');

  const { t } = useTranslation();
  const { getValueByKey } = useSettings();

  const nrsPaper = getValueByKey('NrsPaper');

  const [displayExtraFields, setDisplayExtraFields] = useState<boolean>(false);

  const onSubmitForm = async (fields: UserAddressFields) => {
    setLoading(true);
    try {
      if (userAddress) {
        await userAddressService.updateUserAddress(fields);
      } else {
        await userAddressService.createUserAddress(fields);
      }

      await fetchUser();

      onSuccess();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.userSubscription?.subscription?.paper) {
      setDisplayExtraFields(true);
    }
  }, [user]);

  const styledSelect: SxProps = {
    borderRadius: '50px',
    minWidth: 'auto',
    flex: '1',
    zIndex: 100002
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Stack
        gap={2}
        sx={{
          width: '100%',
          ...sx
        }}
      >
        <Stack gap={2}>
          <Stack
            gap={2}
            sx={{
              mt: 1
            }}
          >
            <TextField
              name={'address'}
              label={t('common.streetAddress') + ' *'}
              {...register('address', {
                required: true,
                validate: (val) => validate.length(val, 2, 50)
              })}
              error={!!errors.address}
            />
            <Stack direction={'row'} gap={1}>
              <TextField
                sx={{ flex: 1 }}
                name={'postCode'}
                label={t('common.postCode') + ' *'}
                {...register('postCode', {
                  required: true,
                  validate: (val) => validate.length(val, 2, 50) && validate.isNumber(val)
                })}
                error={!!errors.postCode}
              />
              <TextField
                sx={{ flex: 2 }}
                name={'postOffice'}
                label={t('common.postOffice') + ' *'}
                {...register('postOffice', {
                  required: true,
                  validate: (val) => validate.length(val, 2, 50)
                })}
                error={!!errors.postOffice}
              />
            </Stack>
          </Stack>
          {nrsPaper && (
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                p: '12px',
                cursor: 'pointer',
                borderBottom: '1px solid #ccc'
              }}
              onClick={() => setDisplayExtraFields(!displayExtraFields)}
            >
              <Typography sx={{}}>{t('common.extraFields')}</Typography>
              {!displayExtraFields && <KeyboardArrowRight />}
              {displayExtraFields && <KeyboardArrowDown />}
            </Stack>
          )}
          {displayExtraFields && (
            <Stack gap={2}>
              <TextField
                name={'address2'}
                label={t('common.address') + ' 2'}
                {...register('address2', {
                  validate: (val) => validate.length(val, 1, 50, true)
                })}
                error={!!errors.address2}
              />
              <TextField
                name={'entrance'}
                label={t('common.entranceDescription')}
                {...register('entrance', {
                  validate: (val) => validate.length(val, 1, 50, true)
                })}
                error={!!errors.entrance}
              />
              <TextField
                name={'floor'}
                label={t('common.floor')}
                {...register('floor', {
                  validate: (val) => validate.length(val, 1, 50, true)
                })}
                error={!!errors.floor}
              />
              <TextField
                name={'careOf'}
                label={t('common.careOf')}
                {...register('careOf', {
                  validate: (val) => validate.length(val, 1, 50, true)
                })}
                error={!!errors.careOf}
              />
              <FormControl>
                <InputLabel>{t('common.country')}</InputLabel>
                <Select
                  {...register('country', { required: true })}
                  defaultValue={country}
                  sx={styledSelect}
                  label={t('common.country')}
                  MenuProps={{
                    sx: {
                      ['.MuiPaper-root']: {
                        margin: '0',
                        minWidth: 'auto !important'
                      }
                    }
                  }}
                >
                  {countryCodes.map((countryCode, index) => (
                    <MenuItem key={index} value={countryCode}>
                      {getCountryCodeFlag(countryCode)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {country && country !== 'NO' && (
                <TextField
                  sx={{ flex: 2 }}
                  name={'state'}
                  label={t('common.state-region') + ' (' + t('common.ifNecessary') + ')'}
                  {...register('state', {
                    validate: (val) => {
                      return validate.length(val, 2, 50) || !val || val === '';
                    }
                  })}
                  error={!!errors.country}
                />
              )}
            </Stack>
          )}
        </Stack>
        <Stack direction={'column'} gap={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button type='submit' variant='contained'>
              {userAddress ? t('userAddress.update') : t('userAddress.add')}
            </Button>
          )}
          {inModal && (
            <Button disabled={loading} onClick={() => onCancel()}>
              {t('common.cancel')}
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  );
};

export default UserAddressForm;
