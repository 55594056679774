import { FC, useState } from 'react';
import { TextField, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormDialog } from 'components';

import useUser from 'hooks/useUser';
import useAlert from 'hooks/useAlert';
import logger from 'logger/logger';

type ChangeNameDialogType = {
  currentName?: string;
  onCancel: () => void;
  onConfirm?: () => void;
};

type ChangeNameFields = {
  name: string;
};

const ChangeNameDialog: FC<ChangeNameDialogType> = ({ currentName, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ChangeNameFields>({
    defaultValues: { name: currentName || '' }
  });
  const [loading, setLoading] = useState(false);
  const { changeName } = useUser();
  const { addAlert } = useAlert();

  const onSubmit: SubmitHandler<ChangeNameFields> = async (fields) => {
    const { name } = fields;

    setLoading(true);
    try {
      await changeName(name);
      onConfirm();
    } catch (error) {
      logger.error('Change name failed', error);
      addAlert('error', t('alert.couldNotChangeName'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      title={t('common.changeName')}
      loading={loading}
      sxHeader={{ fontSize: '1.8rem' }}
      sxContent={{ maxWidth: '350px' }}
    >
      <div className='dialog-content'>
        <Stack spacing={2}>
          <TextField
            autoFocus
            label={t('common.name')}
            {...register('name', {
              required: 'name is required',
              minLength: 2,
              maxLength: 50
            })}
            error={errors.name ? true : false}
          />
        </Stack>
      </div>
    </FormDialog>
  );
};

export default ChangeNameDialog;
