import { Divider, Typography } from '@mui/material';
import { PaymentPicker } from 'components';
import FlexBox from 'components/FlexBox/FlexBox';
import PaperContent from 'components/PaperContent/PaperContent';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import theme from 'styles/theme';
import GiftInfo from 'types/GiftInfo';
import Order from 'types/Order';
import Subscription from 'types/Subscription';
import SelectedGiftSubscription from './SelectedGiftSubscription';

type GiftSubscriptionPaymentContentProps = {
  order: Order;
  subscription: Subscription;
  giftInfo: GiftInfo;
};

const GiftSubscriptionPaymentContent: FC<GiftSubscriptionPaymentContentProps> = ({
  order,
  subscription,
  giftInfo
}) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);

  return (
    <PaperContent
      sx={{
        padding: '16px',

        [theme.breakpoints.down('sm')]: {
          borderRadius: 0,
          marginBottom: '100px'
        }
      }}
    >
      <Typography variant='h4'>{t('common.completeOrder')}</Typography>

      <FlexBox gap={16} direction={'column'} sx={{ width: '100%' }}>
        <Typography variant='h4' fontWeight={'500'}>
          {order.type === 'CHANGE_PAYMENT_SOLUTION' && t('payment.changePaymentSolution')}
          {order.type === 'SUBSCRIPTION' && t('common.completeOrder')}
        </Typography>
        <SelectedGiftSubscription
          subscription={subscription}
          giftInfo={giftInfo}
          orderUuid={order.uuid}
        />

        <Divider sx={{ width: '100%' }} />
        <Typography
          sx={{ marginBottom: '40px', fontWeight: '400' }}
          textAlign={'right'}
          width='100%'
          variant='h6'
        >{`${t('common.total')}: ${order.price} kr (${t('payment.includedVat')})`}</Typography>

        <Typography variant='h5' fontWeight={'500'}>
          {t('giftSubscription.paymentTitle')}
        </Typography>
        <PaymentPicker
          usingSwedbank={true}
          order={order}
          selectedPaymentSolution={'swedbank'}
          subscription={subscription}
          user={user}
        />
      </FlexBox>
    </PaperContent>
  );
};

export default GiftSubscriptionPaymentContent;
