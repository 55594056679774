import { Divider, Link, Typography } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import useSettings from 'hooks/useSettings';
import { useTranslation } from 'react-i18next';
import NavItem from 'types/NavItem';
import useDevice from 'hooks/useDevice';
import { FC } from 'react';

const FooterLinks = () => {
  const device = useDevice();
  const { t } = useTranslation();
  const { getValueByKey } = useSettings();
  const termsOfServiceUrl = getValueByKey('TermsOfServiceUrl');
  const privacyStatement = getValueByKey('PrivacyUrl');
  const externalLinksStr = getValueByKey('ExternalLinks') || '[]';

  // External links
  let externalLinks: NavItem[];
  try {
    externalLinks = JSON.parse(externalLinksStr);
  } catch (error) {
    externalLinks = []; // Fail silently
  }

  return (
    <>
      <Typography>
        <LinkRouter to={'/faq'}>{t('common.help')}</LinkRouter>
      </Typography>
      {device !== 'mobile' && <Divider orientation='vertical' variant='middle' />}
      <Typography>
        <Link href={privacyStatement} target='_blank' rel='norefferer'>
          {t('userSubscription.privacyStatement')}
        </Link>
      </Typography>
      {device !== 'mobile' && <Divider orientation='vertical' variant='middle' />}
      <Typography>
        <Link href={termsOfServiceUrl} target='_blank' rel='norefferer'>
          {t('userSubscription.termsOfServiceLinkText')}
        </Link>
      </Typography>
      {externalLinks.map((el, i) => (
        <FooterExternalLink
          key={i}
          title={el.title}
          path={el.path}
          isMobile={device === 'mobile'}
        />
      ))}
    </>
  );
};

// Sub-component
type FooterLinkProps = {
  isMobile: boolean;
  path: string;
  title: string;
};
const FooterExternalLink: FC<FooterLinkProps> = ({ isMobile, path, title }) => {
  return (
    <>
      {!isMobile && <Divider orientation='vertical' variant='middle' />}
      <Typography>
        <Link href={path} target='_blank' rel='norefferer'>
          {title}
        </Link>
      </Typography>
    </>
  );
};

export default FooterLinks;
