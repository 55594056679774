import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import theme from 'styles/theme';
import routes from 'navigation/routes';
import { COLORS, LOGO } from 'skin';
import ProfileMenu from 'components/ProfileMenu/ProfileMenu';
import { useNavigate } from 'react-router';
import useDevice from 'hooks/useDevice';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type PrimaryHeaderProps = {
  displayDrawer?: boolean;
  onDrawerClick?: () => void;
  title?: string;
  actions?: ReactNode;
  showAuth?: boolean;
  disableDrawer?: boolean;
};

const PrimaryHeader: FC<PrimaryHeaderProps> = ({
  displayDrawer = false,
  onDrawerClick = () => {},
  title,
  actions,
  showAuth
}) => {
  const { t } = useTranslation();
  const device = useDevice();
  const navigate = useNavigate();
  const settings = useSelector((state: RootState) => state.settings);

  const logoLink = settings.find((s) => s.key === 'LogoLink')?.value || '/';
  const styles = {
    headerTitle: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('lg')]: {
        marginLeft: displayDrawer ? '240px' : 0
      }
    },
    headerActions: {
      position: 'absolute',
      height: '64px',
      display: 'flex',
      alignItems: 'center',
      right: '16px',
      [theme.breakpoints.up('lg')]: {
        right: '24px'
      }
    }
  };

  return (
    <header className={'app-bar'}>
      <AppBar
        position='fixed'
        sx={{
          borderRadius: 0,
          height: '64px',
          [theme.breakpoints.up('lg')]: {
            zIndex: 99999
          }
        }}
        className={'app-bar'}
      >
        <Toolbar
          className={'toolbar'}
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            background: COLORS.PRIMARY_DARK,
            minHeight: '64px',
            [theme.breakpoints.up('lg')]: {
              paddingLeft: 0
            }
          }}
        >
          <Box
            sx={{
              height: '100%',
              boxSizing: 'border-box',
              padding: '7px 7px 7px 7px',
              display: 'flex',
              justifyContent: 'center',
              background: COLORS.PRIMARY,
              marginRight: '16px',
              position: 'absolute',
              [theme.breakpoints.up('lg')]: {
                width: '240px',
                paddingLeft: '5px',
                paddingRight: '5px'
              }
            }}
          >
            <Box
              sx={{
                height: 50,
                width: 50,
                display: 'flex',
                justifyContent: 'center',
                [theme.breakpoints.up('lg')]: {
                  width: 'auto',
                  maxWidth: '100%'
                }
              }}
            >
              <a href={logoLink}>
                <img
                  style={{ height: '100%', width: '100%', maxWidth: '100%', objectFit: 'contain' }}
                  src={device === 'mobile' || device === 'tablet' ? LOGO.ICON : LOGO.MAIN}
                  onClick={() => navigate(routes.PROFILE.path)}
                />
              </a>
            </Box>
          </Box>
          <Box sx={styles.headerTitle}>
            {displayDrawer && (device === 'mobile' || device === 'tablet') && (
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={onDrawerClick}
                className={'menu-button'}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant='h5' noWrap>
              {title || t('common.appTitle')}
            </Typography>
          </Box>
          {actions}
          {showAuth && (
            <Box sx={styles.headerActions}>
              <ProfileMenu />
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default PrimaryHeader;
