import routes from 'navigation/routes';
import { useSelector } from 'react-redux';
import { Navigate, Route } from 'react-router';
import { RootState } from 'store';
import RouteItem from 'types/RouteItem';

const useRoute = () => {
  const user = useSelector((state: RootState) => state.user);

  const createRoute = (routeItem: RouteItem, key?: number) => {
    if (!routeItem.private) {
      return <Route key={key} path={routeItem.path} element={routeItem.component} />;
    }

    // Not logged inn
    if (!user) {
      let state = null;
      if (routeItem.redirectFromLogin) {
        state = { returnUrl: location.pathname };
      }
      return (
        <Route
          key={key}
          path={routeItem.path}
          element={<Navigate to={routes.LOGIN.path} state={state} />}
        />
      );
    }

    // Valid role
    return <Route key={key} path={routeItem.path} element={routeItem.component} />;
  };

  return createRoute;
};

export default useRoute;
