import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';
import logger from 'logger/logger';
import routes from 'navigation/routes';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import orderService from 'services/order.service';
import UserSubscription from 'types/UserSubscription';

type ChangePaymentSolutionDialogProps = {
  onCancel: () => void;
  userSubscription: UserSubscription;
};

const ChangePaymentSolutionDialog: FC<ChangePaymentSolutionDialogProps> = ({
  onCancel,
  userSubscription
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosAlert = useAxiosAlert();
  const [loading, setLoading] = useState<boolean>();

  const changePaymentMethodHandler = async () => {
    setLoading(true);

    try {
      const { data: order } = await orderService.add({
        type: 'CHANGE_PAYMENT_SOLUTION',
        userSubscriptionId: userSubscription.id,
        subscriptionId: userSubscription.subscriptionId
      });

      navigate(routes.CHANGE_PAYMENT_METHOD.path.replace(':uuid', order.uuid));
    } catch (error) {
      axiosAlert(error as AxiosError, [
        { status: 400, message: t('alerts.couldNotCreateChangePaymentSolutionOrder') }
      ]);

      logger.error(
        'Could not create order for changing paymentSolution for existing user subscription "temporary" ' +
          error
      );
    } finally {
      setLoading(false);
    }
  };

  const dontShowAgainHandler = () => {
    localStorage.setItem('dontShowPaymentSolutionDialog', 'true');
    onCancel();
  };

  const dontShowAgain = localStorage.getItem('dontShowPaymentSolutionDialog');

  if (!dontShowAgain) {
    return (
      <Dialog open={true}>
        <DialogTitle>
          {t('userSubscription.paymentSolutionTemporary.changePaymentSolutionTitle')}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t('userSubscription.paymentSolutionTemporary.changePaymentSolutionDescription')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Button disabled={loading} onClick={dontShowAgainHandler}>
              {t('common.dontShowAgain')}
            </Button>
            <Stack flexDirection={'row'} gap={1}>
              <Button
                disabled={loading}
                onClick={() => {
                  onCancel();
                }}
              >
                {t('common.close')}
              </Button>
              <Button
                variant='contained'
                disabled={loading}
                onClick={() => {
                  changePaymentMethodHandler();
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: '#fff' }} size={'30px'} />
                ) : (
                  t('payment.changePaymentSolution')
                )}
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
};

export default ChangePaymentSolutionDialog;
