import { Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router';

type LinkButtonProps = {
  variant?: 'outlined' | 'contained' | 'text';
  to: string;
  children?: ReactNode;
  fullwidth?: boolean;
  sx?: SxProps;
};

const LinkButton: FC<LinkButtonProps> = ({
  variant = 'text',
  to,
  children,
  fullwidth = false,
  sx
}) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(to);
      }}
      variant={variant}
      fullWidth={fullwidth}
      sx={sx}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
