import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import UserAddressForm from 'components/UserAddressForm/UserAddressForm';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';

type ChangeAddressDialogProps = {
  onCancel: () => void;
  onSuccess: () => void;
  user: User;
};

const ChangeAddressDialog: FC<ChangeAddressDialogProps> = ({ onCancel, onSuccess, user }) => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <DialogTitle variant='h4'>{t('common.changeAddress')}</DialogTitle>
      <Divider />
      <DialogContent>
        <UserAddressForm
          sx={{ width: '350px', maxWidth: '100%', zIndex: 1000 }}
          userAddress={user.userAddress}
          onSuccess={onSuccess}
          onCancel={onCancel}
          inModal
        />
      </DialogContent>
    </Dialog>
  );
};

export default ChangeAddressDialog;
