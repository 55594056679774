import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Person from '@mui/icons-material/Person';
import useUser from 'hooks/useUser';
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const ProfileMenu: FC = () => {
  const newsroomApp = useSelector((state: RootState) => state.newsroomApp);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user } = useSelector((state: RootState) => state);
  const { signOut } = useUser();
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (newsroomApp) {
    return null;
  }

  return (
    <>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: '#fff' }}
      >
        <Typography
          fontSize={'0.9rem'}
          sx={{
            textTransform: 'none',
            opacity: '0.75',
            position: 'relative',
            top: '3px',
            display: { xs: 'none', sm: 'none', md: 'inline' }
          }}
        >
          {user?.name}
        </Typography>
        &nbsp;
        <Person />
      </Button>
      <Menu
        sx={{ width: '100%' }}
        id='basic-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            signOut();
          }}
        >
          {t('common.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
