import axios from 'axios';
import { ExtraRegisterFields } from 'pages/Login/components/PasswordForm';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const userService = {
  createUser: (phone: number, name: string, password: string, extraFields: ExtraRegisterFields) => {
    const { email, ...userAddress } = extraFields;

    return axios.post('user', {
      phone: phone,
      name: name,
      password: password,
      email: email,
      userAddress: userAddress
    });
  },
  phoneNumberExist: (phone: number) => {
    return axios.post('user/exist', {
      phone: phone
    });
  }
};

export default userService;
