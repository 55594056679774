import Skin from 'types/Skin';

const alvdal: Skin = {
  COLORS: {
    PRIMARY: '#5c229b',
    PRIMARY_DARK: '#3c2754',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#5c229b',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://dyplink.no/wp-content/uploads/2022/09/Icon-512.png',
    MAIN: 'https://dyplink.no/wp-content/uploads/2022/09/Logo-White.svg',
  },
};

export default alvdal;
