import {
  Box,
  Button,
  CircularProgress,
  Divider,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import CardInfo from 'components/CardInfo/CardInfo';

import FlexBox from 'components/FlexBox/FlexBox';
import PageContainer from 'components/PageContainer/PageContainer';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaymentSolutionLogo from 'components/PaymentSolutionLogo/PaymentSolutionLogo';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import paymentService from 'services/payment.service';
import settingService from 'services/setting-service';
import { RootState } from 'store';
import Payment from 'types/Payment';
import calculateVat from 'utils/_calculateVat';
import getDateString from 'utils/_getDateString';

type ReceiptParams = {
  id: string;
};

const Receipt: FC = () => {
  const params = useParams<ReceiptParams>();
  const settings = useSelector((state: RootState) => state.settings);
  const user = useSelector((state: RootState) => state.user);
  const [payment, setPayment] = useState<Payment>();
  const [organizationName, setOrganizationName] = useState<string>();
  const [organizationNumber, setOrganizationNumber] = useState<string>();
  const [organizationAddress, setOrganizationAddress] = useState<string>();
  const [organizationPostOffice, setOrganizationPostOffice] = useState<string>();
  const [organizationPostCode, setOrganizationPostCode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const vat = parseInt(settings.find((s) => s.key === 'Vat')?.value) || 0;

  const createdDateString = getDateString(payment?.created);
  const fromDateString = getDateString(payment?.fromDate);
  const toDateString = getDateString(payment?.toDate);

  const init = async () => {
    setLoading(true);

    try {
      const ps = [];

      ps.push(settingService.getPublicByKey('OrganizationNumber'));
      ps.push(settingService.getPublicByKey('OrganizationName'));
      ps.push(settingService.getPublicByKey('OrganizationPostOffice'));
      ps.push(settingService.getPublicByKey('OrganizationPostCode'));
      ps.push(settingService.getPublicByKey('OrganizationAddress'));

      const [orgNumberRes, orgNameRes, orgPostOfficeRes, orgPostCodeRes, orgAddressRes] =
        await Promise.all(ps);
      const { data: paymentData } = await paymentService.getByIdCurrentUser(parseInt(params.id));

      setPayment(paymentData as Payment);
      setOrganizationName(orgNameRes.data.value);
      setOrganizationAddress(orgAddressRes.data.value);
      setOrganizationPostCode(orgPostCodeRes.data.value);
      setOrganizationPostOffice(orgPostOfficeRes.data.value);
      setOrganizationNumber(orgNumberRes.data.value);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const receiptRow: SxProps = {
    width: '100%'
  };

  const vatPrice = calculateVat(payment?.price, vat);
  const adjustedPrice = payment?.price - vatPrice;

  return (
    <PageContainer centerHorizontal centerVertical>
      {payment && (
        <>
          <PaperCard sx={{ border: '1px solid black', boxShadow: 'none' }} width={1000}>
            {payment && (
              <PaperContent sx={{ padding: '15px' }}>
                <FlexBox
                  justifyContent='space-between'
                  alignItems='center'
                  gap={6}
                  sx={{ marginBottom: '8px' }}
                >
                  <FlexBox alignItems='flex-start' direction='column' sx={{ flex: 1 }}>
                    <FlexBox justifyContent='space-between' sx={{ width: '100%' }}>
                      <Typography sx={{ marginBottom: '16px' }} variant='h4'>{`${t(
                        'common.receipt'
                      )} ${(payment.status === 'REFUNDED'
                        ? t('common.refund')
                        : t('common.payment')
                      ).toLocaleLowerCase()}`}</Typography>
                      <Button sx={{ whiteSpace: 'nowrap' }} onClick={() => window.print()}>
                        {t('common.print')}
                      </Button>
                    </FlexBox>
                    <Typography>{organizationName}</Typography>
                    <Typography>{`${organizationAddress}`}</Typography>
                    <Typography>{`${organizationPostCode} ${organizationPostOffice}`}</Typography>
                    <Typography>{`${t('common.orgNumber')} ${organizationNumber}`}</Typography>
                    <Box sx={{ width: '100%', margin: '16px 0' }}>
                      <Divider />
                    </Box>
                    <Typography>{`${
                      payment?.orderType === 'GIFT_SUBSCRIPTION'
                        ? t('giftSubscription..')
                        : `${t('common.user')}
                       ${user.name}`
                    }`}</Typography>
                    <Typography>{`${t('common.phone')} ${user.phone}`}</Typography>
                    {payment?.card && <CardInfo card={payment.card} hideExpirey />}
                  </FlexBox>
                </FlexBox>
                <TableContainer sx={{ marginBottom: '32px' }}>
                  <Table sx={{ width: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ paddingLeft: 0 }}>
                          <Typography>{t('common.date')}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{t('common.description')}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{t('common.servicePeriod')}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{t('common.amount')}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{t('common.vatPercentage')}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{t('common.vat')}</Typography>
                        </TableCell>
                        <TableCell sx={{ paddingRight: 0, fontWeight: 700 }}>
                          <Typography sx={{ fontWeight: 700 }}>{t('common.total')}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ paddingLeft: 0 }}>
                          <Typography>{createdDateString}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{t('common.subscription')}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{`${fromDateString} - ${toDateString}`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{adjustedPrice.toFixed(2)} kr</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{`${vat}%`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{vatPrice.toFixed(2)} kr</Typography>
                        </TableCell>
                        <TableCell sx={{ paddingRight: 0 }}>
                          <Typography sx={{ fontWeight: 700 }}>{payment.price} kr</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <FlexBox
                  justifyContent='flex-end'
                  sx={{ ...receiptRow, gap: '8px', alignItems: 'center' }}
                >
                  <Typography>{t('common.payedWith')}</Typography>
                  <PaymentSolutionLogo height={30} paymentSolution={payment.paymentSolution} />
                </FlexBox>
              </PaperContent>
            )}
          </PaperCard>
        </>
      )}
      {!payment && loading && <CircularProgress />}
    </PageContainer>
  );
};

export default Receipt;
