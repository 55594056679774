import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Alert, Button, Stack } from '@mui/material';
import routes from 'navigation/routes';
import getDateString from 'utils/_getDateString';

const CanceledUserSubscriptionInfo: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userSubscription } = useSelector((state: RootState) => state.user);

  const getExpiredDate = () => {
    const date = new Date(userSubscription.cancelationDate);
    return getDateString(date);
  };

  const getCancelationMessage = () => {
    if (userSubscription.cancelationType === 'ADMIN') {
      return t('userSubscription.alerts.canceled', {
        date: getExpiredDate()
      });
    }

    if (userSubscription.cancelationType === 'PAYMENT_FAILED') {
      return t('userSubscription.alerts.canceledDoToPaymentFailed', {
        date: getExpiredDate()
      });
    }

    if (userSubscription.cancelationType === 'USER') {
      return t('userSubscription.alerts.canceled', {
        date: getExpiredDate()
      });
    }

    return t('userSubscription.alerts.canceled', {
      date: getExpiredDate()
    });
  };

  if (!userSubscription) {
    return null;
  }

  const cancelationMessage = getCancelationMessage();

  return (
    <Stack gap={2}>
      <Alert severity={'info'}>{cancelationMessage}</Alert>
      <Stack direction={'row'}>
        <Button
          size='small'
          color='secondary'
          variant={'outlined'}
          onClick={() => navigate(routes.ORDER_SUBSCRIPTION_SELECT.path)}
          fullWidth
        >
          {t('subscription.becomeASubscriber')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CanceledUserSubscriptionInfo;
