import { FC } from 'react';
import { Box, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';

type LoadingDialogType = {
  title?: string;
};

const LoadingDialog: FC<LoadingDialogType> = ({ title }) => {
  return (
    <Dialog open={true}>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '25px'
          }}
        >
          <Typography
            variant={'h6'}
            sx={{
              marginRight: '15px'
            }}
          >
            {title}
          </Typography>
          <CircularProgress color={'primary'} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
