import { Alert, Button } from '@mui/material';
import { Stack } from '@mui/system';
import FlexBox from 'components/FlexBox/FlexBox';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperFooter from 'components/PaperFooter/PaperFooter';
import routes from 'navigation/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import getRoutePath from 'services/getRoutePath';

type GiftSubscriptionTimedOutProps = {
  uuid: string;
};

const GiftSubscriptionTimedOut: FC<GiftSubscriptionTimedOutProps> = ({ uuid }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <PaperContent>
        <FlexBox direction='column' gap={2}>
          <Alert sx={{ width: '100%', boxSizing: 'border-box' }} severity='error'>
            {t('payment.alerts.timedOut')}
          </Alert>
        </FlexBox>
      </PaperContent>
      <PaperFooter>
        <Stack gap={1}>
          <Button
            fullWidth
            variant={'contained'}
            onClick={() => navigate(getRoutePath(routes.GIFT_SUBSCRIPTION_UUID.path, 'uuid', uuid))}
          >
            {t('navigation.backToOrderGiftSubscriptionPage')}
          </Button>
          <Button fullWidth variant='outlined' onClick={() => navigate(routes.PROFILE.path)}>
            {t('navigation.backToMyPage')}
          </Button>
        </Stack>
      </PaperFooter>
    </>
  );
};

export default GiftSubscriptionTimedOut;
