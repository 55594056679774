import { FormGroup, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import FormDialog from 'components/FormDialog/FormDialog';
import useAlert from 'hooks/useAlert';
import useAxiosAlert from 'hooks/useAxiosAlert';
import useUser from 'hooks/useUser';
import logger from 'logger/logger';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userProfileService from 'services/user-profile.service';
import { validate } from 'utils';

type AddEmailDialogProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

type FormValues = {
  email: string;
};

const AddEmailDialog: FC<AddEmailDialogProps> = ({ onCancel, onConfirm }) => {
  const { register, formState, handleSubmit } = useForm<FormValues>();
  const { errors } = formState;
  const { t } = useTranslation();
  const { addAlert } = useAlert();
  const { fetchUser } = useUser();
  const [loading, setLoading] = useState<boolean>();
  const axiosAlert = useAxiosAlert();

  const onSubmit: SubmitHandler<FormValues> = async (fields) => {
    addEmailHandler(fields.email);
  };

  const addEmailHandler = async (email: string) => {
    setLoading(true);
    try {
      await userProfileService.addEmail(email);
      await fetchUser();
      addAlert('success', t('alerts.emailAdded'));
      onConfirm();
    } catch (error) {
      logger.error('Add email failed', error);
      axiosAlert(
        error as AxiosError,
        [
          {
            status: 409,
            message: t('changeEmail.alreadyInUse')
          }
        ],
        t('alerts.somethingWentWrong')
      );
    } finally {
      setLoading(null);
    }
  };

  return (
    <FormDialog
      onSubmit={handleSubmit(onSubmit)}
      onCancel={onCancel}
      loading={loading}
      sxContent={{ maxWidth: '400px', width: '100%' }}
      title={t('common.addEmail')}
    >
      <Typography sx={{ marginBottom: '16px' }}>{t('profile.pleaseAddEmail')}</Typography>
      <FormGroup>
        <TextField
          autoFocus
          {...register('email', {
            required: true,
            validate: (value) => validate.email(value)
          })}
          name={'email'}
          placeholder={t('common.email')}
          label={t('common.email')}
          error={errors?.email ? true : false}
        />
      </FormGroup>
      <Typography sx={{ marginBottom: '16px', fontStyle: 'italic', fontSize: '0.8em' }}>
        {t('profile.emailMustBeUnique')}
      </Typography>
    </FormDialog>
  );
};

export default AddEmailDialog;
