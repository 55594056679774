import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperFooter from 'components/PaperFooter/PaperFooter';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import useSettings from 'hooks/useSettings';
import routes from 'navigation/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const LoginInfo: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getValueByKey } = useSettings();

  const newsSiteUrl = getValueByKey('HomeUrl');

  const navigateToLogin = () => {
    navigate(routes.LOGIN.path);
  };

  const navigateToNewsSite = () => {
    window.location.href = newsSiteUrl;
  };

  return (
    <PaperCard variant='outlined' sx={{ width: 'auto' }}>
      <PaperHeader>
        <Typography variant='h4'>{t('common.login')}</Typography>
      </PaperHeader>
      <PaperContent>
        <Typography>{t('orderCompletion.alerts.userNotLoggedInExplenation')}</Typography>
      </PaperContent>
      <PaperFooter>
        <Stack gap={1.5}>
          <Button fullWidth variant={'contained'} onClick={navigateToLogin}>
            {t('navigation.toLogin')}
          </Button>
          <Button fullWidth variant={'outlined'} onClick={navigateToNewsSite}>
            {t('common.toNewsSite')}
          </Button>
        </Stack>
      </PaperFooter>
    </PaperCard>
  );
};

export default LoginInfo;
