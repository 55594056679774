import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormControl, TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import nb from 'date-fns/locale/nb';

type ReactHookDatePickerProps = {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  validate?: (value: Date) => boolean | string;
  error?: boolean;
  helperText?: string;
  required?: boolean | string;
  minDate?: Date;
};

const ReactHookDatePicker: FC<ReactHookDatePickerProps> = ({
  required = false,
  name,
  label,
  validate,
  error,
  control,
  helperText,
  minDate,
  ...props
}) => {
  return (
    <FormControl {...props}>
      <Controller
        rules={{
          required: required,
          validate: validate
        }}
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
            <DatePicker
              label={label}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(value: any) => {
                onChange(value);
              }}
              value={value === undefined ? null : value}
              inputFormat='dd.MM.yyyy'
              mask='__.__.____'
              minDate={minDate}
              renderInput={(params: TextFieldProps) => (
                <TextField {...params} error={error} helperText={helperText} />
              )}
            />
          </LocalizationProvider>
        )}
      />
    </FormControl>
  );
};

export default ReactHookDatePicker;
