/* eslint-disable indent */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import ReactHookDatePicker from 'components/ReactHookDatePicker/ReactHookDatePicker';
import useIsMobile from 'hooks/useIsMobile';

import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userDistributionService from 'services/user-distribution.service';
import UserDistribution from 'types/UserDistribution';
import { validate } from 'utils';

type HoldPaperModalProps = {
  userDistribution: UserDistribution;
  onCancel?: () => void;
  onFinished?: () => void;
};

type HoldPaperFields = {
  holdPaperFromDate: Date;
  holdPaperToDate: Date;
};

const HoldPaperModal: FC<HoldPaperModalProps> = ({ userDistribution, onCancel, onFinished }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState<boolean>();
  const { handleSubmit, control, getValues, watch, setValue, formState } = useForm<HoldPaperFields>(
    {
      defaultValues: userDistribution
        ? {
            holdPaperFromDate: userDistribution?.holdFromDate,
            holdPaperToDate: userDistribution?.holdToDate
          }
        : {}
    }
  );
  const { errors } = formState;

  const holdPaperFromDate = watch('holdPaperFromDate');

  useEffect(() => {
    if (holdPaperFromDate > getValues('holdPaperToDate')) {
      setValue('holdPaperToDate', holdPaperFromDate);
    }
  }, [holdPaperFromDate]);

  const onSubmit = async (fields: HoldPaperFields) => {
    setLoading(true);

    let success = false;
    try {
      await userDistributionService.updateHoldPaper(
        fields.holdPaperFromDate,
        fields.holdPaperToDate
      );
      success = true;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);

      if (success) {
        onFinished();
      }
    }
  };

  const validateHoldPaperFromDate = (val: Date) => {
    holdPaperFromDate;

    let holdPaperToDate;

    // Check if 'holdPaperToDate' is already a Date object or needs to be converted
    if (holdPaperFromDate instanceof Date) {
      holdPaperToDate = holdPaperFromDate;
    } else {
      // Parse 'holdPaperToDate' to a Date object
      holdPaperToDate = new Date(holdPaperFromDate);
    }

    // Validate 'holdPaperToDate'
    if (isNaN(holdPaperToDate.getTime())) {
      holdPaperToDate = undefined;
    }

    if (validate.date(val) && val >= new Date() && !holdPaperToDate) {
      return true;
    }

    // If 'holdPaperToDate' is defined and valid, 'val' should be smaller or equal to it
    return holdPaperToDate ? val <= holdPaperToDate : false;
  };

  return (
    <Dialog open={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {userDistribution ? t('paper.holdPaper.editTitle') : t('paper.holdPaper.addTitle')}
        </DialogTitle>
        <DialogContent>
          <Typography>{t('paper.holdPaper.description')}</Typography>
          <Stack direction={isMobile ? 'column' : 'row'} gap={1} sx={{ mt: 2 }}>
            <ReactHookDatePicker
              label={t('common.from')}
              name={'holdPaperFromDate'}
              control={control}
              required
              validate={validateHoldPaperFromDate}
              minDate={new Date()}
              error={errors?.holdPaperFromDate ? true : false}
            />
            <ReactHookDatePicker
              required
              label={t('common.to')}
              name={'holdPaperToDate'}
              control={control}
              validate={(val: Date) => {
                return validate.date(val) && val >= holdPaperFromDate;
              }}
              minDate={holdPaperFromDate}
              error={errors?.holdPaperToDate ? true : false}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ marginRight: 'auto' }}
            color={'error'}
            onClick={() => onSubmit({ holdPaperFromDate: null, holdPaperToDate: null })}
          >
            {t('common.deactivate')} {t('paper.holdPaper..').toLowerCase()}
          </Button>
          {loading && <CircularProgress size={'26px'} />}
          <Button disabled={loading} onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button disabled={loading} type={'submit'}>
            {t('common.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default HoldPaperModal;
