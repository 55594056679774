import i18n from '../i18n/index';

const getReadbleDuration = (months: number) => {
  if (months === 1) {
    return i18n.t('abbr.month');
  } else if (months === 12) {
    return i18n.t('abbr.year');
  } else {
    return `${months} ${i18n.t('abbr.months')}`;
  }
};
export default getReadbleDuration;