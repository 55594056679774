import axios, { AxiosPromise } from 'axios';
import Subscription from 'types/Subscription';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const subscriptionService = {
  getSubscriptions: (): AxiosPromise<Subscription[]> => {
    return axios.get('subscription/active');
  },
  getSubscription: (id: number): AxiosPromise<Subscription> => {
    return axios.get(`subscription/${id}`);
  },
  getActiveSubscription: (id: number): AxiosPromise<Subscription> => {
    return axios.get(`subscription/active/${id}`);
  },
  getGiftSubscriptions: (): AxiosPromise<Subscription[]> => {
    return axios.get('subscription/giftable');
  }
};

export default subscriptionService;
