/* eslint-disable indent */
import { Stack, SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import './Login.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Navigate, useLocation } from 'react-router';
import { PageContainer, PrimaryCard } from 'components';
import LoginForm from './components/LoginForm/LoginForm';
import routes from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import OrderSubscriptionState from 'types/OrderSubscriptionState';
import LogoPaperHeader from 'components/LogoPaperHeader/LogoPaperHeader';
import PaperFooter from 'components/PaperFooter/PaperFooter';
import RedirectToNewspaperDialog from 'components/RedirectToNewspaperDialog/RedirectToNewspaperDialog';
import SelectedSubscriptionPayment from 'pages/OrderPaymentPage/components/SelectedSubscriptionPayment';
import LinkButton from 'components/LinkButton/LinkButton';

const Login: FC = () => {
  const locationState = useLocation().state as OrderSubscriptionState;
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.user);
  const redirecting = useSelector((state: RootState) => state.redirecting);

  const styledPaperContent: SxProps = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '16px',
    gap: locationState ? '12px' : '0px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px'
    }
  };

  const giftSubscriptionRedirect = locationState?.returnUrl?.startsWith('/gift-subscription')
    ? true
    : false;

  // Redirect rules for when the user has logged in
  if (user) {
    // If a returnUrl is appended to the state before redirecting to login, redirect to that url
    if (locationState?.returnUrl && !locationState?.selectedSubscription) {
      return <Navigate to={locationState.returnUrl} />;
    }

    // If the user has chosen a subscription and is not subscribed, redirect to new order
    if (
      locationState &&
      (!user.userSubscription || user?.userSubscription?.status === 'CANCELED')
    ) {
      return <Navigate to={routes.ORDER_SUBSCRIPTION_SELECT.path} state={locationState} />;
    }

    // Default redirect to home
    return <Navigate to={'/'} />;
  } else {
    return (
      <>
        <PageContainer
          className='login-page-container'
          centerHorizontal
          centerVertical
          footerLight
          pageCard
        >
          <PaperCard pageCard>
            <LogoPaperHeader
              link
              sx={{
                ['img']: { height: '70px', maxHeight: '70px', maxWidth: '100%', width: 'auto' }
              }}
            />
            <Stack justifyContent={'space-between'} sx={{ flex: 1 }}>
              <PaperContent sx={styledPaperContent}>
                {locationState && locationState.selectedSubscription && (
                  <SelectedSubscriptionPayment
                    subscription={locationState.selectedSubscription}
                    campaign={locationState.selectedCampaign}
                    editDisabled={false}
                  />
                )}
                {giftSubscriptionRedirect && (
                  <PrimaryCard variant={'outlined'} sx={{ width: '100%', boxSizing: 'border-box' }}>
                    <Typography fontSize={'1.2rem'} fontWeight={'700'}>
                      {t('giftSubscription.information.login')}{' '}
                    </Typography>
                    <Typography fontSize={'0.8rem'} fontStyle={'italic'}>
                      {t('giftSubscription.information.login2')}{' '}
                    </Typography>
                  </PrimaryCard>
                )}
                <LoginForm
                  borderBox={
                    locationState?.selectedSubscription || giftSubscriptionRedirect ? true : false
                  }
                />
              </PaperContent>
              {!locationState && (
                <PaperFooter
                  hideFooterHeight
                  sx={{
                    position: 'initial'
                  }}
                >
                  <Stack direction='column' gap={2}>
                    <LinkButton to={routes.ORDER_SUBSCRIPTION_SELECT.path} variant={'text'}>
                      {t('subscription.seeSubscriptionsAndPrices')}
                    </LinkButton>
                  </Stack>
                </PaperFooter>
              )}
            </Stack>
          </PaperCard>
        </PageContainer>
        {redirecting && <RedirectToNewspaperDialog />}
      </>
    );
  }
};

export default Login;
