import { FC, useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { validate } from 'utils';
import { useForm } from 'react-hook-form';
import { FormDialog } from 'components';
import useUser from 'hooks/useUser';

type ChangeEmailDialogProps = {
  email?: string;
  onCancel: () => void;
  onSuccess: () => void;
};

type ChangeEmailFields = {
  email: string;
  password: string;
};

const ChangeEmailDialog: FC<ChangeEmailDialogProps> = ({ email, onCancel, onSuccess }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ChangeEmailFields>({
    defaultValues: { email: email?.toString() || '' }
  });

  const { changeEmail } = useUser();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: ChangeEmailFields) => {
    const { email, password } = data;
    setLoading(true);
    try {
      await changeEmail(email, password);
      onSuccess();
    } finally {
      // Error handled in changeEmail
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      title={t('common.changeEmail')}
      submitText={t('common.change')}
      loading={loading}
      sxContent={{ maxWidth: '350px' }}
      sxHeader={{ fontSize: '1.8rem' }}
    >
      <Stack spacing={2}>
        <TextField
          autoFocus
          style={{ width: '100%' }}
          type='email'
          label={t('common.email')}
          {...register('email', {
            validate: (val) => validate.email(val)
          })}
          error={errors.email ? true : false}
          helperText={errors.email ? errors.email.message : ''}
        />
        <Typography sx={{ marginBottom: '16px', fontStyle: 'italic', fontSize: '0.8em' }}>
          {t('profile.toChangeEmailYouMustProvidePassword')}
        </Typography>
        <TextField
          type='password'
          label={t('common.password')}
          {...register('password', {
            required: t('password.passwordEmpty') as string
          })}
          error={errors.password ? true : false}
          helperText={errors.password ? errors.password.message : ''}
        />
      </Stack>
    </FormDialog>
  );
};

export default ChangeEmailDialog;
