import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from 'types/User';
import UserSubscription from 'types/UserSubscription';

type UserSubscriptionUpdateModel = {
  hasActiveUserSubscription: boolean;
  userSubscription: UserSubscription;
}

const initialState = null as User; 
 
export const userReducer = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setUser: (_state, action: PayloadAction<User>) => {
      return action.payload;
    },
    updateUserSubscription: (state, action: PayloadAction<UserSubscriptionUpdateModel>) => {
      return {...state, ...action.payload};
    },
  },
});

export const { actions } = userReducer;

export default userReducer;
