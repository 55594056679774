import { Receipt } from '@mui/icons-material';
import { Chip, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material';

import PaymentSolutionLogo from 'components/PaymentSolutionLogo/PaymentSolutionLogo';
import routes from 'navigation/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import theme from 'styles/theme';
import Payment from 'types/Payment';
import getDateString from 'utils/_getDateString';
import DescriptionIcon from '@mui/icons-material/Description';
import invoiceService from 'services/invoice.service';
import { AxiosError } from 'axios';
import useAxiosAlert from 'hooks/useAxiosAlert';

type PaymentRowProps = {
  payment: Payment;
};

const PaymentRow: FC<PaymentRowProps> = ({ payment }) => {
  const { t } = useTranslation();

  const axiosAlert = useAxiosAlert();
  const createdDateString = getDateString(payment.created);
  const fromDateString = getDateString(payment.fromDate);
  const toDateString = getDateString(payment.toDate);

  const getPaymentStatusColor = () => {
    let paymentStatusColor:
      | 'primary'
      | 'secondary'
      | 'success'
      | 'error'
      | 'warning'
      | 'default'
      | 'info';

    switch (payment.status) {
      case 'CANCELED':
      case 'EXPIRED':
        paymentStatusColor = 'default';
        break;
      case 'PROCESSING':
      case 'NOT_CAPTURED':
      case 'DUE':
        paymentStatusColor = 'info';
        break;
      case 'REFUNDED':
        paymentStatusColor = 'warning';
        break;
      case 'CAPTURED':
        paymentStatusColor = 'success';
        break;
      case 'FAILED':
        paymentStatusColor = 'error';
        break;
      default:
        paymentStatusColor = 'default';

        break;
    }
    return paymentStatusColor;
  };

  const getReceiptAvailible = () => {
    if (
      payment.paymentSolution !== 'invoice' &&
      (payment.status === 'CAPTURED' || payment.status === 'REFUNDED')
    ) {
      return true;
    }

    return false;
  };

  const receiptAvailible = getReceiptAvailible();

  const downloadInvoicePdf = async (paymentId: number) => {
    try {
      const pdfInvoiceQuery = await invoiceService.getInvoicePdf(paymentId);
      const a = document.createElement('a');
      a.href = 'data:application/octet-stream;base64,' + pdfInvoiceQuery.data.base64;
      a.download = pdfInvoiceQuery.data.name;
      a.click();
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    }
  };

  const downloadCreditNotePdf = async (paymentId: number) => {
    try {
      const pdfInvoiceQuery = await invoiceService.getInvoicePdf('R' + paymentId);
      const a = document.createElement('a');
      a.href = 'data:application/octet-stream;base64,' + pdfInvoiceQuery.data.base64;
      a.download = pdfInvoiceQuery.data.name;
      a.click();
    } catch (error) {
      axiosAlert(error as AxiosError, []);
    }
  };

  return (
    <TableRow key={payment.id}>
      <TableCell>
        {payment.paymentSolution === 'invoice' ? (
          <>
            <Tooltip title={t('common.invoice')}>
              <IconButton onClick={() => downloadInvoicePdf(payment.id)}>
                <DescriptionIcon />
              </IconButton>
            </Tooltip>
            {payment.status === 'REFUNDED' && (
              <Tooltip title={t('common.creditNote')}>
                <IconButton color='warning' onClick={() => downloadCreditNotePdf(payment.id)}>
                  <DescriptionIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : receiptAvailible ? (
          <Link
            style={{ color: theme.palette.secondary.main }}
            to={receiptAvailible ? routes.RECEIPT.path.replace(':id', payment?.id.toString()) : ''}
            target='_blank'
          >
            <IconButton>
              <Receipt />
            </IconButton>
          </Link>
        ) : (
          <IconButton disabled>
            <Receipt />
          </IconButton>
        )}
      </TableCell>
      <TableCell>
        <Typography sx={{ fontWeight: 500 }} variant='body1'>
          {createdDateString}
        </Typography>
      </TableCell>
      <TableCell>
        <Chip variant='outlined' label={t(`payment.orderTypes.${payment.orderType}`)} />
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{`${fromDateString} - ${toDateString}`}</Typography>
      </TableCell>
      <TableCell>
        <Chip
          variant='outlined'
          label={t(`payment.status.${payment.status === 'DUE' ? 'PROCESSING' : payment.status}`)}
          color={getPaymentStatusColor()}
        />
      </TableCell>
      <TableCell>
        <Typography sx={{ whiteSpace: 'nowrap' }} variant='body1'>
          {payment.price} kr
        </Typography>
      </TableCell>
      <TableCell>
        <PaymentSolutionLogo
          sx={{ justifyContent: 'flex-start' }}
          height={30}
          paymentSolution={payment.paymentSolution}
        />
      </TableCell> 
    </TableRow>
  );
};

export default PaymentRow;
