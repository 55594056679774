import Skin from 'types/Skin';

const defaults: Skin = {
  COLORS: {
    PRIMARY: '#ba0c30',
    PRIMARY_CONTRAST: '#ffffff',
    PRIMARY_DARK: '#5d0605',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff'
  },
  LOGO: {
    MAIN: 'https://dypnytt.no/wp-content/uploads/2021/07/artboard-1-1.png',
    ICON: 'https://dypnytt.no/wp-content/uploads/2021/07/artboard-1-122x.png'
  }
};

export default defaults;
