import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validate } from 'utils';
import useDevice from 'hooks/useDevice';

type PhoneNumberInputProps = {
  focusInput: boolean;
  formSubmitted: boolean;

  fieldName: string;
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  focusInput,
  formSubmitted,
  fieldName
}) => {
  const { t } = useTranslation();
  const device = useDevice();
  const { setValue, getValues, formState, register } = useFormContext();
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputEvent = event.nativeEvent as InputEvent;
    const cleanedValue = event.target.value.replace(/\D/g, ''); // Remove non-digit characters

    if (cleanedValue.length >= 10 && inputEvent.inputType !== 'deleteContentBackward') {
      return;
    }

    const formattedValue = cleanedValue;
    setPhoneNumber(formattedValue);
    setValue(fieldName, cleanedValue); // Update the form value without spaces
  };

  // Synchronize the form value with the phone number display value
  useEffect(() => {
    const formValue = getValues(fieldName);
    setPhoneNumber(formValue);
  }, [getValues]);

  return (
    <TextField
      id={fieldName}
      autoComplete={device === 'mobile' ? 'off' : 'username'}
      inputRef={(input) => {
        if (focusInput && input) {
          input.focus();
        }
      }}
      {...register(fieldName, {
        required: true,
        validate: () => {
          return validate.phone(getValues(fieldName).split(' ').join(''))
            ? true
            : t('alerts.phoneNumberNotValid').toString();
        }
      })}
      value={phoneNumber}
      onChange={handleChange}
      sx={{ flex: 1 }}
      type={formSubmitted ? 'text' : 'tel'}
      label={t('login.writeYourPhoneNumber') + ' *'}
      placeholder={t('login.phoneNumber') + ' *'}
      className={'field phone-number-input username'}
      disabled={formSubmitted}
      error={formState.errors?.[fieldName] ? true : false}
      helperText={
        formState.errors?.[fieldName] ? formState.errors?.[fieldName].message.toString() : ''
      }
    />
  );
};

export default PhoneNumberInput;
