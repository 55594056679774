import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type ConsentDialogProps = {
  title?: string;
  content?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmText?: string;
};

const ConsentDialog: FC<ConsentDialogProps> = ({
  title,
  content,
  onConfirm,
  onCancel,
  confirmText
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <Button onClick={onConfirm}>{confirmText || t('common.confirmChanges')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentDialog;
