/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, CircularProgress } from '@mui/material';
import useSettings from 'hooks/useSettings';
import routes from 'navigation/routes';
import { FC, useEffect, useState } from 'react';
import orderService from 'services/order.service';
import swedbankService from 'services/swedbank.service';
import Campaign from 'types/Campaign';
import Subscription from 'types/Subscription';
import SwedbankOrder, { SwedbankOrderItem, SwedbankPayeeInfo } from 'types/Swedbank/SwedbankOrder';
import calculateVat from 'utils/_calculateVat';
import defaultOrder from './utils/defaultOrder';
import useScript from 'hooks/useScript';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Order from 'types/Order';
import useAlert from 'hooks/useAlert';
import logger from 'logger/logger';
import { useTranslation } from 'react-i18next';
import FlexBox from 'components/FlexBox/FlexBox';

type SwedbankCheckoutProps = {
  subscription: Subscription;
  campaign: Campaign;
  order: Order;
  returnUrl?: string;
};

type Prices = {
  subscriptionPrice: number;
  campaignPrice?: number;
  totalPrice: number;
  vatPrice: number;
};

interface WindowConfig extends Window {
  payex: any;
}

declare const window: WindowConfig;

const SwedbankCheckout: FC<SwedbankCheckoutProps> = ({
  subscription,
  campaign,
  order,
  returnUrl
}) => {
  // Create order
  const { getValueByKey } = useSettings();
  const [loading, setLoading] = useState<boolean>();
  const vat = parseInt(getValueByKey('Vat', '0'), 10);
  const platformTitle = getValueByKey('PlatformTitle');
  const [swedbankScriptUrl, setSwedbankScriptUrl] = useState<string>();
  const [iframeLoaded, setIframeLoaded] = useState<boolean>();
  const { userSubscription } = useSelector((state: RootState) => state.user);
  const { addAlert } = useAlert();
  const { t } = useTranslation();

  const termsOfServiceUrl = getValueByKey('TermsOfServiceUrl');

  useScript({
    url: swedbankScriptUrl,
    onLoadCb: () => {
      setLoading(false);

      if (!iframeLoaded && window?.payex && window?.payex?.hostedView) {
        window.payex.hostedView
          .checkout({
            container: {
              checkout: 'checkout-container'
            },
            culture: 'nb-No',
            onCheckoutLoaded: () => setIframeLoaded(true)
          })
          .open();
      }
    }
  });

  const getPrices = (): Prices => {
    let totalPrice = 0;
    let campaignPrice;

    if (campaign) {
      totalPrice = campaign.price;
      campaignPrice = campaign.price;
    } else {
      totalPrice = subscription.price;
    }

    const vatPrice = calculateVat(totalPrice, vat);

    return {
      totalPrice: parseFloat(totalPrice.toFixed(2)),
      subscriptionPrice: parseFloat(subscription.price.toFixed(2)),
      campaignPrice,
      vatPrice: parseFloat(vatPrice.toFixed(2))
    };
  };

  const createOrder = (orderId: string): SwedbankOrder => {
    const paymentRoutePath =
      order.type === 'GIFT_SUBSCRIPTION'
        ? routes.GIFT_SUBSCRIPTION_PAYMENT.path
        : routes.ORDER_SUBSCRIPTION_PAYMENT.path;

    const completeUrl = `${window.location.origin}${routes.ORDER_CONFIRMATION.path.replace(
      ':uuid',
      order.uuid.toString()
    )}${returnUrl ? '?returnUrl=' + returnUrl : ''}`;

    const paymentUrl = `${window.location.origin}${paymentRoutePath.replace(
      ':uuid',
      order.uuid.toString()
    )}${returnUrl ? '?returnUrl=' + returnUrl : ''}`;

    const { vatPrice, totalPrice, campaignPrice } = getPrices();
    const swedbankOrder: SwedbankOrder = {
      ...defaultOrder,
      amount: totalPrice * 100,
      vatAmount: vatPrice * 100,
      description: 'Subsription',
      generateRecurrenceToken: order.type === 'GIFT_SUBSCRIPTION' ? false : true,
      urls: {
        completeUrl,
        paymentUrl,
        termsOfServiceUrl: termsOfServiceUrl,
        ...defaultOrder.urls
      }
    };

    const payeeInfo: SwedbankPayeeInfo = {
      payeeName: platformTitle,
      productCategory: 'subscription',
      payeeReference: orderId + new Date().getTime()
    };

    const orderItems: SwedbankOrderItem[] = [
      {
        reference: subscription.id.toString(),
        amount: totalPrice * 100,
        vatAmount: vatPrice * 100,
        vatPercent: vat * 100,
        name: subscription.name,
        unitPrice: totalPrice,
        description: subscription.description,
        ...swedbankOrder.orderItems[0]
      }
    ];

    if (campaignPrice) {
      orderItems[0].discountPrice = campaignPrice;
    }

    swedbankOrder.payeeInfo = payeeInfo;
    swedbankOrder.orderItems = orderItems;

    return swedbankOrder;
  };

  const createVerifyOrder = (orderId: string) => {
    const swedbankOrder: SwedbankOrder = {
      ...defaultOrder,
      operation: 'Verify',
      description: 'Subsription verification',
      urls: {
        completeUrl: `${window.location.origin}${routes.ORDER_CONFIRMATION.path.replace(
          ':uuid',
          order.uuid.toString()
        )}`,
        paymentUrl: `${window.location.origin}${routes.CHANGE_PAYMENT_METHOD.path.replace(
          ':uuid',
          order.uuid.toString()
        )}`,
        termsOfServiceUrl: termsOfServiceUrl,
        ...defaultOrder.urls
      },
      payeeInfo: {
        payeeName: platformTitle,
        productCategory: 'subscription',
        payeeReference: orderId + new Date().getTime()
      }
    };

    return swedbankOrder;
  };

  const initSwedbankCheckout = async () => {
    let orderFound: boolean;

    if (order.paymentSolution === 'swedbank' && order.orderId.includes('/psp')) {
      try {
        const { data: swedbankOrderRes } = await swedbankService.getSwedbankOrder(order.id);

        const viewCheckoutOperation = swedbankOrderRes.operations.find(
          (operation) => operation.rel === 'view-checkout'
        );

        if (!viewCheckoutOperation) {
          return;
        }

        setSwedbankScriptUrl(viewCheckoutOperation.href);
      } catch {
        orderFound = false;
      }
    } else {
      if (!orderFound) {
        createSwedbankOrder();
      }
    }
  };

  const createSwedbankOrder = async () => {
    setLoading(true);

    // Creating order
    try {
      await orderService.update(order.id, {
        paymentSolution: 'swedbank'
      });

      const swedbankOrder =
        order.type === 'SUBSCRIPTION' || order.type === 'GIFT_SUBSCRIPTION'
          ? createOrder(order?.id?.toString())
          : createVerifyOrder(order?.id?.toString());

      const { data } = await swedbankService.createOrder(swedbankOrder);

      if (!data.operations && data.operations.length > 0) {
        throw Error('No operations availible');
      }

      const operation = data.operations.find((operation) => operation.rel === 'view-checkout');

      if (!operation) {
        return;
      }

      setSwedbankScriptUrl(operation.href);

      // Update order
      const swedbankOrderId = data.paymentOrder.id;
      const updateOrderModel: Order = {
        status: 'PENDING',
        orderId: swedbankOrderId
      };

      // Handle order type CHANGE_PAYMENT_SOLUTION
      if (order.type === 'CHANGE_PAYMENT_SOLUTION') {
        // Active userSubscription needed for changing payment method
        if (!userSubscription) {
          return;
        }

        updateOrderModel.userSubscriptionId = userSubscription.id;
      }

      await orderService.update(order.id, updateOrderModel);
    } catch (error) {
      logger.error('Could not create swedbank order');
      setIframeLoaded(true);
      setSwedbankScriptUrl('');
      addAlert('error', t('payment.alerts.couldNotCreate'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initSwedbankCheckout();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      {loading && (
        <FlexBox>
          <CircularProgress />
        </FlexBox>
      )}

      <Box sx={{ width: '100%' }} id='checkout-container'></Box>
    </Box>
  );
};

export default SwedbankCheckout;
