import axios, { AxiosPromise } from 'axios';
import SwedbankOrder from 'types/Swedbank/SwedbankOrder';
import SwedbankOrderRes from 'types/Swedbank/SwedbankOrderRes';

const swedbankService = {
  createOrder: (swedbankOrder: SwedbankOrder): AxiosPromise<SwedbankOrderRes> => {
    return axios.post('payment-solution/swedbank/order/current-user', swedbankOrder);
  },
  updateOrder: (orderId: number, swedbankOrderId: string, swedbankOrder: SwedbankOrder) => {
    return axios.patch(`payment-solution/swedbank/order/current-user/${orderId}`, {
      order: swedbankOrder,
      externalOrderId: swedbankOrderId
    });
  },
  getOrder: (orderId: number): AxiosPromise<SwedbankOrder> => {
    return axios.get(`payment-solution/swedbank/order/current-user/${orderId}`);
  },
  getOrderByUuid: (uuid: string): AxiosPromise<SwedbankOrder> => {
    return axios.get(`payment-solution/swedbank/order/uuid/${uuid}`);
  },
  getRecurringTokenByUuid: (uuid: string): AxiosPromise<string> => {
    return axios.get(`payment-solution/swedbank/order/recurring-token/uuid/${uuid}`);
  },
  getPaymentId: (orderId: number): AxiosPromise<string> => {
    return axios.get(`payment-solution/swedbank/order/${orderId}/current-user/payment-id`);
  },
  getSwedbankOrder: (orderId: number): AxiosPromise<SwedbankOrderRes> => {
    return axios.get(`payment-solution/swedbank/current-user/order/${orderId}/swedbank-order`);
  }
};

export default swedbankService;
