import { Alert, Button, CircularProgress, SxProps, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AxiosError } from 'axios';
import FlexBox from 'components/FlexBox/FlexBox';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import useAxiosAlert from 'hooks/useAxiosAlert';
import useUser from 'hooks/useUser';
import logger from 'logger/logger';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import userProfileService from 'services/user-profile.service';
import { RootState } from 'store';
import { validate } from 'utils';

type Fields = {
  email: string;
};

const EmailAdressForm: FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const { fetchUser } = useUser();
  const [loading, setLoading] = useState<boolean>();
  const [formCompleted, setFormCompleted] = useState<boolean>();
  const axiosAlert = useAxiosAlert();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<Fields>({
    defaultValues: {
      email: user?.email || ''
    }
  });

  // If on of the field is not defiend then display form
  const displayForm = !user?.email;

  const styledPaperCard: SxProps = {
    width: '100%',
  };

  const styledTextField: SxProps = {
    marginBottom: 0
  };

  const getFocus = (field: string): boolean => {
    let isFocused: boolean;

    const email = getValues().email;

    switch (field) {
      case 'email':
        isFocused = !email;
        break;

        break;
      default:
        break;
    }
    return isFocused;
  };

  const onSubmit = async (fields: Fields) => {
    const { email } = fields;
    setLoading(true);
    try {
      await userProfileService.addEmail(email);

      await fetchUser();
      setFormCompleted(true);
    } catch (error) {
      logger.error('Order completed create email form failed.', error);

      axiosAlert(error as AxiosError, [
        {
          status: 400,
          message: t('alerts.fieldsError')
        },
        {
          status: 409,
          message: t('profile.emailMustBeUnique')
        }
      ]);
    } finally {
      setLoading(false);
    }
  };

  if (displayForm && !formCompleted) {
    return (
      <PaperCard sx={styledPaperCard} variant={'outlined'}>
        <PaperHeader>
          <Typography variant='h4'>{t('profile.completeYourProfile')}</Typography>
        </PaperHeader>
        <PaperContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FlexBox direction='column' gap={12}>
              <Typography>{t('profile.pleaseAddEmail')}</Typography>
              {!user.email && (
                <TextField
                  disabled={loading}
                  sx={styledTextField}
                  fullWidth
                  required
                  {...register('email', {
                    required: true,
                    validate: (val) => validate.email(val)
                  })}
                  type={'email'}
                  autoFocus={getFocus('email') ? true : false}
                  error={errors.email ? true : false}
                  label={t('common.email')}
                  placeholder={t('common.email')}
                />
              )}
              {!loading && (
                <Button type='submit' variant='contained' disabled={loading} fullWidth>
                  {t('common.addEmail')}
                </Button>
              )}
              <FlexBox gap={8} alignItems={'center'} justifyContent='center'>
                {loading && <CircularProgress />}
              </FlexBox>
            </FlexBox>
          </form>
        </PaperContent>
      </PaperCard>
    );
  } else if (formCompleted) {
    return (
      <Box sx={{ marginTop: '16px' }}>
        <Alert severity='success'>{t('profile.alerts.profileUpdated')}</Alert>
      </Box>
    );
  } else {
    return null;
  }
};

export default EmailAdressForm;
