import { FC, ReactNode } from 'react';
import { Alert, Button, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { validateAddressFields } from 'utils';
import { useTranslation } from 'react-i18next';

type ProfileAlertsProps = {
  onAlertActionClick?: () => void;
};

const ProfileAlerts: FC<ProfileAlertsProps> = ({ onAlertActionClick }) => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);

  const getAlerts = () => {
    // if subscription has paper but address is not valid, show alert
    const alerts: ReactNode[] = [];

    if (user?.userSubscription?.subscription?.paper && !validateAddressFields(user?.userAddress)) {
      alerts.push(
        <Alert
          key={'addressMissingWarning'}
          onClick={onAlertActionClick}
          action={
            <Button color='warning' size='small'>
              {t('common.changeBlank', {
                blank: t('common.address')
              })}
            </Button>
          }
          severity='warning'
        >
          {t('userAddress.alerts.addressMissingWarning')}
        </Alert>
      );
    }

    return alerts;
  };

  const alerts = getAlerts();

  return (
    <Stack width={'100%'} gap={2}>
      {alerts}
    </Stack>
  );
};

export default ProfileAlerts;
