import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const userProfileService = {
  getInfo: () => {
    // Add no cache header to prevent caching from 410 response
    return axios.get('user-profile', { headers: { 'Cache-Control': 'no-cache' } });
  },
  requestEmailChange: () => {
    return axios.get('user-profile/request-email-change');
  },
  changeEmail: (newEmail: string, password: string) => {
    return axios.post<string>('user-profile/change-email', {
      newEmail: newEmail,
      password: password
    });
  },
  addPostCode: (postCode: string) => {
    return axios.post('user-profile/add-post-code', {
      postCode: postCode
    });
  },
  addEmail: (email: string) => {
    return axios.post('user-profile/add-email', {
      email: email
    });
  },
  changeName: (name: string) => {
    return axios.put('user-profile/change-name', {
      name: name
    });
  },
  changePhoneNumber: (newPhoneNumber: number, password: string) => {
    return axios.put('user-profile/change-phone-number', {
      phone: newPhoneNumber,
      password: password
    });
  },
  changeAddress: (address: string, postCode: string, postOffice: string) => {
    return axios.put('user-profile/change-address', {
      address,
      postCode,
      postOffice
    });
  }
};

export default userProfileService;
