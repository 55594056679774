import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useAlert from './useAlert';

export type AxiosAlert = {
  status: number;
  message?: string;
  cb?: () => void;
};

const useAxiosAlert = () => {
  const { addAlert } = useAlert();
  const alertsState = useSelector((state: RootState) => state.alert);
  const { t } = useTranslation();

  const axiosAlert = (
    error: AxiosError,
    alerts?: AxiosAlert[],
    defaultAction?: {
      cb?: () => void;
      message?: string;
    }
  ) => {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;

      let firedAlert = false;

      if (alerts) {
        alerts.forEach((alert) => {
          if (alert?.status === axiosError?.response?.status) {
            firedAlert = true;

            if (alert.message) {
              addAlert('error', alert.message);
            }

            if (alert.cb) {
              alert.cb();
            }
          }
        });
      }

      if (!error.response && error.request && alertsState.length === 0) {
        addAlert('error', t('alerts.networkError'));
      }
      
      if (defaultAction && !firedAlert) {
        if (defaultAction.message) {
          addAlert('error', defaultAction.message);
        }

        if (defaultAction.cb) {
          defaultAction.cb();
        }
      }
    } else {
      addAlert('error', t('alerts.somethingWentWrong'));
    }
  };

  return axiosAlert;
};

export default useAxiosAlert;
