import UserAddress from 'types/UserAddress';

/**
 * @name validateAddressFields
 * @description Validates a user address object
 */
const validateAddressFields = (userAddress: UserAddress): boolean => {
  if (!userAddress) {
    return false;
  }

  if (!userAddress.address) {
    return false;
  }

  if (!userAddress.postCode) {
    return false;
  }

  if (!userAddress.postOffice) {
    return false;
  }

  return true;
};

export default validateAddressFields;
