import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import FlexBox from 'components/FlexBox/FlexBox';
import PaperCard from 'components/PaperCard/PaperCard';
import PaymentSolutionLogo from 'components/PaymentSolutionLogo/PaymentSolutionLogo';
import SwedbankCheckout from 'components/SwedbankCheckout/SwedbankCheckout';
import VippsCheckout from 'pages/OrderPaymentPage/components/VippsCheckout';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Campaign from 'types/Campaign';
import Order from 'types/Order';
import PaymentSolution from 'types/PaymentSolution';
import Subscription from 'types/Subscription';
import User from 'types/User';

type PaymentPickerProps = {
  onSelect?: (PaymentSolution: PaymentSolution) => void;
  onChange?: (payemtnSoluton: PaymentSolution) => void;
  selectedPaymentSolution?: PaymentSolution;
  subscription?: Subscription;
  campaign?: Campaign;
  user?: User;
  order: Order;
  disableNonSelected?: boolean;
  usingVipps?: boolean;
  usingSwedbank?: boolean;
  returnUrl?: string;
  onRedirect?: () => void;
};

const PaymentPicker: FC<PaymentPickerProps> = ({
  onChange,
  selectedPaymentSolution,
  campaign,
  subscription,
  user,
  order,
  disableNonSelected,
  returnUrl,
  usingSwedbank,
  usingVipps,
  onRedirect
}) => {
  const { t } = useTranslation();
  const [displayVippsCheckout, setDisplayVippsCheckout] = useState<boolean>();

  const userSubscription = user?.userSubscription;

  const getCardPaymentLabel = () => {
    if (
      userSubscription &&
      userSubscription.status === 'ACTIVE' &&
      userSubscription.paymentSolution === 'swedbank' &&
      order.type === 'CHANGE_PAYMENT_SOLUTION'
    ) {
      return t('payment.changeCard');
    }

    order.type === 'CHANGE_PAYMENT_SOLUTION';
    if (
      order.type === 'SUBSCRIPTION' ||
      order.type === 'GIFT_SUBSCRIPTION' ||
      order.type === 'CHANGE_PAYMENT_SOLUTION'
    ) {
      return t('payment.cardPayment');
    }
  };

  const handleVippsFail = () => {
    setDisplayVippsCheckout(null);
  };

  const handleDisplayVippsCheckout = () => {
    if (displayVippsCheckout) {
      // trigger re-render of VippsCheckout component
      setDisplayVippsCheckout(false);
    } else {
      setDisplayVippsCheckout(true);
    }
  };

  useEffect(() => {
    if (displayVippsCheckout === false) {
      setDisplayVippsCheckout(true);
    }
  }, [displayVippsCheckout]);

  const disableVipps = disableNonSelected && order?.paymentSolution === 'swedbank';
  const disableSwedbank =
    (disableNonSelected && order?.paymentSolution === 'vipps') || displayVippsCheckout;

  return (
    <FormControl sx={{ width: '100%' }}>
      <RadioGroup
        name='paymentSolution'
        defaultValue={'vipps'}
        sx={{ width: '100%' }}
        value={selectedPaymentSolution}
        onChange={(e) => onChange(e.target.value as PaymentSolution)}
      >
        <FlexBox direction='column' gap={12} sx={{ width: '100%' }}>
          {usingVipps && (
            <PaperCard variant='outlined' sx={{ padding: '16px', width: '100%' }}>
              <FlexBox gap={12} direction={'column'}>
                <FlexBox justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                  <FormControlLabel
                    disabled={disableVipps}
                    value='vipps'
                    control={<Radio />}
                    label='Vipps'
                  />
                  <PaymentSolutionLogo paymentSolution='vipps' height={25} />
                </FlexBox>
                {selectedPaymentSolution === 'vipps' && usingVipps && (
                  <FlexBox
                    justifyContent='flex-end'
                    sx={{ width: '100%' }}
                    direction={'column'}
                    gap={24}
                  >
                    <Button variant='outlined' fullWidth onClick={handleDisplayVippsCheckout}>
                      {t('common.continue')}
                    </Button>
                    {displayVippsCheckout && (
                      <FlexBox justifyContent='center' sx={{ width: '100%' }}>
                        <VippsCheckout
                          user={user}
                          order={order}
                          campaign={campaign}
                          subscription={subscription}
                          returnUrl={returnUrl}
                          onRedirect={onRedirect}
                          onFail={handleVippsFail}
                        />
                      </FlexBox>
                    )}
                  </FlexBox>
                )}
              </FlexBox>
            </PaperCard>
          )}
          {usingSwedbank && (
            <PaperCard variant='outlined' sx={{ padding: '16px', width: '100%' }}>
              <FlexBox gap={12} direction={'column'}>
                <FlexBox justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                  <FlexBox
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ width: '100%' }}
                  >
                    <FormControlLabel
                      value='swedbank'
                      disabled={disableSwedbank}
                      control={<Radio />}
                      label={getCardPaymentLabel()}
                    />
                    <PaymentSolutionLogo paymentSolution='swedbank' height={25} />
                  </FlexBox>
                </FlexBox>
                {selectedPaymentSolution === 'swedbank' && (
                  <SwedbankCheckout
                    campaign={campaign}
                    subscription={subscription}
                    order={order}
                    returnUrl={returnUrl}
                  />
                )}
              </FlexBox>
            </PaperCard>
          )}
        </FlexBox>
      </RadioGroup>
    </FormControl>
  );
};

export default PaymentPicker;
