import './ChangePasswordDialog.scss';
import { FC, useState } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { validate } from 'utils';
import { useForm } from 'react-hook-form';
import { FormDialog } from 'components';
import useUser from 'hooks/useUser';

type ChangePasswordDialogProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

type ChangePasswordFields = {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
};

const ChangePasswordDialog: FC<ChangePasswordDialogProps> = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { changePassword } = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<ChangePasswordFields>();

  const onSubmit = async (fields: ChangePasswordFields) => {
    const { oldPassword, newPassword } = fields;

    setLoading(false);

    try {
      await changePassword(newPassword, oldPassword);
      onConfirm();
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      title={t('common.changePassword')}
      submitText={t('common.change')}
      loading={loading}
      sxContent={{ maxWidth: '400px' }}
      sxHeader={{ fontSize: '1.8rem' }}
    >
      <div className={'change-password-dialog inputs'}>
        <div className={`old-password-input input-container`}>
          <TextField
            autoFocus
            type='password'
            label={t('password.oldPassword', {
              required: true
            })}
            variant='outlined'
            name={'oldPassword'}
            {...register('oldPassword')}
            fullWidth
            error={errors.oldPassword ? true : false}
          />
        </div>
        <div className={'input-container'}>
          <TextField
            type='password'
            label={t('password.newPassword')}
            {...register('newPassword', {
              required: true,
              validate: validate.password
            })}
            variant='outlined'
            error={errors.newPassword ? true : false}
            fullWidth
            helperText={errors.newPassword ? errors.newPassword.message : ''}
          />
        </div>
        <div className={'input-container'}>
          <TextField
            type='password'
            label={`${t('common.confirm')} ${t('password.repeat')}`}
            {...register('newPasswordRepeat', {
              required: true,
              validate: (newPasswordRepeat) =>
                getValues('newPassword') === newPasswordRepeat
                  ? true
                  : t('password.passwordsMustMatch').toString()
            })}
            fullWidth
            variant='outlined'
            error={errors.newPasswordRepeat ? true : false}
            helperText={errors.newPasswordRepeat ? errors.newPasswordRepeat.message : ''}
          />
        </div>
      </div>
    </FormDialog>
  );
};

export default ChangePasswordDialog;
