import { Alert, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import routes from 'navigation/routes';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import userAuthService from 'services/user-auth.service';
import logger from 'logger/logger';
import { PageContainer } from 'components';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperCard from 'components/PaperCard/PaperCard';
import LogoPaperHeader from 'components/LogoPaperHeader/LogoPaperHeader';
import { validate } from 'utils';
import PaperFooter from 'components/PaperFooter/PaperFooter';
import theme from 'styles/theme';

type FormValues = {
  email: string;
};

type View = {
  loading?: boolean;
  form?: boolean;
  success?: boolean;
  errorMessage?: string;
  error?: boolean;
};

const ForgotPasswordForm: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm<FormValues>();
  const queryParams = new URLSearchParams(location.search);
  const fromApp = queryParams.get('fromApp');

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    updateView({
      loading: true
    });
    try {
      await userAuthService.forgotPassword(data.email, fromApp);
      updateView({
        success: true
      });
    } catch (error) {
      logger.error('Forgot password failed', error);

      let message = 'Client error';

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error;
        if (axiosError.response && axiosError.response.status === 400) {
          message = t('password.noUserMatchedEmail');
        } else {
          message = axiosError.message;
        }
      }

      updateView({
        error: true,
        errorMessage: message
      });
    }
  };

  const [view, setView] = useState<View>({
    loading: false,
    form: true,
    success: false,
    errorMessage: null,
    error: false
  });

  const updateView = (updatedView: View) => {
    setView({
      ...{
        loading: false,
        form: false,
        success: false,
        errorMessage: null,
        error: false
      },
      ...updatedView
    });
  };

  const handleNavigationBackToLogin = () => {
    if (fromApp) {
      // navigate to deeplink
      window.location.href = `${fromApp}://login`;
    } else {
      navigate(routes.LOGIN.path);
    }
  };

  return (
    <PageContainer
      centerHorizontal
      centerVertical
      footerLight
      pageCard
      sx={{
        [theme.breakpoints.down('md')]: {
          padding: 0
        }
      }}
    >
      <PaperCard pageCard>
        <LogoPaperHeader link />
        <PaperContent>
          <Stack gap={2}>
            <Typography variant='h4'>{t('password.forgotPassword')}</Typography>
            <Typography>{t('password.changeExplanetaion')}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={1}>
                {view.success && (
                  <Stack gap={2}>
                    <Alert severity='success'>
                      <p>
                        {t('alerts.forgotPasswordEmailSent', {
                          email: getValues('email')
                        })}
                      </p>
                    </Alert>
                  </Stack>
                )}
                {view.loading && (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress sx={{ margin: '10px 0' }} />
                  </div>
                )}
                {(view.form || view.error) && (
                  <Stack gap={1.5}>
                    {view.error && (
                      <Alert sx={{ marginBottom: '8px' }} severity='error'>
                        {view.errorMessage}
                      </Alert>
                    )}
                    <TextField
                      sx={{ flex: 1 }}
                      label={t('profile.writeYourEmail') + ' *'}
                      {...register('email', {
                        required: true,
                        validate: (val) => validate.email(val)
                      })}
                      error={errors.email ? true : false}
                    />
                    <Button variant='contained' type='submit' size='large'>
                      {t('common.sendLink')}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </form>
          </Stack>
        </PaperContent>
        <PaperFooter>
          <Button onClick={handleNavigationBackToLogin}>
            {fromApp ? t('navigation.backToApp') : t('navigation.backToLogin')}
          </Button>
        </PaperFooter>
      </PaperCard>
    </PageContainer>
  );
};

export default ForgotPasswordForm;
