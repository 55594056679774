import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type FormDialogProps = {
  title?: string;
  onSubmit: () => void;
  submitText?: string;
  onCancel: () => void;
  cancelText?: string;
  children: ReactNode;
  loading?: boolean;
  sxContent?: SxProps;
  sxHeader?: SxProps;
};

const FormDialog: FC<FormDialogProps> = ({
  title,
  onCancel,
  onSubmit,
  submitText,
  cancelText,
  children,
  loading,
  sxHeader,
  sxContent
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={true}>
      <DialogTitle sx={{ padding: '16px 24px 0px 24px', ...sxHeader }}>{title}</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent sx={{ width: '400px', padding: '16px 24px 16px 24px', ...sxContent }}>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={loading}>
            {cancelText || t('common.cancel')}
          </Button>
          <Button type={'submit'} disabled={loading}>
            {loading ? <CircularProgress size={'30px'} /> : submitText || t('common.confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormDialog;
