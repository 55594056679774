import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import FooterLinks from 'components/FooterLinks/FooterLinks';
import useIsMobile from 'hooks/useIsMobile';
import { FC } from 'react';
import packageInfo from '../../../package.json';

interface WindowBuildNo extends Window {
  BUILD_NUMBER: string;
}

declare const window: WindowBuildNo;

type FooterLightProps = {
  sx?: SxProps;
};

const FooterLight: FC<FooterLightProps> = ({ sx }) => {
  const isMobile = useIsMobile();
  const buildNo: string = window.BUILD_NUMBER;

  const footerStyle: SxProps = {
    width: '100%',
    padding: '16px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    ['p, a']: {
      color: '#666',
      textDecoration: 'none'
    }
  };

  return (
    <Box sx={{ ...footerStyle, ...sx }}>
      <Stack
        sx={{ p: '16px' }}
        direction={isMobile ? 'column' : 'row'}
        gap={isMobile ? 1.5 : 2}
        alignItems='center'
      >
        <Link href={'https://newsroom.dyplink.no/'} target='_blank' rel='norefferer'>
          <Box sx={{ height: '30px' }}>
            <img
              src='/assets/newsroom-logo-outlined.svg'
              style={{ position: 'relative', top: '2px', height: '30px', width: 'auto' }}
            />
          </Box>
        </Link>
        {!isMobile && <Divider orientation='vertical' variant='middle' />}
        <Typography>
          <small>
            <i>
              version {packageInfo.version}.{buildNo}
            </i>
          </small>
        </Typography>
        {!isMobile && <Divider orientation='vertical' variant='middle' />}
        <FooterLinks />
      </Stack>
    </Box>
  );
};

export default FooterLight;
