import { useEffect, useState } from 'react';
import theme from 'styles/theme';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth < theme.breakpoints.values.md
  );

  useEffect(() => {
    const resizeW = () => setIsMobile(window.innerWidth < theme.breakpoints.values.sm);

    window.addEventListener('resize', resizeW); // Update the width on resize

    return () => window.removeEventListener('resize', resizeW);
  });

  return isMobile;
};

export default useIsMobile;
