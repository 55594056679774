/* eslint-disable indent */
import { Alert, Box, CircularProgress, SxProps } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { PageContainer } from 'components';
import UserSubscriptionInfo from 'components/UserSubscriptionInfo/UserSubscriptionInfo';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import PaperContent from 'components/PaperContent/PaperContent';
import theme from 'styles/theme';
import CanceledUserSubscriptionInfo from 'components/CanceledUserSubscriptionInfo/CanceledUserSubscriptionInfo';
import Card from 'types/Card';
import { useEffect, useState } from 'react';
import useUser from 'hooks/useUser';

const Subscription = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { fetchAndUpdateCurrentUserSubscription } = useUser();

  const { user } = useSelector((state: RootState) => state);
  if (!user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  const styledPageContainer: SxProps = {
    background: '#efefef',
    [theme.breakpoints.up('sm')]: {
      marginTop: '32px'
    }
  };

  const getIsCardSoonToBeExpired = (card: Card) => {
    if (!card) return;

    const today = new Date();
    const cardExpirationDate = new Date(card.expiryDate);
    const diff = cardExpirationDate.getTime() - today.getTime();
    const diffInDays = diff / (1000 * 3600 * 24);

    return diffInDays < 30;
  };
  const updateUserSubscription = async () => {
    await fetchAndUpdateCurrentUserSubscription();
    setLoading(false);
  };

  const displayCardSoonToBeExpired = getIsCardSoonToBeExpired(user?.userSubscription?.card);
  useEffect(() => {
    updateUserSubscription();
  }, []);
  return (
    <PageContainer centerHorizontal sx={styledPageContainer}>
      {loading && <CircularProgress />}
      {!loading && (
        <PaperCard>
          <PaperHeader>
            <Typography variant={'h4'}>{t('subscription.mySubscription')}</Typography>
          </PaperHeader>
          <PaperContent>
            {displayCardSoonToBeExpired &&
              user?.userSubscription &&
              user?.userSubscription.status === 'ACTIVE' && (
                <Box marginBottom={'12px'}>
                  <Alert severity={'error'}>{t('userSubscription.alerts.cardSoonToExpire')}</Alert>
                </Box>
              )}
            {(user.hasActiveUserSubscription || !user.userSubscription) && (
              <UserSubscriptionInfo displaySubscriptionInfo={true} />
            )}
            {!user.hasActiveUserSubscription && <CanceledUserSubscriptionInfo />}
          </PaperContent>
        </PaperCard>
      )}
    </PageContainer>
  );
};

export default Subscription;
