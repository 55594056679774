import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { PrimaryCard } from 'components';
import routes from 'navigation/routes';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import getRoutePath from 'services/getRoutePath';
import theme from 'styles/theme';
import GiftInfo from 'types/GiftInfo';
import Subscription from 'types/Subscription';

type SelectedGiftSubscriptionProps = {
  subscription: Subscription;
  giftInfo: GiftInfo;
  orderUuid: string;
  disabled?: boolean;
};

const SelectedGiftSubscription: FC<SelectedGiftSubscriptionProps> = ({
  subscription,
  giftInfo,
  orderUuid,
  disabled = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PrimaryCard variant={'outlined'} sx={{ width: '100%', boxSizing: 'border-box' }}>
      <Stack gap={2}>
        <Stack>
          <Stack direction={'row'} justifyContent='space-between' alignItems='center'>
            <Typography>{t('giftSubscription..')}</Typography>
            <Button
              disabled={disabled}
              onClick={() => {
                navigate(getRoutePath(routes.GIFT_SUBSCRIPTION_UUID.path, 'uuid', orderUuid));
              }}
            >
              {t('common.change')}
            </Button>
          </Stack>
          <Typography className={'subscription-name'} variant={'h6'}>
            {subscription.name}
          </Typography>
          <Typography variant={'h6'}>{`${t('common.price')}: ${subscription.price} kr`}</Typography>
        </Stack>
        <Stack
          gap={theme.breakpoints.down('sm') ? 2 : 4}
          direction={'row'}
          flexWrap={'wrap'}
          sx={{ wordBreak: 'break-all' }}
        >
          <Stack>
            <Typography>{t('common.from')}</Typography>
            <Stack direction={'row'} alignItems={'center'} gap={1} flexWrap={'wrap'}>
              <Typography variant='h6'>{`${giftInfo.from}`}</Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography>{t('common.to')}</Typography>
            <Stack direction={'column'}>
              <Typography variant='h6'>{`${giftInfo.name}`}</Typography>
              <Typography variant='h6'>{`${giftInfo.phoneNumber}`}</Typography>
              <Typography variant='h6'>{`${giftInfo.email}`}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </PrimaryCard>
  );
};

export default SelectedGiftSubscription;
