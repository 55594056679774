import { Language } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { FC } from 'react';

type BrowserIconProps = {
  browser: string;
};

const BrowserIcon: FC<BrowserIconProps> = ({ browser }) => {
  const getBrowserIcon = () => {
    const parsedBrowser = browser?.toLowerCase();
    let iconName: string;

    switch (parsedBrowser) {
      case 'safari':
        iconName = 'safari.png';
        break;
      case 'chrome':
        iconName = 'chrome.png';
        break;
      case 'firefox':
        iconName = 'firefox.png';
        break;
      case 'edge':
        iconName = 'edge.png';
        break;
      case 'opera':
        iconName = 'opera.png';
        break;
      case 'samsung browser':
        iconName = 'samsung-internet.png';
        break;
      case 'facebook':
        iconName = 'facebook.png';
        break;
      default:
        break;
    }

    if (iconName) {
      return <img src={`/assets/browser/${iconName}`} style={{ width: '32px', height: '32px' }} />;
    }

    return <Language />;
  };

  const browserIcon = getBrowserIcon();

  return (
    <Stack flexDirection={'row'} alignItems={'center'}>
      {browserIcon}
    </Stack>
  );
};

export default BrowserIcon;
