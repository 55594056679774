/* eslint-disable indent */
import { Alert, Button, CircularProgress, Stack, SxProps } from '@mui/material';
import { AxiosError } from 'axios';
import { PageContainer } from 'components';
import EmailAdressForm from 'components/EmailAddressForm/EmailAddressForm';
import FlexBox from 'components/FlexBox/FlexBox';
import LogoPaperHeader from 'components/LogoPaperHeader/LogoPaperHeader';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperFooter from 'components/PaperFooter/PaperFooter';

import useAxiosAlert from 'hooks/useAxiosAlert';
import useUser from 'hooks/useUser';
import useVipps from 'hooks/useVipps';
import logger from 'logger/logger';
import routes from 'navigation/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import orderService from 'services/order.service';
import swedbankService from 'services/swedbank.service';

import { RootState } from 'store';
import Order from 'types/Order';
import theme from 'styles/theme';
import gaService from 'services/ga.service';
import analyticsService from 'services/analytics.service';
import OrderCompletionAddressForm from './components/OrderCompletionAddressForm';
import OrderCompletedInfo from './components/OrderCompletedInfo';
import LoginInfo from './components/LoginInfo';

const OrderCompletion: FC = () => {
  const { uuid } = useParams();
  const { validateVippsAgreement } = useVipps();

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();
  const [orderCompleted, setOrderCompleted] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const user = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const { fetchAndUpdateCurrentUserSubscription } = useUser();
  const axiosAlert = useAxiosAlert();
  const [errorText, setErrorText] = useState<string>();
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('returnUrl');

  useEffect(() => {
    const init = async () => {
      setLoading(true);

      if (!uuid) {
        setErrorText(t('orderCompletion.alerts.couldNotFindOrder'));
        setLoading(false);
        return;
      }

      try {
        const {
          data: { order }
        } = await orderService.getByUuid(uuid);
        setOrder(order);

        if (order.status === 'COMPLETED') {
          if (user) {
            await fetchAndUpdateCurrentUserSubscription();
          }

          setOrderCompleted(true);
          setLoading(false);
          return;
        }

        if (order.status === 'CANCELED') {
          setErrorText(t('orderCompletion.alerts.canceled'));
          return;
        }

        if (order.paymentSolution === 'vipps') {
          // Handle vipps order
          await completeVippsOrder(order);

          // Send event to GA
          gaService.gtagEvent('nrs_purchase_complete', {
            nrs_purchase_return_url: returnUrl,
            nrs_purchase_completed: 1
          });

          analyticsService.event('purchase-completed', { s1: returnUrl, n1: order.price });
        } else if (order.paymentSolution === 'swedbank') {
          // Handle swedbank order
          const validated = await validateSwedbankOrder(order.uuid);

          if (validated) {
            await completeSwedbankOrder(order);
            gaService.gtagEvent('nrs_purchase_complete', {
              nrs_purchase_return_url: returnUrl,
              nrs_purchase_completed: 1
            });
            analyticsService.event('purchase-completed', { s1: returnUrl, n1: order.price });
          } else {
            setErrorText(t('orderCompletion.alerts.swedbankSomethingWentWrong'));
          }
        } else {
          setErrorText(t('orderCompletion.alerts.somethingWentWrong'));
        }
      } catch (error) {
        logger.info(
          `User could not get order in orderCompletion userPhone ${user.phone} orderUuid ${uuid}`,
          error
        );

        axiosAlert(
          error as AxiosError,
          [
            { status: 401, cb: () => setErrorText(t('orderCompletion.alerts.noAccess')) },
            { status: 404, cb: () => setErrorText(t('orderCompletion.alerts.couldNotFindOrder')) }
          ],
          { cb: () => setErrorText(t('alerts.somethingWentWrong')) }
        );
      } finally {
        setLoading(false);
      }
    };

    init();
  }, []);

  async function completeVippsOrder(order: Order) {
    try {
      const agreement = await validateVippsAgreement(order.uuid);

      if (agreement.status === 'STOPPED' || agreement.status === 'EXPIRED') {
        setErrorText(t('orderCompletion.alerts.vippsStopped'));
        return;
      }

      if (agreement.status === 'PENDING') {
        setErrorText(t('orderCompletion.alerts.vippsPending'));
        return;
      }

      const completed = await completeOrder(order, order.recurringToken);

      if (!completed) {
        return;
      }

      setOrderCompleted(true);

      if (user) {
        await fetchAndUpdateCurrentUserSubscription();
      }
    } catch (error) {
      logger.error('Complete vipps order failed', error);
      axiosAlert(
        error as AxiosError,
        [
          {
            status: 404,
            cb: () => setErrorText(t('orderCompletion.alerts.couldNotFindOrder'))
          }
        ],
        { cb: () => setErrorText(t('alerts.somethingWentWrong')) }
      );
    } finally {
      setLoading(false);
    }
  }

  const validateSwedbankOrder = async (orderUuid: string) => {
    try {
      const { data: swedbankOrder } = await swedbankService.getOrderByUuid(orderUuid);

      if (swedbankOrder.status === 'Cancelled' || swedbankOrder.status === 'Aborted') {
        setErrorText(t('orderCompletion.alerts.canceled'));
        return false;
      }

      if (swedbankOrder.status === 'Failed') {
        setErrorText(t('orderCompletion.alerts.couldNotCompleteOrder'));
        return false;
      }

      return true;
    } catch (error) {
      logger.error(`Could not get swedbank order on orderUuid ${orderUuid}`);
    }
  };

  const completeSwedbankOrder = async (order: Order) => {
    try {
      let completed;
      if (order.type !== 'GIFT_SUBSCRIPTION') {
        // Get recurringToken
        const { data: recurringToken } = await swedbankService.getRecurringTokenByUuid(order.uuid);

        await orderService.updateByUuid(order.uuid, { recurringToken });
        completed = await completeOrder(order, recurringToken);
      } else {
        completed = await completeOrder(order);
      }

      if (!completed) {
        return;
      }

      setOrderCompleted(true);

      if (user) {
        await fetchAndUpdateCurrentUserSubscription();
      }
    } catch (error) {
      logger.error('Complete swedbank order failed', error);

      axiosAlert(
        error as AxiosError,
        [
          {
            status: 404,
            cb: () => setErrorText(t('orderCompletion.alerts.couldNotFindOrder'))
          }
        ],
        { cb: () => setErrorText(t('alerts.somethingWentWrong')) }
      );
    } finally {
      setLoading(false);
    }
  };

  const completeOrder = async (order: Order, recurringToken?: string) => {
    try {
      // CompleteOrder
      if (order.type === 'SUBSCRIPTION' || order.type === 'GIFT_SUBSCRIPTION') {
        await orderService.completeSubscriptionOrder(order.id, recurringToken);
      } else {
        await orderService.completeChangePaymentMethodOrder(order.id, recurringToken);
      }

      return true;
    } catch (error) {
      axiosAlert(
        error as AxiosError,
        [
          {
            status: 410,
            cb: () => setErrorText(t('orderCompletion.alerts.canceled'))
          }
        ],
        {
          cb: () => setErrorText(t('alerts.somethingWentWrong'))
        }
      );

      return false;
    }
  };

  const handleNewspaperNavigation = () =>
    (window.location.href =
      routes.AUTHENTICATE.path + '?returnUrl=' + encodeURIComponent(returnUrl));

  const styledPageContainer: SxProps = {
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
      padding: 0
    }
  };

  return (
    <PageContainer centerHorizontal centerVertical footerLight pageCard sx={styledPageContainer}>
      <PaperCard pageCard>
        <LogoPaperHeader />
        <PaperContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {loading && (
            <Stack direction={'row'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>
          )}
          {orderCompleted && (
            <Stack gap={2}>
              <OrderCompletedInfo orderType={order?.type} loading={loading} />
              {user && order.type !== 'GIFT_SUBSCRIPTION' && <OrderCompletionAddressForm />}
              {user && order.type !== 'GIFT_SUBSCRIPTION' && <EmailAdressForm />}
            </Stack>
          )}
          {!loading && errorText && (
            <Alert severity={'error'} sx={{ marginBottom: '16px' }}>
              {errorText}
            </Alert>
          )}
          {!user && !loading && <LoginInfo />}
        </PaperContent>
        {!loading && (
          <PaperFooter>
            <FlexBox justifyContent='flex-end' gap={8} sx={{ flexWrap: 'wrap' }}>
              {user && order?.type === 'SUBSCRIPTION' && returnUrl && (
                <Button
                  fullWidth
                  variant={user?.email ? 'contained' : 'outlined'}
                  onClick={handleNewspaperNavigation}
                >
                  {t('common.backToNewsSite')}
                </Button>
              )}
              {user && (
                <Button
                  fullWidth
                  variant={user?.email ? 'contained' : 'outlined'}
                  onClick={() => navigate(routes.PROFILE.path)}
                >
                  {returnUrl
                    ? t('common.goToBlank', {
                        blank: t('common.myPage').toLowerCase()
                      })
                    : t('common.backToBlank', {
                        blank: t('common.myPage').toLowerCase()
                      })}
                </Button>
              )}
            </FlexBox>
          </PaperFooter>
        )}
      </PaperCard>
    </PageContainer>
  );
};

export default OrderCompletion;
