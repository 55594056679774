import { Button, Typography, useTheme } from '@mui/material';
import PrimaryCard from 'components/PrimaryCard/PrimaryCard';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Subscription from 'types/Subscription';
import Campaign from 'types/Campaign';
import getReadbleDuration from 'utils/_getReadbleDuration';

type SubscriptionCardProps = {
  subscription: Subscription;
  actions?: ReactNode;
  onSubscriptionSelect?: (subscription: Subscription, campaign: Campaign) => void;
};

const SubscriptionCard: FC<SubscriptionCardProps> = ({ subscription, onSubscriptionSelect }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isCampaign = subscription.campaigns.length > 0;
  const samePrice = isCampaign
    ? subscription.campaigns.filter((c) => c.price !== subscription.price).length === 0
    : true;
  const primaryColor = theme.palette.primary.main;
  // Show bulletpoints
  const description = subscription.description.replace(/\n\*/g, '\n•');
  return (
    <PrimaryCard
      variant='elevation'
      elevation={3}
      sx={{
        width: isCampaign ? '290px' : '280px',
        maxWidth: 'calc(100vw - 50px)',
        backgroundColor: isCampaign ? primaryColor : undefined,
        color: isCampaign ? '#fff' : undefined,
        border: `1px solid ${theme.palette.primary.main}`
      }}
    >
      {isCampaign && !samePrice && (
        <Typography variant={'h6'} sx={{ fontWeight: 700 }}>
          {t('common.campaign')}
        </Typography>
      )}

      <Typography variant={'h5'}>{subscription.name}</Typography>
      <div style={{ minHeight: '3em' }}>
        <Typography sx={{ whiteSpace: 'pre-line' }}>{description}</Typography>
      </div>

      {isCampaign ? (
        subscription.campaigns.map((campaign) => (
          <div key={campaign.id}>
            <Typography>{campaign.description}</Typography>
            <Button
              fullWidth
              size='large'
              sx={{
                marginTop: '5px',
                textTransform: 'none',
                fontSize: '24px',
                backgroundColor: '#fff',
                '&:hover': {
                  backgroundColor: '#fff',
                  opacity: '0.95'
                }
              }}
              variant='outlined'
              onClick={() => {
                onSubscriptionSelect(subscription, campaign);
              }}
            >
              {campaign.name}
            </Button>
            {!samePrice && (
              <div
                style={{
                  fontSize: '0.9em',
                  padding: '5px',
                  fontStyle: 'italic',
                  textAlign: 'right'
                }}
              >
                {t('common._then')} {subscription.price} kr /{' '}
                {getReadbleDuration(subscription.duration)}
              </div>
            )}
          </div>
        ))
      ) : (
        <Button
          fullWidth
          sx={{
            marginTop: '40px',
            fontSize: '20px',
            textTransform: 'none'
          }}
          variant='contained'
          onClick={() => {
            onSubscriptionSelect(subscription, null);
          }}
        >
          {t('common.chooseFor')} {subscription.price} kr /{' '}
          {getReadbleDuration(subscription.duration)}
        </Button>
      )}
    </PrimaryCard>
  );
};

export default SubscriptionCard;
