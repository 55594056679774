import { Button, CircularProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { AxiosResponse } from 'axios';
import { ConsentDialog } from 'components';
import useAlert from 'hooks/useAlert';
import routes from 'navigation/routes';
import SelectedSubscriptionPayment from 'pages/OrderPaymentPage/components/SelectedSubscriptionPayment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import campaignService from 'services/campaign.service';
import orderService from 'services/order.service';
import subscriptionService from 'services/subscription.service';
import vippsService from 'services/vipps.service';
import { VIPPS_ORANGE } from 'styles/colors';
import theme from 'styles/theme';
import Campaign from 'types/Campaign';
import Order from 'types/Order';
import Subscription from 'types/Subscription';

type UnhandledOrderProps = {
  order: Order;
  returnUrl?: string;
};

const UnhandledOrder: FC<UnhandledOrderProps> = ({ order, returnUrl }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addAlert } = useAlert();

  const [subscription, setSubscription] = useState<Subscription>();
  const [campaign, setCampaign] = useState<Campaign>();
  const [campaignSubscriptionLoading, setCampaignSubscriptionLoading] = useState<boolean>();
  const [syncingOrder, setSyncingOrder] = useState<boolean>();
  const [modal, setModal] = useState<number>();
  const [cancelOrderLoading, setCancelOrderLoading] = useState<boolean>();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setCampaignSubscriptionLoading(true);

    try {
      const psAll: Promise<AxiosResponse<Subscription | Campaign>>[] = [];

      if (order.subscriptionId) {
        psAll.push(subscriptionService.getSubscription(order.subscriptionId));
      }

      if (order.campaignId) {
        psAll.push(campaignService.getByOrderId(order.id));
      }

      const results = await Promise.all(psAll);

      if (order.subscriptionId) {
        setSubscription(results[0]?.data as Subscription);
      }

      if (order.campaignId) {
        setCampaign(results[order.subscriptionId ? 1 : 0]?.data as Campaign);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCampaignSubscriptionLoading(false);
    }
  };

  const handleOrderCancelation = async () => {
    setModal(undefined);
    setCancelOrderLoading(true);
    try {
      await orderService.updateByUuid(order.uuid, {
        status: 'CANCELED'
      });

      sessionStorage.setItem(
        'temporaryAlert',
        JSON.stringify({
          type: 'success',
          text: t('payment.alerts.yourOrderHasBeenCanceled')
        })
      );

      // Refresh the component or page
      window.location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      setCancelOrderLoading(false);
    }
  };

  const handleOrderSync = async () => {
    setSyncingOrder(true);

    try {
      const { data: agreement } = await vippsService.getAgreementByOrderUuid(order.uuid);

      if (agreement && agreement.status === 'ACTIVE') {
        navigate(routes.ORDER_CONFIRMATION.path.replace(':uuid', order.uuid.toString()), {
          state: {
            returnUrl
          }
        });
      }

      if (agreement && (agreement.status === 'EXPIRED' || agreement.status === 'STOPPED')) {
        // navigate to order
        await orderService.updateByUuid(order.uuid, {
          status: 'CANCELED'
        });

        sessionStorage.setItem(
          'temporaryAlert',
          JSON.stringify({
            type: 'success',
            text: t('payment.alerts.yourOrderHasBeenCanceled')
          })
        );

        // Refresh the component or page
        window.location.reload();
      }

      if (agreement && agreement.status === 'PENDING') {
        addAlert('info', t('alerts.orderPendingVipps'));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSyncingOrder(false);
    }
  };

  return (
    <Stack
      gap={2}
      sx={{
        maxWidth: '500px'
      }}
    >
      <Typography textAlign={'left'} variant='h4'>
        {t('alerts.userHaveExistingVippsOrderTitle')}
      </Typography>
      {!campaignSubscriptionLoading && subscription && (
        <SelectedSubscriptionPayment subscription={subscription} campaign={campaign} hideEdit />
      )}
      {campaignSubscriptionLoading && <Typography>{t('common.cancelOrderLoading')}</Typography>}
      <Typography>{t('alerts.userHaveExsitingVippsOrderDescription')}</Typography>
      <Typography>{t('alerts.orderAlreadyPaidDescription')}</Typography>
      <Stack direction={'row'} sx={{ mt: 2 }} gap={2} justifyContent={'center'}>
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            // on mobile flexDiection should be column
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column'
            }
          }}
          gap={1.5}
        >
          <Button
            disabled={syncingOrder || cancelOrderLoading}
            variant={'outlined'}
            sx={{
              [theme.breakpoints.down('sm')]: {
                width: '100%'
              }
            }}
            onClick={() => {
              setModal(1);
            }}
          >
            {t('common.cancelOrder')}
          </Button>
          <Button
            endIcon={syncingOrder ? <CircularProgress size={20} /> : null}
            variant={'contained'}
            onClick={handleOrderSync}
            disabled={syncingOrder || cancelOrderLoading}
          >
            {t('common.updateOrder')}
          </Button>
          <Button
            endIcon={cancelOrderLoading ? <CircularProgress size={20} /> : null}
            disabled={syncingOrder || cancelOrderLoading}
            sx={{
              backgroundColor: VIPPS_ORANGE
            }}
            variant={'contained'}
            onClick={() =>
              navigate(routes.ORDER_SUBSCRIPTION_PAYMENT.path.replace(':uuid', order.orderId), {
                state: {
                  returnUrl
                }
              })
            }
          >
            {t('common.backToOrder')}
          </Button>
        </Stack>
      </Stack>
      {modal === 1 && (
        <ConsentDialog
          title={t('payment.cancelOrderTitle')}
          content={t('payment.cancelOrderDescription')}
          confirmText={t('common.cancelOrder')}
          onCancel={() => setModal(undefined)}
          onConfirm={handleOrderCancelation}
        />
      )}
    </Stack>
  );
};

export default UnhandledOrder;
