import { Button, Stack, Typography } from '@mui/material';
import { PageContainer } from 'components';
import LogoPaperHeader from 'components/LogoPaperHeader/LogoPaperHeader';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperFooter from 'components/PaperFooter/PaperFooter';
import routes from 'navigation/routes';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'store';

const ErrorPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);

  return (
    <PageContainer centerHorizontal centerVertical pageCard footerLight>
      <PaperCard pageCard>
        <LogoPaperHeader />
        <PaperContent sx={{ flex: 1 }}>
          <Stack alignItems='center'>
            <Typography textAlign={'center'} variant='h4'>
              404. {t('navigation.404')}
            </Typography>
          </Stack>
        </PaperContent>
        <PaperFooter>
          {user && (
            <Button onClick={() => navigate(routes.PROFILE.path)}>
              {t('navigation.backToMyPage')}
            </Button>
          )}
          {!user && (
            <Button onClick={() => navigate(routes.LOGIN.path)}>
              {t('navigation.login')}
            </Button>
          )}
        </PaperFooter>
      </PaperCard>
    </PageContainer>
  );
};

export default ErrorPage;
