/* eslint-disable indent */
import { Alert, Box, Button, Divider, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { renewByMonths } from 'utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useNavigate } from 'react-router-dom';
import routes from 'navigation/routes';
import ConsentDialog from 'components/ConsentDialog/ConsentDialog';
import userSubscriptionService from 'services/user-subscription.service';
import useUser from 'hooks/useUser';
import useAlert from 'hooks/useAlert';
import logger from 'logger/logger';
import FlexBox from 'components/FlexBox/FlexBox';
import getDateString from 'utils/_getDateString';
import orderService from 'services/order.service';
import PaymentSolutionLogo from 'components/PaymentSolutionLogo/PaymentSolutionLogo';
import CardInfo from 'components/CardInfo/CardInfo';

type UserSubscriptionInfoProps = {
  displaySubscriptionInfo?: boolean;
};

const UserSubscriptionInfo: FC<UserSubscriptionInfoProps> = ({
  displaySubscriptionInfo = false
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const newsroomApp = useSelector((state: RootState) => state.newsroomApp);

  const { addAlert } = useAlert();

  const { fetchAndUpdateCurrentUserSubscription, fetchUser } = useUser();
  const [modal, setModal] = useState<number>();

  const { userSubscription = {} } = user;

  const renewDate = new Date(userSubscription?.renewDate);
  const renewDateObject = moment(renewDate);
  const renewDateString = renewDateObject
    .toDate()
    .toLocaleDateString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' });

  const cancelSubscription = async () => {
    try {
      await userSubscriptionService.cancelSubscription();
      await fetchAndUpdateCurrentUserSubscription();
      await fetchUser();
    } catch (error) {
      logger.error('Cancel Subscription failed', error);
      addAlert('error', t('alerts.somethingWentWrong'));
    } finally {
      setModal(null);
    }
  };

  const reactivateSubscription = async () => {
    try {
      await userSubscriptionService.activateSubscription();
      await fetchAndUpdateCurrentUserSubscription();
    } catch (error) {
      logger.error('Reactivate Subscription failed', error);
      addAlert('error', t('alerts.somethingWentWrong'));
    } finally {
      setModal(null);
    }
  };

  const changePaymentSoloutionFromNoneHandler = async () => {
    if (!userSubscription) {
      return;
    }

    try {
      const { data: order } = await orderService.add({
        type: 'CHANGE_PAYMENT_SOLUTION',
        userSubscriptionId: userSubscription.id,
        subscriptionId: userSubscription.subscriptionId
      });

      navigate(routes.CHANGE_PAYMENT_METHOD.path.replace(':uuid', order.uuid));
    } catch (error) {
      logger.error(
        'Could not create order for changing paymentSolution for existing user subscription ' +
          error
      );
    }
  };

  const changePaymentMethodHandler = async () => {
    if (!userSubscription) {
      return;
    }

    try {
      const { data: order } = await orderService.add({
        type: 'CHANGE_PAYMENT_SOLUTION',
        userSubscriptionId: userSubscription.id,
        subscriptionId: userSubscription.subscriptionId
      });

      navigate(routes.CHANGE_PAYMENT_METHOD.path.replace(':uuid', order.uuid));
    } catch (error) {
      logger.error(
        'Could not create order for changing paymentSolution for existing user subscription ' +
          error
      );
    }
  };

  const nextRenewDate = renewByMonths(renewDate, userSubscription.subscription?.duration);

  const getStatusInfo = () => {
    if (userSubscription.status === 'AWAITING_PAYMENT') {
      return (
        <Alert severity='info'>
          {t('userSubscription.alerts.awaitingPayment')}
          <strong>{` ${getDateString(new Date(userSubscription.renewDate))} ${t(
            'common.to'
          ).toLocaleLowerCase()} ${getDateString(
            renewByMonths(renewDate, userSubscription?.subscription?.duration)
          )}`}</strong>
          <br />
          {t('userSubscription.alerts.awaitingPaymentChangePaymentSolution')}
          {user.userSubscription.paymentSolution === 'invoice' && (
            <>
              <br />
              <br />
              <strong>{t('userSubscription.alerts.awaitingPaymentInvoiceTitle')}</strong>
              <br />
              {t('userSubscription.alerts.awaitingPaymentInvoiceDescription')}
            </>
          )}
        </Alert>
      );
    }

    if (
      userSubscription.status === 'TO_BE_CANCELED' &&
      userSubscription.paymentSolution !== 'gift'
    ) {
      return (
        <Alert severity='info'>
          {t('userSubscription.alerts.toBeCanceled', {
            date: getDateString(new Date(userSubscription.renewDate))
          })}
        </Alert>
      );
    }

    if (userSubscription.status === 'PAYMENT_FAILED') {
      return <Alert severity='error'>{t('userSubscription.alerts.paymentFailed')}</Alert>;
    }
  };

  const userSubscriptionInfo = getStatusInfo();

  const getDisplayPaymentInformation = () => {
    if (userSubscription.status === 'ACTIVE' || userSubscription.status === 'PAYMENT_FAILED') {
      return true;
    } else {
      return;
    }
  };

  const displayPaymentInformation = getDisplayPaymentInformation();

  return user.userSubscription && user.hasActiveUserSubscription ? (
    <div className='active-subscription-info'>
      <div>
        <Typography variant={'h5'}>{user.userSubscription?.subscription?.name}</Typography>
        <Divider sx={{ margin: '8px 0', width: '100%' }} />
        {displaySubscriptionInfo && (
          <FlexBox sx={{ marginBottom: '12px' }} gap={4} direction='column'>
            <FlexBox gap={8} justifyContent={'flex-start'}>
              <Typography variant={'h6'}>{t('common.price')}</Typography>
              <Typography
                variant={'h6'}
              >{`${user.userSubscription?.subscription?.price} kr`}</Typography>
            </FlexBox>
            <Typography variant={'h6'}>{`${t('common.duration')} ${
              user.userSubscription?.subscription?.duration
            } mnd`}</Typography>
            {displayPaymentInformation && (
              <>
                {user.userSubscription.paymentSolution && (
                  <FlexBox gap={8} justifyContent={'flex-start'} alignItems={'center'}>
                    <Typography variant='h6'>{t('common.paymentSolution')}</Typography>
                    <PaymentSolutionLogo paymentSolution={user.userSubscription.paymentSolution} />
                  </FlexBox>
                )}
                {user?.userSubscription?.card && (
                  <CardInfo variant='h6' card={user?.userSubscription?.card} />
                )}
              </>
            )}
          </FlexBox>
        )}
        {userSubscriptionInfo && <Box sx={{ margin: '16px 0' }}>{userSubscriptionInfo}</Box>}
        {userSubscription.paymentSolution === 'external' &&
          userSubscription.status === 'ACTIVE' && (
            <Alert severity='info'>{t('userSubscription.alerts.externalPaymentSolution')}</Alert>
          )}
        {userSubscription.paymentSolution === 'gift' && (
          <Alert sx={{ marginBottom: '16px' }} severity='info'>
            {t('userSubscription.alerts.paymentSolutionGift', {
              date: getDateString(new Date(userSubscription.renewDate))
            })}
          </Alert>
        )}
        {userSubscription.paymentSolution === 'migration' &&
          userSubscription.status === 'ACTIVE' && (
            <Alert sx={{ marginBottom: '16px' }} severity='info'>
              {t('userSubscription.alerts.paymentSolutionMigration')}
            </Alert>
          )}
        {user.userSubscription.status === 'ACTIVE' && (
          <Typography variant={'h6'}>{`${t('common.renews')} ${renewDateString}`}</Typography>
        )}
        {user.userSubscription.status === 'TO_BE_CANCELED' && (
          <Typography variant={'h6'}>{`${t(
            'subscription.expireIn'
          )} ${renewDateString}`}</Typography>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          marginTop: '20px'
        }}
      >
        {!displaySubscriptionInfo && (
          <Button
            onClick={() => navigate(routes.SUBSCRIPTION.path)}
            size='small'
            color='secondary'
            variant={'outlined'}
          >
            {t('subscription.mySubscription')}
          </Button>
        )}
        {displaySubscriptionInfo &&
          !newsroomApp &&
          ['none', 'gift'].indexOf(user.userSubscription.paymentSolution) !== -1 && (
            <Button
              onClick={changePaymentSoloutionFromNoneHandler}
              size='small'
              color='secondary'
              variant={'outlined'}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {t('subscription.changePaymentSolution')}
            </Button>
          )}
        {displaySubscriptionInfo &&
          ['invoice', 'vipps', 'swedbank', 'migration', 'external'].indexOf(
            user.userSubscription.paymentSolution
          ) !== -1 && (
            <div>
              <Stack direction={'row'} flexWrap={'wrap'} gap={1} sx={{ marginBottom: '10px' }}>
                {user.userSubscription.status === 'ACTIVE' && (
                  <>
                    {!newsroomApp && (
                      <Button
                        onClick={() => setModal(2)}
                        size='small'
                        color='secondary'
                        variant={'outlined'}
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        {t('subscription.changePaymentSolution')}
                      </Button>
                    )}
                    {user.userSubscription.paymentSolution === 'swedbank' && !newsroomApp && (
                      <Button
                        onClick={() => setModal(3)}
                        size='small'
                        color='secondary'
                        variant={'outlined'}
                      >
                        {t('payment.changeCard')}
                      </Button>
                    )}
                  </>
                )}
                {(user.userSubscription.status === 'ACTIVE' ||
                  user.userSubscription.status === 'AWAITING_PAYMENT') && (
                  <Button
                    onClick={() => setModal(0)}
                    size='small'
                    color='error'
                    variant={'outlined'}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {t('subscription.cancel')}
                  </Button>
                )}
              </Stack>
              {user.userSubscription.status === 'TO_BE_CANCELED' && (
                <Button
                  onClick={() => setModal(1)}
                  size='small'
                  color='secondary'
                  variant={'outlined'}
                >
                  {t('subscription.reactivate')}
                </Button>
              )}
            </div>
          )}
      </div>
      {modal === 0 && (
        <ConsentDialog
          onCancel={() => setModal(null)}
          onConfirm={cancelSubscription}
          title={t('userSubscription.cancelTitle')}
          content={
            userSubscription.status === 'ACTIVE'
              ? t('userSubscription.cancelDescription', {
                  renewDate: renewDateString
                })
              : t('userSubscription.cancelDescriptionAwaitingPayment', {
                  from: getDateString(userSubscription.renewDate),
                  to: getDateString(nextRenewDate)
                })
          }
          confirmText={t('userSubscription.confirmCancellation')}
        />
      )}
      {modal === 1 && (
        <ConsentDialog
          onCancel={() => setModal(null)}
          onConfirm={reactivateSubscription}
          title={t('userSubscription.reactivateTitle')}
          content={t('userSubscription.reactivateDescription', {
            renewDate: renewDateString
          })}
        />
      )}
      {modal === 2 && (
        <ConsentDialog
          onCancel={() => setModal(null)}
          onConfirm={changePaymentMethodHandler}
          confirmText={t('payment.changePaymentSolution')}
          title={t('userSubscription.changePaymenSolutionTitle')}
          content={t('userSubscription.changePaymenSolutionContent', {
            renewDate: renewDateString
          })}
        />
      )}
      {modal === 3 && (
        <ConsentDialog
          onCancel={() => setModal(null)}
          onConfirm={changePaymentMethodHandler}
          confirmText={t('payment.changeCard')}
          title={t('userSubscription.changeCardTitle')}
          content={t('userSubscription.changeCardContent', {
            renewDate: renewDateString
          })}
        />
      )}
    </div>
  ) : (
    <>
      <Typography sx={{ marginBottom: '16px' }}>{t('alerts.noActiveSubscription')}</Typography>
      <Button
        size='small'
        color='secondary'
        variant={'outlined'}
        onClick={() => navigate(routes.ORDER_SUBSCRIPTION_SELECT.path)}
      >
        {t('subscription.becomeASubscriber')}
      </Button>
    </>
  );
};

export default UserSubscriptionInfo;
