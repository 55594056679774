import Skin from 'types/Skin';

const sulaposten: Skin = {
  COLORS: {
    PRIMARY: '#007a8e',
    PRIMARY_DARK: '#246168',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#007a8e',
    BACKGROUND_LIGHT: '#eee'
  },
  LOGO: {
    ICON: 'https://dyplink.no/wp-content/uploads/2024/07/sulaposten-icon-1.svg',
    MAIN: 'https://dyplink.no/wp-content/uploads/2024/07/sulaposten-logo-white.svg'
  }
};

export default sulaposten;