import SwedbankOrder from 'types/Swedbank/SwedbankOrder';
import { config } from 'utils';

const defaultOrder: SwedbankOrder = {
  operation: 'Purchase',
  currency: 'NOK',
  language: 'nb-NO',
  userAgent: 'Mozilla/5.0',
  productName: 'Checkout3',
  generateRecurrenceToken: true,
  intent: 'AutoCapture',
  payer: {
    digitalProducts: true,
    requireConsumerInfo: true
  },
  urls: {
    hostUrls: [window.location.origin],
    returnUrl: `${config.API_URL}/payment-solution/swedbank/order/callback`
  },
  orderItems: [
    {
      quantity: 1,
      quantityUnit: 'pcs',
      type: 'SERVICE',
      class: 'Subscription'
    }
  ]
};

export default defaultOrder;
