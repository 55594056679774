import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as string;

export const newsroomAppReducer = createSlice({
  name: 'newsroomAppReducer',
  initialState,
  reducers: {
    setNewsroomApp: (state, action: PayloadAction<string>) => {
      return action.payload;
    }
  }
});

export const { actions } = newsroomAppReducer;

export default newsroomAppReducer;
