import Skin from 'types/Skin';

const storfjordnytt: Skin = {
  COLORS: {
    PRIMARY: '#343e86',
    PRIMARY_DARK: '#131c5b',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#343e86',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://www.storfjordnytt.no/wp-content/uploads/2020/03/favicon-300x300.png',
    MAIN: 'https://www.storfjordnytt.no/wp-content/uploads/2020/03/cropped-logotopp2018.png',
  },
};

export default storfjordnytt;