import { FC, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { validate } from 'utils';
import FormDialog from 'components/FormDialog/FormDialog';
import userTokenService from 'services/userToken.service';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';
import { Stack } from '@mui/system';

type LogOutAllDevicesDialogProps = {
  onConfirm?: () => void;
  onCancel?: () => void;
};

type Fields = {
  password: string;
};

const LogOutAllDevicesDialog: FC<LogOutAllDevicesDialogProps> = ({ onConfirm, onCancel }) => {
  const [loading, setLoading] = useState<boolean>();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Fields>({
    defaultValues: { password: '' }
  });

  const { t } = useTranslation();
  const axiosAlert = useAxiosAlert();

  const onSubmit = async (fields: Fields) => {
    setLoading(true);

    try {
      await userTokenService.logoutAll(fields.password);
      onConfirm();
    } catch (error) {
      axiosAlert(error as AxiosError, [
        {
          status: 401,
          message: t('password.notValid')
        }
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onCancel={onCancel}
      loading={loading}
      title={t('userTokens.logoutAllDevices')}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack gap={2}>
        <Typography>{t('userTokens.logoutAllDevicesDescripton')}</Typography>
        <TextField
          type='password'
          label={t('common.password')}
          {...register('password', {
            required: true,
            validate: validate.password
          })}
          error={errors.password ? true : false}
        />
      </Stack>
    </FormDialog>
  );
};

export default LogOutAllDevicesDialog;
