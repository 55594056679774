import { Edit } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { AxiosError } from 'axios';
import PhoneNumberInput from 'components/PhoneNumber/PhoneNumber';
import useAxiosAlert from 'hooks/useAxiosAlert';
import logger from 'logger/logger';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import userService from 'services/user.service';

type PhoneNumberFormProps = {
  onSubmit: (isNewUser: boolean, phoneNumber: number) => void;
  onFormReset: () => void;
};

type Fields = {
  username: string;
};

const PhoneNumberForm: FC<PhoneNumberFormProps> = ({ onSubmit: onSubmitProp, onFormReset }) => {
  const { t } = useTranslation();
  const formMethods = useForm<Fields>();
  const axiosAlert = useAxiosAlert();
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const phoneNumberVerificationHandler = async (phoneNumber: number): Promise<boolean> => {
    const res = await userService.phoneNumberExist(phoneNumber);
    const { data: phoneNumberExists } = res;
    return phoneNumberExists;
  };

  const onSubmit = async (data: Fields) => {
    try {
      setLoading(true);
      const { username: phone } = data;
      const phoneNumber = parseInt(phone.split(' ').join(''), 10);
      const isNewUser = !(await phoneNumberVerificationHandler(phoneNumber));
      setFormSubmitted(true);
      onSubmitProp(isNewUser, phoneNumber);
    } catch (error) {
      logger.error('Phone number verification failed');
      axiosAlert(error as AxiosError, []);
    } finally {
      setLoading(false);
    }
  };

  const resetFormHandler = () => {
    setFormSubmitted(false);
    setFocusInput(true);
    onFormReset();
  };

  return (
    <Box sx={{ marginBottom: '12px' }}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Stack spacing={1.5}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              {!loading && (
                <PhoneNumberInput
                  focusInput={focusInput}
                  formSubmitted={formSubmitted}
                  fieldName='username'
                />
              )}
              {formSubmitted && (
                <Box sx={{ flexShrink: 0 }}>
                  <IconButton onClick={resetFormHandler}>
                    <Edit fontSize='medium' />
                  </IconButton>
                </Box>
              )}
              {loading && (
                <Stack sx={{ width: '100%' }} flexDirection={'row'} justifyContent={'center'}>
                  <CircularProgress />
                </Stack>
              )}
            </Stack>
            {!formSubmitted && (
              <Button
                sx={{ marginBottom: '10px', padding: '8px 22px' }}
                variant='contained'
                disabled={loading}
                fullWidth
                type={'submit'}
              >
                {t('common.next')}
              </Button>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default PhoneNumberForm;
