import Skin from 'types/Skin';

const defaults: Skin = {
  COLORS: {
    PRIMARY: '#C11F25',
    PRIMARY_CONTRAST: '#ffffff',
    PRIMARY_DARK: '#7A1A1A',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff'
  },
  LOGO: {
    MAIN: 'https://malviknytt.no/wp-content/uploads/2022/12/logo-malviknytt-white.svg',
    ICON: 'https://malviknytt.no/wp-content/uploads/2022/12/icon-malviknytt.svg?v1'
  }
};

export default defaults;