import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

export const redirectingReducer = createSlice({
  name: 'redirectingReducer',
  initialState,
  reducers: {
    setRedirecting: (state, action: PayloadAction<boolean>) => {
      return action.payload;
    }
  }
});

export const { actions } = redirectingReducer;

export default redirectingReducer;
