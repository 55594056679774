import { FC, useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FormDialog } from 'components';
import useUser from 'hooks/useUser';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';

type AddPostCodeDialogProps = {
  onCancel: () => void;
  onSuccess: () => void;
};

type AddPostCodeFields = {
  postCode: string;
};

const AddPostCodeDialog: FC<AddPostCodeDialogProps> = ({ onCancel, onSuccess }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AddPostCodeFields>({
    defaultValues: { postCode: '' }
  });

  const { addPostCode } = useUser();
  const [loading, setLoading] = useState(false);
  const addAxiosAlert = useAxiosAlert();

  const onSubmit = async (data: AddPostCodeFields) => {
    const { postCode } = data;
    setLoading(true);
    try {
      await addPostCode(postCode);
      onSuccess();
    } catch (e) {
      addAxiosAlert(e as AxiosError, [], { message: t('userAddress.alerts.addPostCodeError') });
    } finally {
      // Error handled in AddPostCode
      setLoading(false);
    }
  };

  return (
    <FormDialog
      onCancel={onCancel}
      onSubmit={handleSubmit(onSubmit)}
      title={t('common.addPostCode')}
      submitText={t('common.add')}
      loading={loading}
      sxContent={{ maxWidth: '350px' }}
      sxHeader={{ fontSize: '1.8rem' }}
    >
      <Stack spacing={2}>
        <TextField
          autoFocus
          style={{ width: '100%' }}
          type='postCode'
          label={t('common.postCode')}
          {...register('postCode', {
            validate: (value) => (value.length < 4 ? t('userAddress.postCodeTooShort') : true)
          })}
          error={errors.postCode ? true : false}
          helperText={errors.postCode ? errors.postCode.message : ''}
        />
      </Stack>
      <Typography sx={{ marginTop: '16px', textSize: '8px', fontStyle: 'italic' }}>
        {t('userAddress.addPostCodeDescription')}
      </Typography>
    </FormDialog>
  );
};

export default AddPostCodeDialog;
