import { Box, SxProps } from '@mui/system';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import { FC } from 'react';
import { LOGO } from 'skin';
import theme from 'styles/theme';

type LogoPaperHeaderProps = {
  sx?: SxProps;
  link?: boolean;
};

const LogoPaperHeader: FC<LogoPaperHeaderProps> = ({ sx }) => {
  const styledImgLogo: SxProps = {
    borderRadius: '20px',
    padding: '8px',
    ['img']: {
      maxHeight: `${60}px`,
      [theme.breakpoints.down('sm')]: {
        maxHeight: `${50}px`
      }
    }
  };

  return (
    <PaperHeader sx={{ background: theme.palette.primary.main, padding: '8px', ...sx }}>
      <Box sx={{ padding: '5px', ...styledImgLogo }}>
        <img src={LOGO.MAIN} />
      </Box>
    </PaperHeader>
  );
};

export default LogoPaperHeader;
