import axios, { AxiosPromise } from 'axios';
import VippsAgreement from '../components/Vipps/types/VippsAgreement';

const vippsService = {
  createAgreement: (vippsAgreement: VippsAgreement) => {
    return axios.post('payment-solution/vipps/agreement', vippsAgreement);
  },
  getAgreementByOrderUuid: (orderUuid: string): AxiosPromise<VippsAgreement> => {
    return axios.get(`payment-solution/vipps/agreement/uuid/${orderUuid}`, { timeout: 5000 });
  },
  getAgreement: (agreementId: string): AxiosPromise<VippsAgreement> => {
    return axios.get(`payment-solution/vipps/agreement/${agreementId}`, { timeout: 5000 });
  },
  getAgreementUser: (agreementId: string): AxiosPromise<VippsAgreement> => {
    return axios.get(`payment-solution/vipps/user/agreement/${agreementId}`, { timeout: 5000 });
  },
  updateAgreementRedirectUrl: (agreementId: string, redirectUrl: string) => {
    return axios.patch(`payment-solution/vipps/agreement-update-redirect/${agreementId}`, {
      redirectUrl
    });
  }
};

export default vippsService;
