import { Box, SxProps } from '@mui/system';
import { FC, ReactNode, useEffect, useState, useRef } from 'react';

type PaperFooterProps = {
  children: ReactNode;
  sx?: SxProps;
  hideFooterHeight?: boolean;
};

const PaperFooter: FC<PaperFooterProps> = ({ children, sx, hideFooterHeight }) => {
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef(null);

  useEffect(() => {
    setFooterHeight(footerRef.current.clientHeight);
  }, []); //empty dependency array so it only runs once at render

  return (
    <>
      {!hideFooterHeight && <Box sx={{ height: `${footerHeight}px` }}></Box>}
      <Box
        ref={footerRef}
        sx={{
          padding: '16px',
          borderTop: '1px solid #efefef',
          textAlign: 'center',
          position: 'absolute',
          bottom: 0,
          width: 'calc(100% - 32px)',
          ...sx
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default PaperFooter;
