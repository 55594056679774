import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { PageContainer } from 'components';
import { FC, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import settingService from 'services/setting-service';
import { useTranslation } from 'react-i18next';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import useIsMobile from 'hooks/useIsMobile';
import theme from 'styles/theme';

type Question = {
  question: string;
  answer: string;
};

const questions: Question[] = [
  {
    question: 'Jeg har glemt passord, hva gjør jeg?',
    answer:
      'Når du kommer til innloggingssiden klikker du knappen "Glemt passord", så fyller du inn e-postadresse din. Du vil nå få en e-post med en tilbakestillingslenke.'
  },
  {
    question: 'Jeg har betalt men får ikke tilgang, hva gjør jeg?',
    answer:
      'Først, prøv å logge ut og inn igjen på nettavisa. \n\n' +
      'Du kan sjekke om det er opprettet betaling og abonnement på Min side. \n' +
      ' • Har du blitt trukket penger men det ikke dukker opp abonnement – kontakt kundeservice.\n' +
      ' • Ser du abonnementet på Min side men ikke får tilgang – kontakt kundeservice.'
  },
  {
    question: 'Hvor finner jeg kvittering?',
    answer: 'Kvitteringer finner du under menypunktet "Betalinger" på Min side.'
  },

  {
    question: 'Er det mulig å betale med faktura?',
    answer:
      'Faktura er tilgjengelig for helårsabonnement, og gjelder i utgangspunktet kun for bedrifter. Unntak kan gjøres i helt spesielle tilfeller og da kontaktes kundeservice.' +
      ' Fakturagebyr kan da tilkomme kostnaden på abonnementet.'
  },
  {
    question: 'Jeg har kjøpt feil abonnement, hva gjør jeg?',
    answer: 'Kontakt kundeservice, de kan refundere kjøpet slik at du kan gjøre en ny bestilling.'
  },
  {
    question: 'Hvordan bytter/endrer jeg kort for betaling?',
    answer:
      'På Min side kan du gå på "Mitt abonnement", her har du mulighet for å klikke på "Endre kort". Bruker du vipps-betaling kan du klikke på "Endre betalingsløsning".'
  },
  {
    question: 'Har jeg angrerett på abonnement?',
    answer:
      'Angrerett er ofte spesifisert som en del av abonnementsvilkår. For gjeldende regler les disse. \n\n' +
      'Dersom ikke noe er spesifisert har du angrerett på kjøp av abonnement i henhold til angrerettlovens bestemmelser. Du har rett til å gå fra avtalen ved å sende inn en melding til kundeservice innen 14 dager etter at bestillingen er mottatt. Dette må gjøres skriftlig(f.eks. på e-post) – husk å ta med telefonnummer og navn på brukeren din. Etter angrerettsloven forsvinner angreretten på kjøp av digitale tjenester når tjenesten tas i bruk.'
  },
  {
    question: 'Hvorfor mottar jeg ingen ordrebekreftelse på e-post?',
    answer:
      'Vi sender ikke ut kvitteringer eller ordrebekreftelse per e-post. Du kan alltid finne disse på Min side under "Betalinger" '
  },
  {
    question: 'Hvordan kontakter jeg kundeservice?',
    answer: 'Se kontaktinfo til kundeservice på bunnen av denne siden.'
  },
  {
    question: 'Hvorfor må jeg logge inn på nytt så ofte?',
    answer:
      'Nettleseren din husker innlogging i 30 dager. Dersom du bytter nettleser eller tømmer cachen i nettleseren må du logge inn på nytt. \n\n' +
      'Dersom du må logge inn oftere enn 30 dager på enheten din, kan det være at du bruker incognito-modus (også kjent som privat-modus). Skru av denne om du vil at systemet skal huske innloggingen din lenger.'
  },
  {
    question: 'Jeg får en feilmelding, hva gjør jeg?',
    answer:
      'Får du en feilmelding som du ikke forventet, så kan du melde den inn til kundeservice. \n\n' +
      'De fleste feilmelding skal være godt merket, som f.eks. feil passord, eller ugyldig e-postadresse. Får du en uventet eller uforståelig feilmelding, så ønsker vi svært gjerne at du melder dette inn til kundeservice, slik at vi kan få rettet opp i det raskest mulig.'
  },
  {
    question: 'Jeg har funnet et sikkerhetshull, hvor melder jeg det inn?',
    answer:
      'Finner du sikkerhetshull så kan du melde dette til kundeservice eller direkte til support@dyplink.no.\n\n' +
      'Vi setter pris på at du IKKE bruker våre systemer med hensikt å tilegne deg informasjon som ikke gjelder din egen bruker, eller på andre måter ødelegger ytelsen eller tilgjengeligheten på systemet(DoS-angrep) '
  },
  {
    question: 'Aktive enheter',
    answer:
      'Aktive enheter er innloggede enheter som har tilgang til å lese avisen. Det er en grense på hvor mange aktive enheter du kan ha om gangen. Disse kan enkelt administreres på Min side under "Aktive enheter".'
  }
];

const FaqContent: FC = () => {
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([...questions]);
  const [query, setQuery] = useState<string>('');
  const [phone, setPhone] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const isMobile = useIsMobile();

  const { t } = useTranslation();

  const getCustomerServiceInfo = async () => {
    const [phoneSetting, emailAddressSetting] = await Promise.all([
      settingService.getPublicByKey('Phone'),
      settingService.getPublicByKey('EmailAddress')
    ]);
    setPhone(phoneSetting?.data?.value);
    setEmailAddress(emailAddressSetting?.data?.value);
  };

  useEffect(() => {
    getCustomerServiceInfo();
  }, []);

  const doFilter = (needle: string) => {
    if (needle === '') {
      setFilteredQuestions([...questions]);
      return;
    }
    const updatedQuestions = questions.filter(
      (q) =>
        q.question.toLocaleLowerCase().indexOf(needle.toLocaleLowerCase()) !== -1 ||
        q.answer.toLocaleLowerCase().indexOf(needle.toLocaleLowerCase()) !== -1
    );
    setFilteredQuestions(updatedQuestions);
  };

  return (
    <PageContainer
      centerHorizontal
      footerLight
      header
      sx={{
        minHeight: 'auto',
        [theme.breakpoints.down('sm')]: {
          minHeight: 'auto'
        }
      }}
    >
      <Typography variant={isMobile ? 'h4' : 'h2'} sx={{ textAlign: 'center' }}>
        Ofte stilte spørsmål
      </Typography>
      <Typography sx={{ fontStyle: 'italic' }}>vedrørende abonnement</Typography>
      <TextField
        sx={{ backgroundColor: '#fff', borderRadius: '50px' }}
        value={query}
        onChange={(e) => {
          doFilter(e.target.value);
          setQuery(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          )
        }}
        variant='outlined'
      />
      <Box sx={{ maxWidth: '700px', width: '100%' }}>
        {filteredQuestions.map((faq, i) => (
          <Accordion key={i}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ whiteSpace: 'pre-line' }}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {(phone !== null || emailAddress !== null) && (
        <PaperCard sx={{ maxWidth: '440px' }}>
          <PaperHeader>
            <Typography variant={'h4'}>Kontakt kundeservice</Typography>
          </PaperHeader>
          <PaperContent>
            {phone !== null && (
              <Typography sx={{ fontWeight: 'bold' }}>
                {t('common.phone')}: {phone}
              </Typography>
            )}
            {emailAddress !== null && (
              <Typography sx={{ fontWeight: 'bold' }}>
                {t('common.email')}: {emailAddress}
              </Typography>
            )}
          </PaperContent>
        </PaperCard>
      )}
    </PageContainer>
  );
};

export default FaqContent;
