import moment from 'moment';

const renewByMonths = (anchorDate: Date, numberOfMonths: number) => {
  if (typeof numberOfMonths !== 'number' || numberOfMonths < 0 || !moment.isDate(anchorDate)) {
    return null;
  }

  const anchorDateNumber = anchorDate.getDate();

  const nextRenewDate = new Date(
    anchorDate.getFullYear(),
    anchorDate.getMonth() + numberOfMonths,
    anchorDateNumber
  );

  // Check if anchor days are larger then next renewDate
  if (anchorDate.getDate() > nextRenewDate.getDate()) {
    // Will set the date to the end of the month
    // Example Feb 01 will become Jan 31
    nextRenewDate.setDate(0);
  }

  // Renewes at midnight
  nextRenewDate.setHours(0, 0, 0, 0);

  return nextRenewDate;
};

export default renewByMonths;
