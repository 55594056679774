import axios from 'axios';
import EventData from 'types/EventData';

let platformId: string = null;
let initiated = false;
const NEWSROOM_ANALYTICS_URL = 'https://nra.dyplink.no';
let failSafe = 0;

const event = async (action: string, data?: EventData) => {
  // Make sure event fires after init
  if (!initiated) {
    failSafe++;
    if (failSafe < 50) {
      // Try for 5 seconds
      setTimeout(() => {
        event(action, data);
      }, 100);
    }
    return;
  }
  
  if (platformId) {
    try {
      await axios.post(`${NEWSROOM_ANALYTICS_URL}/event/`, {
        platformId,
        label: 'nrs',
        action,
        s1: data?.s1,
        n1: data?.n1
      });
    } catch (e) {
      // Fail silently
    }
  }
};

const analyticsService = {
  init: (_platformId: string) => {
    if (_platformId) {
      platformId = _platformId;
    }
    initiated = true;
  },
  event
};

export default analyticsService;
