import Skin from 'types/Skin';

const osthavet: Skin = {
  COLORS: {
    PRIMARY: '#008cc3',
    PRIMARY_DARK: '#01426a',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#008cc3',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://osthavet.no/wp-content/uploads/2023/11/icon-osthavet.png',
    MAIN: 'https://osthavet.no/wp-content/uploads/2023/11/logo-osthavet-white.png',
  },
};

export default osthavet;