import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import { FC, ReactNode, useState } from 'react';

type AccordionInfoBoxProps = {
  children: ReactNode;
  title?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1';
  sx?: SxProps;
};

const AccordionInfoBox: FC<AccordionInfoBoxProps> = ({ children, title, variant = 'body1', sx }) => {
  const [displayContent, setDisplayContent] = useState<boolean>();

  return (
    <Box
      sx={{
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '20px',
        padding: '10px 20px',
        ...sx
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{
          cursor: 'pointer',
          alignItems: 'center'
        }}
        onClick={() => setDisplayContent(!displayContent)}
      >
        <Typography variant={variant}>{title}</Typography>
        {!displayContent && <KeyboardArrowRight />}
        {displayContent && <KeyboardArrowDown />}
      </Stack>
      {displayContent && <Box sx={{ borderTop: '20px' }}>{children}</Box>}
    </Box>
  );
};

export default AccordionInfoBox;
