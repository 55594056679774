import { Box, Chip, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import PaymentSolution from 'types/PaymentSolution';

type PaymentSolutionLogoProps = {
  paymentSolution: PaymentSolution;
  onClick?: () => void;
  height?: number;
  sx?: SxProps;
};

const PaymentSolutionLogo: FC<PaymentSolutionLogoProps> = ({
  paymentSolution,
  onClick,
  height = 30,
  sx = {}
}) => {
  const { t } = useTranslation();
  const styledImg: SxProps = {
    ['img']: {
      height: `${height}px`,
      [theme.breakpoints.down('sm')]: {
        height: `${height - 5}px`
      }
    }
  };

  const getLogo = (ps: PaymentSolution): ReactNode => {
    switch (ps) {
      case 'vipps':
        return <img src='/assets/payment/vipps-logo.png' alt='image' />;
      case 'swedbank':
        return <img src='/assets/payment/swedbank-pay-logo.svg' alt='image' />;
      case 'none':
        return (
          <Chip color={'default'} label={t('paymentSolution.none')} sx={{ opacity: '0.82' }} />
        );
      case 'migration':
        return (
          <Chip color={'default'} label={t('paymentSolution.migration')} sx={{ opacity: '0.82' }} />
        );
      case 'invoice':
      case 'external':
        return <Chip color={'info'} label={t('paymentSolution.' + ps)} sx={{ opacity: '0.82' }} />;
      default:
        return <Chip color={'error'} label={t('paymentSolution.' + ps)} sx={{ opacity: '0.82' }} />;

    }
  };

  return (
    <Box sx={{ ...sx, ...styledImg }} onClick={onClick}>
      {getLogo(paymentSolution)}
    </Box>
  );
};

export default PaymentSolutionLogo;
