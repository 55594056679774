import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Setting from 'types/Setting';

const initialState = [] as Setting[];

export const settingsReducer = createSlice({
  name: 'settingsReducer',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Setting[]>) => {
      return action.payload;
    }
  }
});

export const { actions } = settingsReducer;

export default settingsReducer;
