import logger from 'logger/logger';
import userAuthService from 'services/user-auth.service';

const useRedirect = () => {
  const handleNewspaperRedirect = async () => {
    try {
      const returnUrl = getReturnUrl();
      const res = await userAuthService.createAuthCode();
      const code = res.data;
      const includesParams = returnUrl?.includes('?');
      clearReturnUrlSession();

      const redirect = `${returnUrl}${includesParams ? '&authCode=' : '?authCode='}${code}`;

      window.location.href = redirect;
    } catch (error) {
      logger.error('Handle newspaper redirect failed', error);
    }
  };

  const handleSignOutRedirect = async () => {
    const returnUrl = getReturnUrl() || '/login';
    window.location.href = returnUrl;
  };

  const setReturnUrl = (returnUrl: string) => {
    sessionStorage.setItem('returnUrl', returnUrl);
  };

  const clearReturnUrlSession = () => {
    sessionStorage.removeItem('returnUrl');
  };

  const getReturnUrl = () => {
    return sessionStorage.getItem('returnUrl');
  };

  return {
    handleNewspaperRedirect,
    setReturnUrl,
    getReturnUrl,
    handleSignOutRedirect
  };
};

export default useRedirect;
