import { InputLabel, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type ProfileInfoItemType = {
  label: string;
  text: string;
  style?: React.CSSProperties;
  action?: ReactNode;
};

const ProfileInfoItem: FC<ProfileInfoItemType> = ({ label, text, action, style }) => {
  return (
    <div style={{ marginBottom: '16px', maxWidth: '400px', ...style }}>
      <div style={{ float: 'right' }}>{action}</div>
      <InputLabel>{label}</InputLabel>
      <Typography>{text}</Typography>
    </div>
  );
};

export default ProfileInfoItem;
