import Skin from 'types/Skin';

const oyblikk: Skin = {
  COLORS: {
    PRIMARY: '#365a9b',
    PRIMARY_DARK: '#254071',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#365a9b',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://dyplink.no/wp-content/uploads/2023/01/icon-oyblikk.png',
    MAIN: 'https://dyplink.no/wp-content/uploads/2023/01/logo-oyblikk.png',
  },
};

export default oyblikk;