import WindowConfig from 'types/WindowConfig';
import ReactGA from 'react-ga4';
import GtagData from 'types/GtagData';

declare const window: WindowConfig;

const gaService = {
  init: () => {
    if (window.CONFIG?.GA_TAG) {
      ReactGA.initialize(window.CONFIG?.GA_TAG);
    }
  },
  pageview: () => {
    if (window.CONFIG?.GA_TAG) {
      ReactGA.send('pageview');
    }
  },
  event: (category: string, action: string, label: string) => {
    if (window.CONFIG?.GA_TAG) {
      ReactGA.event({
        category: category,
        action: action,
        label: label,
        transport: 'xhr'
      });
    }
  },
  gtagEvent: (name: string, eventData: GtagData) => {
    if (window.CONFIG?.GA_TAG) {
      ReactGA.gtag('event', name, eventData);
    }
  }
};

export default gaService;
