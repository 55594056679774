import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Box, SxProps } from '@mui/system';
import LinkButton from 'components/LinkButton/LinkButton';
import routes from 'navigation/routes';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import subscriptionService from 'services/subscription.service';
import theme from 'styles/theme';
import Subscription from 'types/Subscription';
import { validate } from 'utils';

type GiftSubscriptionFormFields = {
  name: string;
  email: string;
  phoneNumber: string;
  selectedSubscriptionId?: number | string;
  message: string;
  from: string;
};

type GiftSubscriptionFormProps = {
  onSave: (fields: GiftSubscriptionFormFields) => void;
  existingFields?: GiftSubscriptionFormFields;
};

const MESSAGE_MAX_LENGTH = 500;

const GiftSubscriptionForm: FC<GiftSubscriptionFormProps> = ({ onSave, existingFields }) => {
  const { t } = useTranslation();
  const [giftSubscriptions, setGiftSubscriptions] = useState<Subscription[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch
  } = useForm<GiftSubscriptionFormFields>({
    defaultValues: {
      ...existingFields
    }
  });

  const [messageLength, setMessageLength] = useState<number>(0);
  const watchFields = watch('message');

  useEffect(() => {
    setMessageLength(getValues()?.message?.length ?? 0);
  }, [watchFields]);

  const getGiftSubscriptions = async () => {
    try {
      setLoading(true);
      const { data: _giftSubscriptions } = await subscriptionService.getGiftSubscriptions();

      setGiftSubscriptions(_giftSubscriptions);
    } catch (error) {
      console.error('error');
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const createSubscriptionOptionLabel = (subscription: Subscription) => {
    // include subscription price, duration and name
    // example: name, 1 måned for 100kr
    return `${subscription.name} - ${subscription.price}kr`;
  };

  useEffect(() => {
    getGiftSubscriptions();
  }, []);

  const styledGiftSubscriptionFormContainer: SxProps = {
    mt: 2
  };

  const styledSelect: SxProps = {
    width: '100%',
    minWidth: '350px',
    borderRadius: '50px',
    flex: '1',

    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    }
  };

  return (
    <Box sx={styledGiftSubscriptionFormContainer}>
      {!loading && (
        <form
          onSubmit={handleSubmit((fields) => {
            fields.selectedSubscriptionId = parseInt(fields.selectedSubscriptionId as string);
            onSave(fields);
          })}
        >
          <Stack direction={'column'} gap={2}>
            <Typography variant='h6'>{t('giftSubscription.formTitle')}</Typography>
            <Stack direction={'row'} flexWrap={'wrap'} sx={{ width: '100%' }} gap={1}>
              <TextField
                sx={{ flex: '1', minWidth: '250px' }}
                label={t('common.name')}
                {...register('name', {
                  required: true,
                  validate: (name) => {
                    if (!validate.length(name, 2, 50)) {
                      return t('giftSubscription.formError.name').toString();
                    }

                    return true;
                  }
                })}
                error={errors.name ? true : false}
                helperText={errors.name ? errors.name.message : ''}
              />
              <TextField
                sx={{ flex: '1', minWidth: '250px' }}
                label={t('common.email')}
                {...register('email', {
                  required: true,
                  validate: (e) => {
                    if (!validate.email(e)) {
                      return t('giftSubscription.formError.email').toString();
                    }

                    return true;
                  }
                })}
                error={errors.email ? true : false}
                helperText={errors.email ? errors.email.message : ''}
              />
              <TextField
                sx={{ flex: '1', minWidth: '250px' }}
                label={t('common.phoneNumber')}
                {...register('phoneNumber', {
                  required: true,
                  validate: (v) => {
                    if (!validate.phone(v)) {
                      return t('giftSubscription.formError.phoneNumber').toString();
                    }

                    return true;
                  }
                })}
                error={errors.phoneNumber ? true : false}
                helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
              />
            </Stack>
            <Typography variant='h6'>{t('giftSubscription.selectSubscription')}</Typography>
            <Stack direction='row' flexWrap='wrap' gap={1}>
              <Select
                {...register('selectedSubscriptionId', { required: true })}
                defaultValue={giftSubscriptions?.length > 0 && giftSubscriptions?.[0].id}
                sx={styledSelect}
                MenuProps={{
                  sx: {
                    ['.MuiPaper-root']: {
                      margin: '0'
                    }
                  }
                }}
              >
                {giftSubscriptions.map((subscription, index) => (
                  <MenuItem key={index} value={subscription.id}>
                    {createSubscriptionOptionLabel(subscription)}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Typography variant='h6'>{t('giftSubscription.greeting')}</Typography>
            <Stack gap={2}>
              <TextField
                label={t('giftSubscription.from')}
                {...register('from', {
                  required: true,
                  validate: (v) => {
                    if (!validate.length(v, 2, 50)) {
                      return t('giftSubscription.formError.name').toString();
                    }

                    return true;
                  }
                })}
                sx={{ flex: '1', minWidth: '250px' }}
              />
              <Stack>
                <TextField
                  multiline
                  rows={2}
                  label={t('giftSubscription.message')}
                  {...register('message', {
                    required: false,
                    validate: (v) => {
                      if (!validate.length(v, 0, MESSAGE_MAX_LENGTH)) {
                        return false;
                      }

                      return true;
                    }
                  })}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '25px'
                    }
                  }}
                />
                <Box
                  sx={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                >
                  <Typography
                    sx={{
                      pr: '10px',
                      fontSize: '0.8rem',
                      color: errors.message
                        ? theme.palette.error.main
                        : theme.palette.text.secondary
                    }}
                  >
                    {messageLength} / {MESSAGE_MAX_LENGTH}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={'row'} gap={1} justifyContent={'flex-end'} sx={{ mt: 4 }}>
            <LinkButton variant='outlined' to={routes.PROFILE.path}>
              {t('common.cancel')}
            </LinkButton>
            <Button variant='contained' type='submit'>
              {t('giftSubscription.order')}
            </Button>
          </Stack>
        </form>
      )}
      {loading && (
        <Stack direction={'row'} justifyContent={'center'}>
          <CircularProgress />
        </Stack>
      )}
    </Box>
  );
};

export default GiftSubscriptionForm;
