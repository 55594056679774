import i18n from 'i18n';
import moment from 'moment';

const getDateString = (date: Date) => {
  return moment(date)
    .toDate()
    .toLocaleDateString(i18n.language, { year: 'numeric', month: '2-digit', day: '2-digit' });
};

export default getDateString;
