import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import defaultAgreement from 'components/Vipps/data/defaultAgreement';
import VippsAgreement from 'components/Vipps/types/VippsAgreement';
import vippsService from 'services/vipps.service';
import useAlert from 'hooks/useAlert';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import orderService from 'services/order.service';
import { RootState } from 'store';
import Campaign from 'types/Campaign';
import Order from 'types/Order';
import Subscription from 'types/Subscription';
import User from 'types/User';
import logger from 'logger/logger';
import useSettings from 'hooks/useSettings';
import useAxiosAlert from 'hooks/useAxiosAlert';
import { AxiosError } from 'axios';

type VippsCheckoutProps = {
  user: User;
  subscription: Subscription;
  order: Order;
  campaign?: Campaign;
  returnUrl?: string;
  onFail?: () => void;
  onRedirect?: () => void;
};

const VippsCheckout: FC<VippsCheckoutProps> = ({
  user,
  subscription,
  campaign,
  order,
  returnUrl,
  onRedirect,
  onFail
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { userSubscription } = useSelector((state: RootState) => state.user);
  const { getValueByKey } = useSettings();
  const { addAlert } = useAlert();
  const axiosAlert = useAxiosAlert();

  const orderTimeoutMinutes = parseInt(getValueByKey('OrderTimeoutMinutes', '15'));

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const { data: orderData } = await orderService.getByUuid(order.uuid);
      const { order: latestOrder } = orderData;

      if (
        latestOrder.paymentSolution === 'vipps' &&
        latestOrder.status === 'PENDING' &&
        latestOrder.recurringToken
      ) {
        const vippsConfirmationUrl = latestOrder?.data?.vippsConfirmationUrl;

        if (vippsConfirmationUrl) {
          onRedirect();
          redirectToVipps(vippsConfirmationUrl);
        }
      } else {
        createSubscriptionOrder();
      }
    } catch (error) {
      console.error(error);
    }
  };

  function dateString(d: Date) {
    function pad(n: number) {
      return n < 10 ? '0' + n : n;
    }
    return (
      d.getFullYear() +
      '-' +
      pad(d.getMonth() + 1) +
      '-' +
      pad(d.getDate()) +
      'T' +
      pad(d.getHours()) +
      ':' +
      pad(d.getMinutes()) +
      ':' +
      pad(d.getSeconds()) +
      'Z'
    );
  }

  const getCampaignEndDate = (): Date => {
    if (!campaign) {
      return;
    }

    if (campaign.outTheYear) {
      const currentDate = new Date();
      const startOfNextYear = new Date(currentDate.getFullYear() + 1, 0, 1);
      startOfNextYear.setHours(0, 0, 0, 0);

      return startOfNextYear;
    } else {
      const campaignEndDate = new Date();
      campaignEndDate.setHours(0, 0, 0, 0);
      campaignEndDate.setDate(campaignEndDate.getDate() + campaign.durationDays);
      return campaignEndDate;
    }
  };

  const getCampaingCountAndUnit = (
    durationDays: number
  ): { count: number; unit: 'WEEK' | 'MONTH' | 'DAY' } => {
    if (durationDays % 7 === 0) {
      return {
        count: durationDays / 7,
        unit: 'WEEK'
      };
    } else if (durationDays % 30 === 0) {
      return {
        count: durationDays / 30,
        unit: 'MONTH'
      };
    }
    // Days cant be more then 30
    else if (durationDays > 30) {
      if (durationDays > 60) {
        return {
          count: Math.floor(durationDays / 30),
          unit: 'MONTH'
        };
      } else {
        return {
          count: Math.floor(durationDays / 7),
          unit: 'WEEK'
        };
      }
    } else {
      return {
        count: durationDays,
        unit: 'DAY'
      };
    }
  };

  function redirectToVipps(redirectUrl: string) {
    const isInFacebookApp =
      navigator.userAgent.includes('FBAN') || navigator.userAgent.includes('FBAV');
    /* showToast('Redirecting to Vipps...'); */

    // if redirectUrl contains vipps://?... or vippsmt://?... and is in facebook app add a facebook to path so it becomes vipps://facebook?...
    if (redirectUrl.includes('vipps://') || redirectUrl.includes('vippsmt://')) {
      if (isInFacebookApp) {
        redirectUrl = redirectUrl.replace('vipps://', 'vipps://facebook');
        redirectUrl = redirectUrl.replace('vippsmt://', 'vippsmt://facebook');
      }
    }

    window.location.assign(redirectUrl);
  }

  const createSubscriptionOrder = async () => {
    setLoading(true);

    try {
      const description = t('common.newspaperAccess');
      const adustedDescription = description.length > 45 ? description.slice(0, 44) : description;

      const isInFacebookApp =
        navigator.userAgent.includes('FBAN') || navigator.userAgent.includes('FBAV');

      const merchantRedirectUrl =
        defaultAgreement.merchantRedirectUrl +
        `${order.uuid}` +
        (returnUrl ? `?returnUrl=${returnUrl}` : '');

      // Vipps agreement body
      const vippsAgreement: VippsAgreement = {
        ...defaultAgreement,
        ...{
          // replace http with https
          merchantRedirectUrl: merchantRedirectUrl.replace('http://', 'https://'),
          pricing: {
            amount: subscription.price * 100,
            currency: 'NOK',
            type: 'LEGACY'
          },
          interval: {
            count: subscription.duration ?? 1,
            unit: 'MONTH'
          },
          customerPhoneNumber: user.phone.toString(),
          isApp: isInFacebookApp,
          productName: subscription.name,
          productDescription: adustedDescription,
          initialCharge: {
            orderId: order.uuid,
            transactionType: 'DIRECT_CAPTURE',
            amount: subscription.price * 100,
            description: t('common.subscription')
          }
        }
      };

      if (campaign) {
        if (campaign.outTheYear) {
          // If campaign is out the year
          vippsAgreement.campaign = {
            price: campaign.price * 100,
            eventDate: dateString(getCampaignEndDate()),
            type: 'EVENT_CAMPAIGN',
            eventText: 'Ut året'
          };
        } else {
          const { count, unit } = getCampaingCountAndUnit(campaign.durationDays);

          vippsAgreement.campaign = {
            price: campaign.price * 100,
            type: 'PERIOD_CAMPAIGN',
            period: {
              count,
              unit
            }
          };
        }

        vippsAgreement.initialCharge.amount = campaign.price * 100;
      } else if (order.type === 'CHANGE_PAYMENT_SOLUTION') {
        // Change payment solution
        vippsAgreement.campaign = {
          end: dateString(new Date(userSubscription.renewDate)),
          price: 0,
          type: 'PRICE_CAMPAIGN'
        };
        vippsAgreement.initialCharge = null;
      }

      // Create agreement
      const { data: agreementData } = await vippsService.createAgreement(vippsAgreement);

      // Updating order width payment solution and order.uuid
      await orderService.update(order.id, {
        paymentSolution: 'vipps',
        orderId: order.uuid
      });

      const currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() + orderTimeoutMinutes);

      if (agreementData.agreementId && agreementData.vippsConfirmationUrl && subscription) {
        await orderService.update(order.id, {
          recurringToken: agreementData.agreementId,
          status: 'PENDING',
          data: {
            vippsConfirmationUrl: agreementData.vippsConfirmationUrl
          }
        });

        onRedirect();
        redirectToVipps(agreementData.vippsConfirmationUrl);
        //window.location.assign(agreementData.vippsConfirmationUrl);
      } else {
        addAlert('error', t('alerts.somethingWentWrong'));
      }
    } catch (error) {
      logger.error('Create subscription order failed(vipps)', error);
      axiosAlert(
        error as AxiosError,
        [
          {
            status: 400,
            message: t('payment.alerts.vippsBadField')
          }
        ],
        t('alerts.somethingWentWrong')
      );
      onFail();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default VippsCheckout;
