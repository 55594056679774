import { CssBaseline } from '@mui/material';
import { config } from 'utils';
import './SplashScreen.scss';

const SplashScreen = () => (
  <div className='SplashScreen'>
    <CssBaseline />
    <div className='SplashContainer'>
      {config?.SPLASH_SCREEN_LOGO && <img src={config?.SPLASH_SCREEN_LOGO} />}
      <div style={{ textAlign: 'center' }}>
        <div id='loading-spinner'></div>
      </div>
    </div>
  </div>
);

export default SplashScreen;
