import './PrimaryNav.scss';
import { FC, ReactNode, useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { List, ListItem, ListItemText, SwipeableDrawer } from '@mui/material';
import NavItem from 'types/NavItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Box } from '@mui/system';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import PrimaryFooter from 'components/PrimaryFooter/PrimaryFooter';
import theme from 'styles/theme';
import useDevice from 'hooks/useDevice';

type PrimaryNavProps = {
  children: ReactNode;
  action?: ReactNode;
  title?: string;
  navList?: NavItem[];
  centerContent?: boolean;
  className?: string;
  showAuth?: boolean;
};

const PrimaryNav: FC<PrimaryNavProps> = ({
  children,
  action,
  title,
  navList = [],
  centerContent = false,
  className,
  showAuth = true
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const device = useDevice();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigateHandler = (path: string) => {
    navigate(path);
  };

  // Closes drawer on mobil navigation
  useEffect(() => {
    if (device === 'tablet' || device === 'mobile') {
      setMobileOpen(false);
    }
  }, [location]);

  const drawer = (
    <List
      sx={{
        paddingTop: 0,
        // selected and (selected + hover) states
        '&& .Mui-selected, && .Mui-selected:hover': {
          bgcolor: '#efefef',
          '& .MuiTypography-root': {
            fontWeight: 400
          }
        }
      }}
    >
      {navList.map((navItem: NavItem, index: number) => (
        <ListItem
          button
          key={index}
          selected={pathname === navItem.path}
          onClick={() => {
            if (navItem.path.startsWith('https://') || navItem.path.startsWith('http://')) {
              window.location.href = navItem.path;
            } else {
              navigateHandler(navItem.path);
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '7px',
              alignItems: 'center'
            }}
          >
            {navItem?.icon}
            <ListItemText primary={navItem.title} />
          </Box>
        </ListItem>
      ))}
    </List>
  );

  const centerContentClass = centerContent ? 'center-content' : '';

  const NavDrawer =
    device === 'tablet' || device === 'mobile' ? (
      <SwipeableDrawer // Mobile drawer
        disableSwipeToOpen
        className={'drawer'}
        variant='temporary'
        anchor={'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        onOpen={handleDrawerToggle}
        transitionDuration={{ exit: 100 }}
        classes={{
          paper: 'drawer-paper'
        }}
        sx={{
          '& .MuiDrawer-paper': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
          }
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <div className={'drawer-container'}>{drawer}</div>
      </SwipeableDrawer>
    ) : (
      <div
        style={{
          width: '240px',
          height: '100%'
        }}
      >
        <Drawer // Tablet/desktop drawer
          className={'drawer'}
          classes={{
            paper: 'drawer-paper'
          }}
          variant='permanent'
          open
        >
          <div className={'drawer-container'}>
            <div className={'toolbar'}></div>
            {drawer}
          </div>
        </Drawer>
      </div>
    );

  const displayDrawer = navList.length > 0;

  return (
    <>
      <div className={`primary-nav ${className || ''}`}>
        <PrimaryHeader
          displayDrawer={displayDrawer}
          title={title}
          actions={action}
          showAuth={showAuth && user ? true : false}
          onDrawerClick={handleDrawerToggle}
        />
        {displayDrawer && NavDrawer}
        <Box
          className={`${'content-container'} ${centerContentClass}`}
          sx={{
            marginTop: '64px',
            background: '#efefef',
            [theme.breakpoints.up('lg')]: {
              overflow: 'hidden',
              borderBottomLeftRadius: '100px',
              marginBottom: '-100px',
              zIndex: 1300
            }
          }}
        >
          {children}
        </Box>
      </div>
      <PrimaryFooter sx={{ [theme.breakpoints.up('md')]: { minHeight: '100px' } }} />
    </>
  );
};

export default PrimaryNav;
