import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const userDistributionService = {
  get: () => {
    return axios.get(`user/distribution/current-user/`);
  },
  updateHoldPaper: (from: Date, to: Date) => {
    return axios.put(`user/distribution/current-user/hold-paper/`, {
      from,
      to
    });
  }
};

export default userDistributionService;
