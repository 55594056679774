import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactNode } from 'react';
import theme from 'styles/theme';

type PaperContentProps = {
  children: ReactNode;
  sx?: SxProps;
};

const PaperContent: FC<PaperContentProps> = ({ children, sx }) => {
  const styledPaperContent: SxProps = {
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px'
    }
  };
  return <Box sx={{ ...styledPaperContent, ...sx }}>{children}</Box>;
};

export default PaperContent;
