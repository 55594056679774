import { Dashboard } from 'components';
import Receipt from 'privatePages/Receipt/Receipt';
import {
  Login,
  OrderSubscription,
  ForgotPasswordForm,
  SetNewPassword,
  Authenticate,
  OrderCompletion,
  FaqPage,
  ErrorPage
} from 'pages';
import OrderPayment from 'pages/OrderPaymentPage/OrderPaymentPage';
import Payments from 'privatePages/Payments/Payments';
import { Profile, MySubscription } from 'privatePages';
import UserTokens from 'privatePages/UserTokens/UserTokens';
import GiftSubscription from 'privatePages/GiftSubscription/GiftSubscription';
import GiftSubscriptionPayment from 'privatePages/GiftSubscriptionPayment/GiftSubscriptionPayment';
import HoldPaper from 'privatePages/Paper/HoldPaper';

const routes = {
  //Private
  // ---------------------------------------
  SUBSCRIPTION: {
    path: '/subscription',
    private: true,
    component: (
      <Dashboard>
        <MySubscription />
      </Dashboard>
    )
  },
  USER_TOKENS: {
    path: '/active-devices',
    private: true,
    component: (
      <Dashboard>
        <UserTokens />
      </Dashboard>
    )
  },
  ORDER_SUBSCRIPTION_PAYMENT: {
    path: '/order-subscription/pay/:uuid',
    private: true,
    component: <OrderPayment />
  },

  CHANGE_PAYMENT_METHOD: {
    path: '/change-payment-method/pay/:uuid',
    private: true,
    component: <OrderPayment changePaymentSolution />
  },
  PAYMENTS: {
    path: '/payment',
    private: true,
    component: (
      <Dashboard>
        <Payments />
      </Dashboard>
    )
  },
  RECEIPT: {
    path: '/receipt/:id',
    private: true,
    component: <Receipt />
  },
  GIFT_SUBSCRIPTION: {
    path: '/gift-subscription/',
    private: true,
    redirectFromLogin: true,
    component: <GiftSubscription />
  },
  GIFT_SUBSCRIPTION_UUID: {
    path: '/gift-subscription/:uuid',
    private: true,
    component: <GiftSubscription />
  },
  GIFT_SUBSCRIPTION_PAYMENT: {
    path: '/gift-subscription/pay/:uuid',
    private: true,
    component: <GiftSubscriptionPayment />
  },
  HOLD_PAPER: {
    path: '/hold-paper',
    private: true,
    component: (
      <Dashboard>
        <HoldPaper />
      </Dashboard>
    )
  },

  // Public
  // ---------------------------------------
  FAQ: {
    path: '/faq/',
    private: false,
    component: <FaqPage />
  },
  ORDER_CONFIRMATION: {
    path: '/order-confirmation/:uuid',
    private: false,
    component: <OrderCompletion />
  },
  LOGIN: {
    path: '/login',
    private: false,
    component: <Login />
  },
  ORDER_SUBSCRIPTION_SELECT: {
    path: '/order-subscription/select',
    private: false,
    component: <OrderSubscription />
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    private: false,
    component: <ForgotPasswordForm />
  },
  SET_NEW_PASSWORD: {
    path: '/set-new-password/:token',
    private: false,
    component: <SetNewPassword />
  },
  AUTHENTICATE: {
    path: '/authenticate',
    private: false,
    component: <Authenticate />
  },
  // Default
  PROFILE: {
    path: '/',
    private: true,
    component: (
      <Dashboard>
        <Profile />
      </Dashboard>
    )
  },
  ERROR_PAGE: {
    path: '*',
    private: false,
    component: <ErrorPage />
  }
};
export default routes;
