import axios from 'axios';
import UserSubscription from 'types/UserSubscription';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const userSubscriptionService = {
  getUserSubscription: (validate = false) => {
    const urlParams = validate ? '?validate=true' : '';

    return axios.get<UserSubscription>(`user-subscription/current-user${urlParams}`);
  },
  cancelSubscription: () => {
    return axios.post('user-subscription/current-user/cancel');
  },
  activateSubscription: () => {
    return axios.post('user-subscription/current-user/activate');
  }
};

export default userSubscriptionService;
