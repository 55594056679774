/* eslint-disable @typescript-eslint/no-explicit-any */
import VippsAgreement from 'components/Vipps/types/VippsAgreement';
import logger from 'logger/logger';
import { useCookies } from 'react-cookie';

import vippsService from 'services/vipps.service';

type VippsConfirmation = {
  orderUuid: string;
  vippsConfirmationUrl: string;
  vippsAgreementId: string;
};

const useVipps = () => {
  /**
   * Validates vipps agreement a
   * @param agreementId
   * @returns
   */

  const [cookies, setCookie, removeCookie] = useCookies(['vipps-confirmation-url']);

  async function validateVippsAgreement(orderUuid: string): Promise<VippsAgreement> {
    try {
      let { data: vippsAgreement } = await vippsService.getAgreementByOrderUuid(orderUuid);

      if (vippsAgreement.status !== 'PENDING') {
        return vippsAgreement;
      }

      // If pending try to whait then get again
      let attemts = 0;
      while (attemts < 5 && vippsAgreement.status === 'PENDING') {
        vippsAgreement = await new Promise((resolve, reject) =>
          setTimeout(async () => {
            try {
              const vippsRes = await vippsService.getAgreementByOrderUuid(orderUuid);
              resolve(vippsRes.data);
            } catch (error) {
              reject(error);
            }
          }, 2000)
        );

        if (vippsAgreement.status !== 'PENDING') {
          break;
        }

        attemts++;
      }

      return vippsAgreement;
    } catch (error) {
      logger.error('Validate vipps failed', error);
      throw error;
    }
  }

  const getVippsConfirmationUrl = (orderUuid: string) => {
    const vippsConfirmation = cookies['vipps-confirmation-url'] as VippsConfirmation;

    if (!vippsConfirmation) {
      return;
    }

    if (vippsConfirmation?.orderUuid === orderUuid) {
      return vippsConfirmation.vippsConfirmationUrl;
    }
  };

  const setVippsConfirmationUrl = (
    orderUuid: string,
    vippsConfirmationUrl: string,
    vippsAgreementId: string,
    expires: Date
  ) => {
    removeCookie('vipps-confirmation-url');
    setCookie(
      'vipps-confirmation-url',
      { orderUuid, vippsConfirmationUrl, vippsAgreementId } as VippsConfirmation,
      {
        expires,
        path: '/'
      }
    );
  };

  return { validateVippsAgreement, getVippsConfirmationUrl, setVippsConfirmationUrl };
};

export default useVipps;
