import Skin from 'types/Skin';

const haramsnytt: Skin = {
  COLORS: {
    PRIMARY: '#800b0a',
    PRIMARY_DARK: '#520908',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#800b0a',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://dyplink.no/wp-content/uploads/2024/06/haramsnytt-icon.svg',
    MAIN: 'https://dyplink.no/wp-content/uploads/2024/06/Haramsnytt.svg',
  },
};

export default haramsnytt;