import { FC, useEffect, useState } from 'react';
import './app.css';
import { Routes, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import axios, { AxiosError } from 'axios';
import RouteItem from 'types/RouteItem';
import routes from './navigation/routes';
import { Alerts } from 'components';
import { config } from 'utils';
import useUser from 'hooks/useUser';
import useAxiosAlert from 'hooks/useAxiosAlert';
import useSettings from 'hooks/useSettings';
import gaService from 'services/ga.service';
import logger from 'logger/logger';
import { Box } from '@mui/material';
import useRoute from 'hooks/useRoute';
import { useLocation } from 'react-router';
import useAlert from 'hooks/useAlert';
import Alert from 'types/Alert';
import SplashScreen from 'components/SplashScreen/SplashScreen';
import { RootState, newsroomAppActions } from 'store';
import useScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { useTranslation } from 'react-i18next';
import analyticsService from 'services/analytics.service';

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const App: FC = () => {
  const { t } = useTranslation();
  const [initialLoading, setInitialLoading] = useState(true);
  useScrollToTop();
  const createRoute = useRoute();
  const { fetchUser } = useUser();
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosAlert = useAxiosAlert();
  const { syncSettings } = useSettings();
  const { addAlert } = useAlert();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const newsroomApp = queryParams.get('newsroomApp');
  const authTokenFromUrl = queryParams.get('authToken');

  useEffect(() => {
    (async () => {
      const settings = await syncSettings();
      const platformIdSetting = settings.find((s) => s.key === 'AnalyticsPlatformId');
      analyticsService.init(platformIdSetting?.value);
    })();
  }, []);

  useEffect(() => {
    const newsroomAppFromStorage = localStorage.getItem('newsroomApp');

    if (newsroomApp || newsroomAppFromStorage) {
      dispatch(newsroomAppActions.setNewsroomApp(newsroomApp || newsroomAppFromStorage));
      localStorage.setItem('newsroomApp', newsroomApp);
    }
  }, [newsroomApp]);

  useEffect(() => {
    if (user && user.archived) {
      navigate(routes.LOGIN.path);
    }
  }, [user]);

  useEffect(() => {
    gaService.init();

    if (authTokenFromUrl) {
      localStorage.setItem('auth-token', authTokenFromUrl);
      queryParams.delete('authToken');
    }

    const authToken = localStorage.getItem('auth-token');

    const authenticateUser = async () => {
      if (authToken) {
        try {
          axios.defaults.headers.common['Authorization'] = authToken;
          await fetchUser();
        } catch (error) {
          logger.error('Authenticat user failed', error);
          axiosAlert(error as AxiosError, [
            {
              // Gives authentication failed alert if user is not authenticated, but has a token
              status: 400,
              message: t('navigation.alerts.invalidToken'),
              cb: () => {
                localStorage.removeItem('auth-token');
              }
            },
            {
              // Gives authentication failed alert if user is not authenticated, but has a token
              status: 401,
              message: t('navigation.alerts.invalidToken'),
              cb: () => {
                localStorage.removeItem('auth-token');
              }
            },
            {
              // Gives authentication failed alert if user is not authenticated, but has a token
              status: 410,
              message: t('navigation.alerts.sessionExpired')
            }
          ]);
        } finally {
          setInitialLoading(false);
        }
      } else {
        setInitialLoading(false);
      }
    };

    authenticateUser();
  }, [dispatch]);

  // Fires a alert on navigation if sendt from another page
  useEffect(() => {
    if (location?.state && location.state['alert']) {
      const alert = location?.state['alert'] as Alert;
      addAlert(alert?.type || 'success', alert.text || '', (alert.timeout = 5000));
    }

    const temporaryAlert = sessionStorage.getItem('temporaryAlert') as string;

    if (temporaryAlert) {
      const alert = JSON.parse(temporaryAlert) as Alert;
      addAlert(alert?.type || 'success', alert.text || '', (alert.timeout = 5000));
      sessionStorage.removeItem('temporaryAlert');
    }

    // Scroll to top on navigation
    const app = document.querySelector('.App');
    if (app) {
      app.scrollTo(0, 0);
    }
  }, [location]);

  if (initialLoading) {
    return <SplashScreen />;
  } else {
    return (
      <Box className='App'>
        <Alerts />
        <Routes>
          {Object.values(routes).map((routeItem: RouteItem, index) => {
            return createRoute(routeItem, index);
          })}
        </Routes>
      </Box>
    );
  }
};

export default App;
