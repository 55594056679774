import './PrimaryCard.scss';
import { Card, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

type PrimaryCardProps = {
  children: ReactNode;
  className?: string;
  sx?: SxProps;
  onClick?: () => void;
  variant?: 'outlined' | 'elevation';
  elevation?: number;
};

const PrimaryCard: FC<PrimaryCardProps> = ({
  children,
  className = '',
  sx,
  variant = 'elevation',
  elevation = 1,
  onClick = () => {}
}) => {
  return (
    <>
      {variant === 'elevation' && (
        <Card
          elevation={elevation}
          onClick={onClick}
          sx={sx}
          variant={variant}
          className={`primary-card ${className}`}
        >
          <div className={'container'}>{children}</div>
        </Card>
      )}
      {variant === 'outlined' && (
        <Card onClick={onClick} sx={sx} variant={variant} className={`primary-card ${className}`}>
          <div className={'container'}>{children}</div>
        </Card>
      )}
    </>
  );
};

export default PrimaryCard;
