import { PageContainer } from 'components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useSettings from 'hooks/useSettings';
import { useNavigate } from 'react-router';
/* eslint-disable indent */
import { Button, Stack, Typography } from '@mui/material';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import userDistributionService from 'services/user-distribution.service';
import UserDistribution from 'types/UserDistribution';
import { FC, useState, useEffect } from 'react';
import HoldPaperModal from './components/HoldPaperModal';

const HoldPaper: FC = () => {
  const { t } = useTranslation();
  const { getValueByKey } = useSettings();
  const user = useSelector((state: RootState) => state.user);
  const nrsPaper = getValueByKey('NrsPaper');
  const navigate = useNavigate();
  const [userDistribution, setuserDistribution] = useState<UserDistribution>(null);
  const { fetchUser } = useUser();
  const [modal, setModal] = useState<number>();

  const getUserDistribution = async () => {
    const userDistributionQuery = await userDistributionService.get();
    if (userDistributionQuery.data.holdFromDate !== null) {
      userDistributionQuery.data.holdFromDate = new Date(userDistributionQuery.data.holdFromDate);
    }
    if (userDistributionQuery.data.holdToDate !== null) {
      userDistributionQuery.data.holdToDate = new Date(userDistributionQuery.data.holdToDate);
    }
    setuserDistribution(userDistributionQuery.data);
  };

  const holdPaperActive =
    !!userDistribution?.holdFromDate && !!userDistribution?.holdToDate && new Date() < userDistribution.holdToDate;

  useEffect(() => {
    if (!nrsPaper || !user?.userSubscription?.subscription?.paper) {
      navigate('/');
    } else {
      getUserDistribution();
    }
  }, []);

  return (
    <PageContainer centerHorizontal>
      <Stack gap={2}>
        <PaperCard>
          <PaperHeader>
            <Typography variant='h4'>{t('paper.holdPaper..')}</Typography>
          </PaperHeader>
          <PaperContent>
            <Stack gap={2}>
              <Typography>{t('paper.holdPaper.description')}</Typography>
              {holdPaperActive ? (
                <>
                  <Typography sx={{ fontWeight: 700 }}>
                    {t('paper.holdPaper.paperOnHold', {
                      from: userDistribution.holdFromDate.toLocaleDateString(),
                      to: userDistribution.holdToDate.toLocaleDateString()
                    })}
                  </Typography>
                </>
              ) : (
                <Typography sx={{ fontWeight: 700 }}>
                  {t('paper.holdPaper.paperNotOnHold')}
                </Typography>
              )}
              <Button
                variant={'outlined'}
                onClick={() => {
                  setModal(1);
                }}
              >
                {holdPaperActive ? t('paper.holdPaper.edit') : t('paper.holdPaper.add')}
              </Button>
            </Stack>
          </PaperContent>
        </PaperCard>
        {modal === 1 && (
          <HoldPaperModal
            userDistribution={holdPaperActive ? userDistribution : null}
            onCancel={() => setModal(null)}
            onFinished={() => {
              setModal(null);
              getUserDistribution();
              fetchUser();
            }}
          />
        )}
      </Stack>
    </PageContainer>
  );
};

export default HoldPaper;
