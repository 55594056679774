/* eslint-disable indent */
import { Computer, Edit, PhoneAndroid, TabletMac } from '@mui/icons-material';
import { Box, Button, Chip, Divider, IconButton, Stack, SxProps, Typography } from '@mui/material';
import BrowserIcon from 'components/BrowserIcon/BrowserIcon';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';

import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';

import UserToken, { DeviceType } from 'types/UserToken';
import { capitalizeFirstLetter } from 'utils';
import ChangeUserTokenDialog from 'components/ChangeUserTokenNameDialog/ChangeUserTokenNameDialog';

type UserTokenDetailsProps = {
  userToken: UserToken;
  isUser?: boolean;
  onRemoveAsActiveDevice?: (userToken: UserToken) => void;
  onLogout?: (userToken: UserToken) => void;
  onChanged?: () => void;
  onAddAsActiveDevice?: (userToken: UserToken) => void;
  onRemoveExpired?: (userToken: UserToken) => void;
  maxActiveLoginsReached?: boolean;
};

const UserTokenDetails: FC<UserTokenDetailsProps> = ({
  userToken,
  isUser,
  onRemoveAsActiveDevice,
  onLogout,
  onAddAsActiveDevice,
  onRemoveExpired,
  onChanged,
  maxActiveLoginsReached
}) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<number>();

  const getDeviceIcon = (device: DeviceType) => {
    // create switch
    let icon: ReactNode;

    switch (device) {
      case 'PHONE':
        icon = <PhoneAndroid />;
        break;
      case 'DESKTOP':
        icon = <Computer />;
        break;
      case 'TABLET':
        icon = <TabletMac />;
        break;
      default:
        break;
    }
    return icon;
  };

  const getNumberOfDaysFromDateString = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const diff = date.getTime() - now.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return days;
  };

  const deviceIcon = getDeviceIcon(userToken.deviceType);
  const expirationDays = getNumberOfDaysFromDateString(userToken.expires);

  const styledCard: SxProps = {
    width: '384px',
    padding: '16px',
    paddingBottom: '8px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
      width: '100%',
      minWidth: '100%'
    }
  };

  // FUnction that creates a date string containing date and time down to the minutes
  const getDateTimeString = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().substr(-2);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    //get name of day norwegian full
    const dayName = date.toLocaleDateString('nb-NO', { weekday: 'long' });
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Add leading zeros
    const addLeadingZero = (number: number) => {
      return number < 10 ? `0${number}` : number;
    };

    // is date today boolean gotten by anonymous function
    const isToday = (() => {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    })();

    return `${
      isToday
        ? t('common.today')
        : `${capitalizeFirstLetter(dayName)} ${addLeadingZero(day)}.${addLeadingZero(
            month
          )}.${year}`
    }, ${addLeadingZero(hours)}:${addLeadingZero(minutes)}`;
  };

  const handleChangeName = () => {
    onChanged();
    setModal(null);
  };

  const expired = userToken.expires < new Date().toISOString();

  return (
    <>
      <PaperCard sx={styledCard} variant={'outlined'}>
        <PaperContent sx={{ p: 0}}>
          <Stack direction='column' spacing={1}>
            <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
              <Stack direction='row' spacing={1} alignItems={'center'}>
                {isUser && (
                  <Box
                    sx={{
                      background: theme.palette.info.main,
                      width: '17px',
                      height: '17px',
                      borderRadius: '10px'
                    }}
                  ></Box>
                )}
                {deviceIcon}
                <Typography>{userToken.name || userToken?.deviceName}</Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setModal(1);
                }}
              >
                <Edit />
              </IconButton>
            </Stack>
            <Divider />
            <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
              <Typography>{t('common.browser')}</Typography>
              <Stack direction='row' spacing={1} alignItems={'center'}>
                <Typography>{capitalizeFirstLetter(userToken.browser)}</Typography>
                <BrowserIcon browser={userToken.browser} />
              </Stack>
            </Stack>
            <Divider />
            <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
              <Typography>{t('common.status')}</Typography>
              {!expired && (
                <Typography>
                  {t('common.expiresIn')} {expirationDays} {t('common.days')}
                </Typography>
              )}
              {expired && (
                <Chip
                  label={t('common.expired').toUpperCase()}
                  variant={'outlined'}
                  color={'error'}
                />
              )}
            </Stack>
            <Divider />

            <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
              <Typography>{t('common.lastUsed')}</Typography>
              <Typography>{getDateTimeString(userToken.lastUsed)}</Typography>
            </Stack>
            <Divider />
            <Stack direction='row' justifyContent={'flex-end'} spacing={1}>
              {!userToken.activeDevice && (
                <Button
                  onClick={() => onAddAsActiveDevice(userToken)}
                  disabled={maxActiveLoginsReached}
                >
                  {t('userTokens.addToActive')}
                </Button>
              )}
              {userToken.activeDevice && (
                <Button onClick={() => onRemoveAsActiveDevice(userToken)} color='error'>
                  {t('userTokens.removeAsActive')}
                </Button>
              )}
              {!expired && (
                <Button onClick={() => onLogout(userToken)} color='error'>
                  {isUser ? t('userTokens.logYourselfOut') : t('common.logout')}
                </Button>
              )}
              {expired && (
                <Button onClick={() => onRemoveExpired(userToken)} color='error'>
                  {t('userTokens.removeExpired')}
                </Button>
              )}
            </Stack>
          </Stack>
        </PaperContent>
      </PaperCard>
      {modal === 1 && (
        <ChangeUserTokenDialog
          onCancel={() => setModal(null)}
          onConfirm={handleChangeName}
          userToken={userToken}
        />
      )}
    </>
  );
};

export default UserTokenDetails;
