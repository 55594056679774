import { Button, Checkbox, Typography, Link } from '@mui/material';
import FlexBox from 'components/FlexBox/FlexBox';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import useSettings from 'hooks/useSettings';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type SubscriptionTermsOfServiceConsentProps = {
  onComplete: () => void;
};

const SubscriptionTermsOfServiceConsent: FC<SubscriptionTermsOfServiceConsentProps> = ({
  onComplete
}) => {
  const [consent, setConsent] = useState<boolean>();
  const { t } = useTranslation();
  const { getValueByKey } = useSettings();

  const termsOfServiceUrl = getValueByKey('TermsOfServiceUrl');

  return (
    <PaperCard variant='outlined' sx={{ width: '100%' }}>
      <PaperContent>
        <FlexBox direction='column' gap={16} alignItems={'flex-start'}>
          <FlexBox gap={8} justifyContent='space-between'>
            <Typography>{t('userSubscription.termsOfServiceConsent')}</Typography>
            <Checkbox
              name={'termsOfServiceConsent'}
              value={consent}
              onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                setConsent(checked);
              }}
            />
          </FlexBox>
          <Link href={termsOfServiceUrl} target='_blank' title=''>
            <Typography>{t('userSubscription.termsOfServiceLinkText')}</Typography>
          </Link>
          <Button
            disabled={!consent}
            fullWidth
            variant='outlined'
            onClick={() => {
              onComplete();
            }}
          >
            {t('common.confirm')}
          </Button>
        </FlexBox>
      </PaperContent>
    </PaperCard>
  );
};

export default SubscriptionTermsOfServiceConsent;
