/* eslint-disable indent */
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Stack, SxProps } from '@mui/system';
import useSettings from 'hooks/useSettings';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CountryCode, { countryCodes } from 'types/CountryCode';
import { getCountryCodeFlag, validate } from 'utils';

export type ExtraRegisterFields = {
  name: string;
  password: string;
  passwordConfirm: string;
  email?: string;
  address: string;
  postCode: string;
  postOffice: string;
  address2: string;
  country: CountryCode;
  state: string;
  careOf: string;
  entrance: string;
  floor: string;
};

type PasswordFormProps = {
  onSubmit: (fields: ExtraRegisterFields) => void;
  isNewUser: boolean;
  loading?: boolean;
};

const PasswordForm: FC<PasswordFormProps> = ({ onSubmit, isNewUser, loading }) => {
  const { getValueByKey } = useSettings();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch
  } = useForm<ExtraRegisterFields>();

  const country = watch('country');
  const [displayExtraFields, setDisplayExtraFields] = useState<boolean>(false);

  const styledSelect: SxProps = {
    borderRadius: '50px',
    minWidth: 'auto',
    flex: '1',
    zIndex: 100002
  };

  const isFieldRequired = (value: number) => value === 2;
  const userFieldsEnum = {
    OPTIONAL: 1,
    REQUIRED: 2
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', rowGap: '12px' }}
    >
      {isNewUser && (
        <TextField
          type='name'
          autoFocus
          label={`${t('common.name') + ' *'}`}
          {...register('name', {
            required: true,
            validate: (name) => validate.length(name, 2, 50)
          })}
          fullWidth
          error={errors.name ? true : false}
          helperText={errors.name ? errors.name.message : ''}
        />
      )}
      {isNewUser &&
        (parseInt(getValueByKey('UserFormCustomFieldEmail')) === userFieldsEnum.OPTIONAL ||
          parseInt(getValueByKey('UserFormCustomFieldEmail')) === userFieldsEnum.REQUIRED) && (
          <TextField
            type='email'
            label={`${t('common.email')}${
              parseInt(getValueByKey('UserFormCustomFieldEmail')) === userFieldsEnum.REQUIRED
                ? ' *'
                : ''
            }`}
            {...register('email', {
              required:
                parseInt(getValueByKey('UserFormCustomFieldEmail')) === userFieldsEnum.REQUIRED,
              validate: (email) => {
                if (
                  !email.length &&
                  parseInt(getValueByKey('UserFormCustomFieldEmail')) === userFieldsEnum.OPTIONAL
                ) {
                  return true;
                }

                if (!validate.email(email)) {
                  return t('common.invalidEmail');
                }

                return true;
              }
            })}
            fullWidth
            error={errors.email ? true : false}
            helperText={errors.email ? errors.email.message : ''}
          />
        )}

      {isNewUser &&
        parseInt(getValueByKey('UserFormCustomFieldPostalCode')) > 0 &&
        !parseInt(getValueByKey('UserFormCustomFieldAddress')) && (
          <TextField
            sx={{ flex: 1 }}
            name={'postCode'}
            label={`${t('common.postCode')}${
              parseInt(getValueByKey('UserFormCustomFieldPostalCode')) === userFieldsEnum.REQUIRED
                ? ' *'
                : ''
            }`}
            {...register('postCode', {
              required:
                parseInt(getValueByKey('UserFormCustomFieldPostalCode')) === userFieldsEnum.REQUIRED
            })}
            helperText={errors.postCode ? errors.postCode.message : ''}
            error={!!errors.postOffice}
          />
        )}

      {isNewUser &&
        (parseInt(getValueByKey('UserFormCustomFieldAddress')) === userFieldsEnum.OPTIONAL ||
          parseInt(getValueByKey('UserFormCustomFieldAddress')) === userFieldsEnum.REQUIRED) && (
          <Stack gap={2}>
            <Stack gap={2} sx={{ mt: 1 }}>
              <TextField
                name={'address'}
                label={`${t('common.streetAddress')}${
                  parseInt(getValueByKey('UserFormCustomFieldAddress')) === userFieldsEnum.REQUIRED
                    ? ' *'
                    : ''
                }`}
                {...register('address', {
                  required:
                    isFieldRequired(parseInt(getValueByKey('UserFormCustomFieldAddress'))) ||
                    undefined
                })}
                error={!!errors.address}
                helperText={errors.address?.message || ''}
              />
              <Stack direction={'row'} gap={1}>
                <TextField
                  sx={{ flex: 1 }}
                  name={'postCode'}
                  label={`${t('common.postCode')}${
                    parseInt(getValueByKey('UserFormCustomFieldAddress')) === 2 ? ' *' : ''
                  }`}
                  {...register('postCode', {
                    required:
                      parseInt(getValueByKey('UserFormCustomFieldAddress')) ===
                      userFieldsEnum.REQUIRED
                  })}
                  error={!!errors.postCode}
                  helperText={errors.postCode?.message || ''}
                />
                <TextField
                  sx={{ flex: 2 }}
                  name={'postOffice'}
                  label={`${t('common.postOffice')}${
                    parseInt(getValueByKey('UserFormCustomFieldAddress')) === 2 ? ' *' : ''
                  }`}
                  {...register('postOffice', {
                    required:
                      parseInt(getValueByKey('UserFormCustomFieldAddress')) ===
                      userFieldsEnum.REQUIRED
                  })}
                  error={!!errors.postOffice}
                  helperText={errors.postOffice?.message || ''}
                />
              </Stack>
            </Stack>
          </Stack>
        )}

      {isNewUser &&
        parseInt(getValueByKey('NrsPaper')) === userFieldsEnum.OPTIONAL &&
        (parseInt(getValueByKey('UserFormCustomFieldAddress')) === userFieldsEnum.OPTIONAL ||
          parseInt(getValueByKey('UserFormCustomFieldAddress')) === userFieldsEnum.REQUIRED) && (
          <Stack gap={2}>
            <Stack
              gap={2}
              sx={{
                mt: 1
              }}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  p: '12px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #ccc'
                }}
                onClick={() => setDisplayExtraFields(!displayExtraFields)}
              >
                <Typography>{t('common.extraFields')}</Typography>
                {!displayExtraFields && <KeyboardArrowRight />}
                {displayExtraFields && <KeyboardArrowDown />}
              </Stack>

              {displayExtraFields && (
                <Stack gap={2}>
                  <TextField
                    name={'address2'}
                    label={t('common.address') + ' 2'}
                    {...register('address2')}
                    error={!!errors.address2}
                  />
                  <TextField
                    name={'entrance'}
                    label={t('common.entrance')}
                    {...register('entrance', {})}
                    error={!!errors.entrance}
                  />
                  <TextField
                    name={'floor'}
                    label={t('common.floor')}
                    {...register('floor', {
                      validate: (val) => validate.length(val, 1, 50, true)
                    })}
                    error={!!errors.floor}
                  />
                  <TextField
                    name={'careOf'}
                    label={t('common.careOf')}
                    {...register('careOf', {})}
                    error={!!errors.careOf}
                  />
                  <FormControl>
                    <InputLabel>{t('common.country')}</InputLabel>
                    <Select
                      {...register('country', { required: true })}
                      defaultValue={country}
                      sx={styledSelect}
                      label={t('common.country')}
                      MenuProps={{
                        sx: {
                          ['.MuiPaper-root']: {
                            margin: '0',
                            minWidth: 'auto !important'
                          }
                        }
                      }}
                    >
                      {countryCodes.map((countryCode, index) => (
                        <MenuItem key={index} value={countryCode}>
                          {getCountryCodeFlag(countryCode)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {country && country !== 'NO' && (
                    <TextField
                      sx={{ flex: 2 }}
                      name={'state'}
                      label={t('common.state-region') + ' (' + t('common.ifNecessary') + ')'}
                      {...register('state', {})}
                      error={!!errors.country}
                    />
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        )}

      <TextField
        id='password-input'
        type='password'
        className={'field'}
        label={t('password.password') + ' *'}
        {...register('password', {
          required: true,
          validate: (val) => {
            if (isNewUser) {
              const validatedPassword = validate.password(val);
              const validatedPasswordlength = validate.length(val, 6, 50);
              if (validatedPassword !== true) {
                return validatedPassword;
              }

              if (validatedPasswordlength !== true) {
                return t('password.passwordMustBeAtLeast6Characters').toString();
              }

              return true;
            } else {
              const validatedPasswordlength = validate.length(val, 6, 50);

              if (validatedPasswordlength !== true) {
                return t('password.passwordMustBeAtLeast6Characters').toString();
              }

              return true;
            }
          }
        })}
        error={errors.password ? true : false}
        fullWidth
        helperText={errors.password ? errors.password.message : ''}
      />

      {isNewUser && (
        <TextField
          type='password'
          label={`${t('password.repeatPassword') + ' *'}`}
          {...register('passwordConfirm', {
            required: true,
            validate: (passwordConfirm) =>
              passwordConfirm === getValues('password')
                ? true
                : t('password.passwordsMustMatch').toString()
          })}
          fullWidth
          error={errors.passwordConfirm ? true : false}
          helperText={errors.passwordConfirm ? errors.passwordConfirm.message : ''}
        />
      )}

      <Button
        disabled={loading}
        type={'submit'}
        fullWidth
        variant='contained'
        sx={{ marginBottom: '10px' }}
      >
        {isNewUser ? t('login.createUser') : t('login.signIn')}
      </Button>
    </form>
  );
};

export default PasswordForm;
