/* eslint-disable indent */
import { SxProps } from '@mui/material';
import { Box } from '@mui/system';
import FooterLight from 'components/FooterLight/FooterLight';
import PrimaryFooter from 'components/PrimaryFooter/PrimaryFooter';
import PrimaryHeader from 'components/PrimaryHeader/PrimaryHeader';
import { FC, ReactNode } from 'react';
import theme from 'styles/theme';

type PageContainerProps = {
  children: ReactNode;
  className?: string;
  sx?: SxProps;
  sxPageContent?: SxProps;
  fullWidth?: boolean;
  centerVertical?: boolean;
  centerHorizontal?: boolean;
  header?: boolean;
  footer?: boolean;
  footerLight?: boolean;
  title?: string;
  pageCard?: boolean;
  pageCardBreakpoint?: 'sm' | 'md' | 'lg' | 'xl';
};

const PageContainer: FC<PageContainerProps> = ({
  children,
  className,
  sx = {},
  sxPageContent = {},
  fullWidth = true,
  centerVertical = false,
  centerHorizontal = false,
  header = false,
  title,
  footer = false,
  footerLight,
  pageCard,
  pageCardBreakpoint = 'sm'
}) => {
  const sxStyles: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 0,
    margin: 0,
    flex: 1,
    boxSizing: 'border-box',
    [theme.breakpoints.down(pageCardBreakpoint)]: {
      justifyContent: pageCard ? 'flex-start' : 'initial'
    }
  };

  const pageContent: SxProps = {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    gap: '16px',
    flex: 1,
    alignItems: centerHorizontal ? 'center' : 'flex-start',
    justifyContent: centerVertical ? 'center' : 'flex-start',
    maxWidth: '100%',
    marginTop: header ? '84px' : '0px',
    flexDirection: 'column',
    boxSizing: 'border-box',
    [theme.breakpoints.up(pageCardBreakpoint)]: {
      minWidth: '600px',
      padding: '16px'
    },
    [theme.breakpoints.down(pageCardBreakpoint)]: {
      width: '100%',
      padding: pageCard ? 0 : '16px',
      justifyContent: pageCard ? 'flex-start' : centerVertical ? 'center' : 'flex-start',
      minHeight: pageCard ? 'auto' : '80vh',
      flex: 'initial'
    }
  };

  return (
    <>
      {header && <PrimaryHeader title={title} />}
      <Box
        sx={{ ...sxStyles, ...sx }}
        className={`page-container ${className}`}
        maxWidth={`${fullWidth ? null : 'lg'}`}
      >
        <Box maxWidth={null} sx={{ ...pageContent, ...sxPageContent }}>
          {children}
        </Box>
        {footerLight && <FooterLight />}
      </Box>
      {footer && <PrimaryFooter />}
    </>
  );
};

export default PageContainer;
