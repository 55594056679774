import { config } from 'utils';
import VippsAgreement from '../types/VippsAgreement';
import { replace } from 'lodash';

const defaultAgreement: VippsAgreement = {
  isApp: false,
  interval: {
    unit: 'MONTH',
    count: 1
  },
  merchantRedirectUrl: replace(`${config.ORIGIN}/order-confirmation/`, 'http://', 'https://'),
  merchantAgreementUrl: replace(`${config.ORIGIN}/subscription/`, 'http://', 'https://')
};

export default defaultAgreement;
