import { Box, SxProps } from '@mui/system';
import { FC } from 'react';
import { CardBrand } from 'types/Card';

type CardBrandLogoProps = {
  cardBrand: CardBrand;
  sx?: SxProps;
};

const CardBrandLogo: FC<CardBrandLogoProps> = ({ cardBrand, sx }) => {
  const getCardBrandLogoUrl = () => {
    switch (cardBrand.toLocaleLowerCase()) {
      case 'visa':
        return '/assets/payment/visa-logo.svg';
      case 'mastercard':
        return '/assets/payment/mastercard-logo.svg';
      case 'maestro':
        return '/assets/payment/maestro-logo.svg';
      default:
        return '/assets/payment/visa-logo.svg';
    }
  };

  const cardBrandLogoUrl = getCardBrandLogoUrl();

  const styledCardBrandLogo: SxProps = {
    height: '15px',
    ['img']: {
      width: '100%',
      height: '100%'
    }
  };

  return (
    <Box className='card-brand-logo' sx={{ ...styledCardBrandLogo, ...sx }}>
      {<img src={cardBrandLogoUrl} />}
    </Box>
  );
};

export default CardBrandLogo;
