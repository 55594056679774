import { FC, ReactNode } from 'react';
import { PrimaryNav } from 'components';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import NavItem from 'types/NavItem';
import { RootState } from '../../store';
import routes from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LinkIcon from '@mui/icons-material/Link';
import HelpIcon from '@mui/icons-material/Help';
import { Devices, SubscriptionsOutlined } from '@mui/icons-material';
import NewspaperIcon from '@mui/icons-material/Newspaper';

type DashboardProps = {
  children: ReactNode;
};

const Dashboard: FC<DashboardProps> = ({ children }) => {
  const { t } = useTranslation();
  const settings = useSelector((state: RootState) => state.settings);
  const user = useSelector((state: RootState) => state.user);

  const navList: NavItem[] = [
    {
      path: routes.PROFILE.path,
      title: t('common.profile'),
      icon: <PersonIcon />
    },
    {
      path: routes.SUBSCRIPTION.path,
      title: t('subscription.mySubscription'),
      icon: <SubscriptionsOutlined />
    },
    {
      path: routes.PAYMENTS.path,
      title: t('payment.payments'),
      icon: <CreditCardIcon />
    },
    {
      path: routes.USER_TOKENS.path,
      title: t('userTokens.title'),
      icon: <Devices />
    },
    {
      path: routes.FAQ.path,
      title: t('common.help'),
      icon: <HelpIcon />
    }
  ];
  const nrsPaper = !!settings.find((s) => s.key === 'NrsPaper')?.value;
  const userHasPaperSubscription = !!user.userSubscription?.subscription?.paper;
  if (nrsPaper && userHasPaperSubscription) {
    // insert befor help
    navList.splice(navList.length - 2, 0, {
      path: routes.HOLD_PAPER.path,
      title: t('paper.holdPaper..'),
      icon: <NewspaperIcon />
    });
  }

  // External links
  const externalLinksStr = settings.find((s) => s.key === 'ExternalLinks')?.value || '[]';
  let externalLinks: NavItem[];
  try {
    externalLinks = JSON.parse(externalLinksStr);
  } catch (error) {
    externalLinks = []; // Fail silently
  }
  externalLinks.forEach((el) => {
    navList.push({
      path: el.path,
      title: el.title,
      icon: <LinkIcon />
    });
  });

  return user ? <PrimaryNav navList={navList}>{children}</PrimaryNav> : <Navigate to='login' />;
};

export default Dashboard;
