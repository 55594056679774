import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Alert, Button, CircularProgress, Stack, SxProps, Typography } from '@mui/material';
import { ConsentDialog, PageContainer } from 'components';
import LogOutAllDevicesDialog from 'components/LogOutAllDevicesDialog/LogOutAllDevicesDialog';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import useIsMobile from 'hooks/useIsMobile';
import useUser from 'hooks/useUser';
import logger from 'logger/logger';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import userTokenService from 'services/userToken.service';
import { RootState } from 'store';
import theme from 'styles/theme';
import UserToken from 'types/UserToken';
import UserTokenDetails from './components/UserTokenDetails';

const UserTokens: FC = () => {
  const { t } = useTranslation();
  const newsroomApp = useSelector((state: RootState) => state.newsroomApp);
  const [userTokens, setUserTokens] = useState<UserToken[]>();
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<number>();
  const [currentUserToken, setUserToken] = useState<UserToken>();
  const [tokenInEdit, setTokenInEdit] = useState<UserToken>();
  const [hideNoneActiveTokens, setHideNoneActiveTokens] = useState<boolean>(null);
  const [hideActiveTokens, setHideActiveTokens] = useState<boolean>(false);
  const [maxActiveLogins, setMaxActiveLogins] = useState<number>(
    parseInt(
      useSelector((state: RootState) => state.settings.find((s) => s.key === 'MaxActiveLogins'))
        ?.value || '5'
    )
  );

  const { signOut } = useUser();

  const { getToken } = useUser();

  const getUserTokens = async () => {
    try {
      const { data: userMaxActiveLogins } = await userTokenService.getCurrentUserMaxActiveLogins();
      if(userMaxActiveLogins){
        setMaxActiveLogins(userMaxActiveLogins);
      }
      setLoading(true);

      const { data: _userTokens } = await userTokenService.getUserTokens();
      const token = getToken();
      const currentUserToken = _userTokens.find((ut) => ut.token === token);

      if (currentUserToken) {
        setUserToken(currentUserToken);
      }

      setUserTokens(
        _userTokens.filter((ut) => ut.browser !== 'facebook' && ut.browser !== 'newsroomApp')
      );
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userTokens) {
      getUserTokens();
    }
  }, []);

  const addToActiveDevices = async (userToken: UserToken) => {
    setLoading(true);
    try {
      await userTokenService.setToActive(userToken.id);
    } catch (error) {
      logger.error(error);
    } finally {
      await getUserTokens();
      setLoading(false);
    }
  };

  const logoutUserTokenHandler = async () => {
    setModal(null);
    setLoading(true);
    try {
      if (tokenInEdit.id === currentUserToken.id) {
        await signOut();
      } else {
        await userTokenService.logoutToken(tokenInEdit.id);
        await getUserTokens();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);

      setTokenInEdit(null);
    }
  };

  const logoutExpiredUserTokenHandler = async (userToken: UserToken) => {
    setLoading(true);
    try {
      if (userToken.id === currentUserToken.id) {
        await signOut();
      } else {
        await userTokenService.logoutToken(userToken.id);
        await getUserTokens();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const removeTokenAsActiveHandler = async () => {
    setLoading(true);
    setModal(null);
    try {
      await userTokenService.removeActive(tokenInEdit.id);
      await getUserTokens();
    } catch (error) {
      logger.error(error);
    } finally {
      setTokenInEdit(null);
      setLoading(false);
    }
  };

  const activeUserTokensList = userTokens
    // move currentUser to top of list
    ?.filter((ut) => ut.activeDevice)
    .map((activeUserToken) => {
      let isUser = false;

      if (currentUserToken && currentUserToken.id === activeUserToken.id) {
        isUser = true;
      }

      return (
        <UserTokenDetails
          isUser={isUser}
          key={activeUserToken.id}
          userToken={activeUserToken}
          onRemoveExpired={logoutExpiredUserTokenHandler}
          onChanged={() => getUserTokens()}
          onRemoveAsActiveDevice={(userToken: UserToken) => {
            setTokenInEdit(userToken);
            setModal(0);
          }}
          onLogout={(userToken: UserToken) => {
            setTokenInEdit(userToken);
            if (currentUserToken && currentUserToken.id === userToken.id) {
              setModal(2);
            } else {
              setModal(1);
            }
          }}
        />
      );
    })
    .sort((a, b) => {
      if (a.props.isUser) {
        return -1;
      }
      if (b.props.isUser) {
        return 1;
      }
      return 0;
    });

  const numberOfActiveUserTokens = userTokens?.filter((ut) => ut.activeDevice).length;

  const noneActiveUserTokensList = userTokens
    ?.filter((ut) => !ut.activeDevice)
    .map((ut) => {
      let isUser = false;

      if (currentUserToken && currentUserToken.id === ut.id) {
        isUser = true;

        // Display all tokens if current user is in the list
        if (hideNoneActiveTokens === null) {
          setHideNoneActiveTokens(false);
        }
      } else if (hideNoneActiveTokens === null) {
        setHideNoneActiveTokens(true);
      }

      return (
        <UserTokenDetails
          key={ut.id}
          isUser={isUser}
          userToken={ut}
          onRemoveExpired={logoutExpiredUserTokenHandler}
          onLogout={(userToken: UserToken) => {
            setTokenInEdit(userToken);
            if (currentUserToken.id === userToken.id) {
              setModal(2);
            } else {
              setModal(1);
            }
          }}
          onAddAsActiveDevice={addToActiveDevices}
          maxActiveLoginsReached={numberOfActiveUserTokens >= maxActiveLogins}
        />
      );
    })
    .sort((a, b) => {
      if (a.props.isUser) {
        return -1;
      }
      if (b.props.isUser) {
        return 1;
      }
      return 0;
    });

  const styledPageContainer: SxProps = {
    marginTop: '32px',
    marginBottom: '32px',
    width: '100%',
    maxWidth: '1280px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginTop: '0'
    }
  };

  const styledContainer: SxProps = {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  };

  const styledFlexContainer: SxProps = {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  };

  return (
    <>
      <PageContainer
        sx={{ flexDirection: 'row', justifyContent: 'center' }}
        sxPageContent={styledPageContainer}
        centerHorizontal
        pageCard
      >
        <Stack
          width={'100%'}
          direction='column'
          alignItems='center'
          gap={isMobile ? 0 : 4}
          sx={styledContainer}
        >
          <Stack alignItems={'center'} gap={isMobile ? 1 : 4} width={'100%'}>
            {currentUserToken?.browser === 'facebook' ||
              (currentUserToken?.browser === 'newsroomApp' && (
                <>
                  <PaperCard pageCard sx={{ overflow: 'inherit' }}>
                    <PaperHeader>
                      <Typography variant='h4'>{t('userTokens.logedInOnAppTitle', {})}</Typography>
                    </PaperHeader>
                    <PaperContent>
                      <Alert severity={'info'}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('userTokens.logedInOnAppDescription')
                          }}
                        ></div>
                      </Alert>
                    </PaperContent>
                  </PaperCard>
                </>
              ))}
            <PaperCard pageCard sx={{ width: '100%', maxWidth: '100%', overflow: 'inherit' }}>
              <PaperHeader sx={{ textAlign: 'center' }}>
                <Stack
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setHideActiveTokens(!hideActiveTokens)}
                  direction='row'
                  justifyContent='center'
                  spacing={1}
                  alignItems={'center'}
                >
                  <Typography variant='h4'>
                    {t('userTokens.activeDevices')} {numberOfActiveUserTokens}/{maxActiveLogins}
                  </Typography>
                  {isMobile && (
                    <>
                      {hideActiveTokens ? (
                        <ChevronRight fontSize='large' />
                      ) : (
                        <ExpandMore fontSize='large' />
                      )}
                    </>
                  )}
                </Stack>
              </PaperHeader>
              {!hideActiveTokens && (
                <PaperContent sx={{ p: isMobile ? '20px' : '30px' }}>
                  <Stack flexDirection={'row'} gap={2} flexWrap={'wrap'} sx={styledFlexContainer}>
                    {!loading && activeUserTokensList}
                    {!loading && activeUserTokensList?.length === 0 && (
                      <Stack
                        direction='row'
                        justifyContent='center'
                        width={'100%'}
                        alignItems='center'
                        spacing={2}
                      >
                        <Typography>{t('userTokens.noActiveTokens')}</Typography>
                      </Stack>
                    )}
                  </Stack>
                </PaperContent>
              )}
            </PaperCard>
            {noneActiveUserTokensList?.length !== 0 && (
              <PaperCard pageCard sx={{ width: '100%', maxWidth: '100%', overflow: 'inherit' }}>
                <PaperHeader sx={{ textAlign: 'center' }}>
                  <Stack
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setHideNoneActiveTokens(!hideNoneActiveTokens)}
                    direction='row'
                    spacing={1}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Typography variant='h4'>
                      {t('userTokens.otherDevices')} (
                      {noneActiveUserTokensList && noneActiveUserTokensList.length})
                    </Typography>
                    {hideNoneActiveTokens ? (
                      <ChevronRight fontSize='large' />
                    ) : (
                      <ExpandMore fontSize='large' />
                    )}
                  </Stack>
                </PaperHeader>
                {!hideNoneActiveTokens && (
                  <PaperContent sx={{ p: isMobile ? '20px' : '30px' }}>
                    <Stack flexDirection={'row'} gap={2} flexWrap={'wrap'} sx={styledFlexContainer}>
                      {noneActiveUserTokensList}
                      {noneActiveUserTokensList?.length === 0 && (
                        <Stack
                          direction='row'
                          justifyContent='center'
                          alignItems='center'
                          width={'100%'}
                          spacing={2}
                        >
                          <Typography>
                            {t('userTokens.noOtherLoggedInDevicesDescription')}
                          </Typography>
                          <Typography>{t('userTokens.noOtherLoggedInDevices')}</Typography>
                        </Stack>
                      )}
                      {loading && (
                        <Stack flexDirection='row' justifyContent={'center'}>
                          <CircularProgress />
                        </Stack>
                      )}
                    </Stack>
                  </PaperContent>
                )}
              </PaperCard>
            )}
            <Stack
              direction={isMobile ? 'column' : 'row'}
              gap={isMobile ? 1 : 4}
              sx={{ width: '100%' }}
            >
              {!newsroomApp && (
                <PaperCard pageCard sx={{ width: '100%', overflow: 'inherit' }}>
                  <PaperHeader>
                    <Typography variant='h4'>{t('userTokens.logoutAllDevices')}</Typography>
                  </PaperHeader>
                  <PaperContent>
                    <Stack gap={2}>
                      <Typography>{t('userTokens.logoutAllDevicesDescripton')}</Typography>
                      <Button onClick={() => setModal(3)} variant={'outlined'}>
                        {t('userTokens.logoutAllDevices')}
                      </Button>
                    </Stack>
                  </PaperContent>
                </PaperCard>
              )}
              <PaperCard pageCard sx={{ overflow: 'inherit', width: '100%' }}>
                <PaperHeader>
                  <Typography variant='h4'>{t('userTokens.whatIsActiveDevices')}</Typography>
                </PaperHeader>
                <PaperContent>
                  <Typography>
                    {t('userTokens.tooltips.activeDevices', {
                      number: maxActiveLogins
                    })}
                  </Typography>
                </PaperContent>
              </PaperCard>
            </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      {modal === 0 && (
        <ConsentDialog
          title={t('userTokens.removeActiveTokenTitle')}
          content={t('userTokens.removeActiveTokenDescription')}
          confirmText={t('common.remove')}
          onCancel={() => {
            setModal(null);
            setTokenInEdit(null);
          }}
          onConfirm={removeTokenAsActiveHandler}
        />
      )}
      {modal === 1 && (
        <ConsentDialog
          title={t('userTokens.logoutDeviceTitle', {
            device: tokenInEdit?.deviceName
              ? tokenInEdit?.deviceName +
                ' ' +
                t(`userTokens.deviceType.${tokenInEdit?.deviceType}`).toLocaleLowerCase()
              : t(`userTokens.deviceType.${tokenInEdit?.deviceType}`).toLocaleLowerCase()
          })}
          content={t('userTokens.logoutDeviceDescription', t('userTokens.device2'))}
          confirmText={t('common.logout')}
          onCancel={() => {
            setModal(null);
            setTokenInEdit(null);
          }}
          onConfirm={logoutUserTokenHandler}
        />
      )}
      {modal === 2 && (
        <ConsentDialog
          title={t('userTokens.logoutCurrentDeviceTitle')}
          content={t('userTokens.logoutCurrentDeviceDescription')}
          confirmText={t('common.logout')}
          onCancel={() => {
            setModal(null);
            setTokenInEdit(null);
          }}
          onConfirm={logoutUserTokenHandler}
        />
      )}
      {modal === 3 && (
        <LogOutAllDevicesDialog
          onCancel={() => setModal(null)}
          onConfirm={() => {
            setModal(null);
            getUserTokens();
          }}
        />
      )}
    </>
  );
};

export default UserTokens;
