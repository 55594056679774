import { Box, Stack, SxProps, Typography } from '@mui/material';
import FooterLinks from 'components/FooterLinks/FooterLinks';
import useDevice from 'hooks/useDevice';
import { FC } from 'react';
import { COLORS } from 'utils/_config';
import packageInfo from '../../../package.json';

interface WindowBuildNo extends Window {
  BUILD_NUMBER: string;
}
declare const window: WindowBuildNo;

type PrimaryFooterProps = {
  sx?: SxProps;
};

const PrimaryFooter: FC<PrimaryFooterProps> = ({ sx }) => {
  const buildNo: string = window.BUILD_NUMBER;
  const device = useDevice();

  const footerStyle: SxProps = {
    background: COLORS.BACKGROUND_DARK,
    color: '#fff',
    maxWidth: '100%',
    padding: '15px',
    paddingBottom: device === 'mobile' || device === 'tablet' ? '15px' : '0',
    display: 'flex',
    justifyContent: device === 'mobile' || device === 'tablet' ? 'center' : 'flex-start',
    minHeight: '60px',
    zIndex: 1201
  };
  const linkColors = 'rgba(255,255,255,0.6)';
  return (
    <>
      <Box sx={{ ...footerStyle, ...sx }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: device === 'mobile' || device === 'tablet' ? 'center' : 'flex-start',
            flexDirection: 'column'
          }}
        >
          <a href='https://newsroom.dyplink.no/' target='_blank' rel='noreferrer'>
            <img style={{ height: '30px' }} src='/assets/newsroom-logo-white.svg' />
          </a>
          <Typography>
            <small>
              <i>
                version {packageInfo.version}.{buildNo}
              </i>
            </small>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          background: COLORS.BACKGROUND_DARK,
          color: linkColors,
          width: '100%',
          display: 'flex',
          zIndex: 1202,
          marginTop: '-1px',
          paddingBottom: '15px',
          justifyContent: 'center',
          boxSizing: 'border-box',
          ['p, a']: {
            color: linkColors,
            textDecoration: 'none',
            fontSize: '14px'
          },
          hr: {
            borderColor: linkColors
          }
        }}
      >
        {device === 'desktop' && <div style={{ width: '240px', height: '10px' }}></div>}
        <Stack
          direction={device !== 'mobile' ? 'row' : 'column'}
          gap={device === 'mobile' || device === 'tablet' ? 1.5 : 2}
          alignItems='center'
        >
          <FooterLinks />
        </Stack>
      </Box>
    </>
  );
};

export default PrimaryFooter;
