import { FC, useState } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import UserAddressForm from 'components/UserAddressForm/UserAddressForm';
import PaperContent from 'components/PaperContent/PaperContent';
import { useTranslation } from 'react-i18next';
import { Alert, Stack, Typography } from '@mui/material';

const OrderCompletionAddressForm: FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const [displayAlert, setDisplayAlert] = useState(false);

  // Display user address form if subscription has paper and user has no address
  const displayUserAddressForm = user?.userSubscription?.subscription?.paper;

  if (!displayUserAddressForm) {
    return null;
  }

  const title = user.userAddress
    ? t('userAddress.updateToReceivePaper')
    : t('userAddress.addToReceivePaper');

  return (
    <PaperCard variant='outlined' sx={{ width: '100%' }}>
      <PaperHeader>
        <Typography variant='h4'>{title}</Typography>
      </PaperHeader>
      <PaperContent>
        <Stack gap={2}>
          {displayAlert && <Alert severity='success'>{t('userAddress.updated')}</Alert>}
          <UserAddressForm
            userAddress={user.userAddress}
            onSuccess={() => {
              setDisplayAlert(true);
            }}
          />
        </Stack>
      </PaperContent>
    </PaperCard>
  );
};

export default OrderCompletionAddressForm;
