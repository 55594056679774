import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Languages
import noNb from './nb-NO.json';
import noNn from './nn-NO.json';
import en from './en.json';
import WindowConfig from 'types/WindowConfig';

const resources = {
  'nb-NO': { translation: noNb },
  'nn-NO': { translation: noNn },
  en: { translation: en }
};
declare const window: WindowConfig;

const validOrDefaultLanguage = (lang: string) => {
  const languages = Object.keys(resources);
  if(languages.includes(lang)) {
    return lang;
  } else {
    return 'nb-NO';
  }
}

export const defaultLanguage = validOrDefaultLanguage(window.CONFIG.DEFAULT_LANGUAGE);

if (window.CONFIG.LANGUAGE_BROWSER_DETECT || false) {
  i18n.use(LanguageDetector);
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false
  }
});

export const lang = [
  {
    value: 'nb-NO',
    name: 'Norwegian (Bokmål)'
  },
  {
    value: 'nn-NO',
    name: 'Norwegian (Nynorsk)'
  },
  {
    value: 'en',
    name: 'American english'
  }
];

export default i18n;
