import { SxProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type PaperContentTitleProps = {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: ReactNode;
  sx?: SxProps;
};

const PaperContentTitle: FC<PaperContentTitleProps> = ({ variant = 'h5', children, sx }) => {
  const styledPaperContentTitle: SxProps = {
    marginTop: '8px',
    marginBottom: '24px'
  };

  return (
    <Typography variant={variant} sx={{ ...styledPaperContentTitle, ...sx }}>
      {children}
    </Typography>
  );
};

export default PaperContentTitle;
