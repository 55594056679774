import Skin from 'types/Skin';

const aukranytt: Skin = {
  COLORS: {
    PRIMARY: '#04223f',
    PRIMARY_DARK: '#0085c8',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#0085c8',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://aukranytt.no/wp-content/uploads/2024/06/aukranytt-icon-round.svg',
    MAIN: 'https://aukranytt.no/wp-content/uploads/2024/05/aukranytt-3.svg',
  },
};

export default aukranytt;