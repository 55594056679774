type CountryCode =
  | 'AD'
  | 'AL'
  | 'AM'
  | 'AT'
  | 'AZ'
  | 'BA'
  | 'BE'
  | 'BG'
  | 'BY'
  | 'CH'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DK'
  | 'EE'
  | 'ES'
  | 'FI'
  | 'FO'
  | 'FR'
  | 'GB'
  | 'GE'
  | 'GG'
  | 'GI'
  | 'GR'
  | 'HR'
  | 'HU'
  | 'IE'
  | 'IM'
  | 'IS'
  | 'IT'
  | 'JE'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MK'
  | 'MT'
  | 'NL'
  | 'NO'
  | 'PL'
  | 'PT'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'SE'
  | 'SI'
  | 'SJ'
  | 'SK'
  | 'SM'
  | 'TR'
  | 'UA'
  | 'VA'
  | 'XK'
  | 'CA'
  | 'US';

export const countryCodes: CountryCode[] = [
  'AD',
  'AL',
  'AM',
  'AT',
  'AZ',
  'BA',
  'BE',
  'BG',
  'BY',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FO',
  'FR',
  'GB',
  'GE',
  'GG',
  'GI',
  'GR',
  'HR',
  'HU',
  'IE',
  'IM',
  'IS',
  'IT',
  'JE',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MD',
  'ME',
  'MK',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RS',
  'RU',
  'SE',
  'SI',
  'SJ',
  'SK',
  'SM',
  'TR',
  'UA',
  'VA',
  'XK',
  'CA',
  'US'
];

export default CountryCode;
