export const formatPhoneNumber = (cleanedValue: string | number): string => {
  if (cleanedValue === null || cleanedValue === undefined) return '';
  if (typeof cleanedValue !== 'string')
    cleanedValue = (cleanedValue as unknown as string).toString();

  if (cleanedValue.length === 0) return '';
  if (cleanedValue.length > 9) return cleanedValue.slice(0, 9); // Prevent input longer than 9 digits

  let formattedValue = cleanedValue;

  if (cleanedValue.startsWith('2') && cleanedValue.length <= 8) {
    formattedValue = cleanedValue.replace(
      /(\d{1,2})(\d{1,2})?(\d{1,2})?(\d{1,2})?/,
      (_, p1, p2, p3, p4) => {
        return [p1, p2, p3, p4].filter(Boolean).join(' ');
      }
    );
  } else if (cleanedValue.length <= 8) {
    formattedValue = cleanedValue.replace(/(\d{1,3})(\d{1,2})?(\d{1,3})?/, (_, p1, p2, p3) => {
      return [p1, p2, p3].filter(Boolean).join(' ');
    });
  } else if (cleanedValue.length === 9) {
    formattedValue = cleanedValue.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
  }

  return formattedValue;
};
