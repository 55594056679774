import axios, { AxiosPromise } from 'axios';
import NrsNotification from 'types/NrsNotification';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const notificationService = {
  getForUser: (): AxiosPromise<NrsNotification[]> => {
    return axios.get('notification/for-user');
  },
  actedOn: (id: number): AxiosPromise<NrsNotification> => {
    return axios.put(`notification/acted-on/${id}`);
  },
  dismiss: (id: number): AxiosPromise<NrsNotification> => {
    return axios.put(`notification/dismiss/${id}`);
  }
};

export default notificationService;
