import axios from 'axios';
import Setting from 'types/Setting';

const settingService = {
  getInitSettings: () => {
    return axios.get<Setting[]>(`setting/public-initial/`);
  },
  getPublicByKey: (key: string) => {
    return axios.get<Setting>(`setting/public/${key}`);
  }
};

export default settingService;
