import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Alert from 'types/Alert';
import { uniqeId } from 'utils';

const defaultAlert: Alert = {
  id: uniqeId(),
  type: 'success',
  text: '',
  timeout: 5000
};

const initialState = [] as Alert[];

export const alertReducer = createSlice({
  name: 'alertReducer',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<Alert>) => {
      if (
        action.payload.text === 'Network error' &&
        state.find((a) => a.text === 'Network error')
      ) {
        return state;
      }

      const alert = { ...defaultAlert, ...action.payload };
      state.push(alert);
    },
    removeAlert: (state, { payload: alertId }: PayloadAction<Alert>) => {
      const newState = state.filter((alert) => {
        return alert.id !== alertId;
      });
      return newState;
    },
    clear: () => {
      return [];
    }
  }
});

export const { actions } = alertReducer;

export default alertReducer;
