import Skin from 'types/Skin';

const folldalsportalen: Skin = {
  COLORS: {
    PRIMARY: '#228518',
    PRIMARY_DARK: '#224121',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#228518',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://www.folldalsportalen.no/wp-content/uploads/sites/4/2021/03/folldalsportalen-inverted-logo-800.png',
    MAIN: 'https://www.folldalsportalen.no/wp-content/uploads/sites/4/2023/10/folldalsportalen-white.png'
  },
};

export default folldalsportalen;