import axios, { AxiosPromise } from 'axios';
import Payment from 'types/Payment';

const paymentService = {
  getUserPayments: (): AxiosPromise<Payment[]> => {
    return axios.get('payment/current-user');
  },
  getByIdCurrentUser: (paymentId: number): AxiosPromise<Payment> => {
    return axios.get(`payment/current-user/${paymentId}`);
  }
};

export default paymentService;
