import {
  CircularProgress,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { PageContainer } from 'components';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import PaymentRow from 'privatePages/Payments/components/PaymentRow';
import useAlert from 'hooks/useAlert';
import logger from 'logger/logger';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import paymentService from 'services/payment.service';
import theme from 'styles/theme';
import Payment from 'types/Payment';

const Payments: FC = () => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [hasInvoice, setHasInvoice] = useState<boolean>(false);
  const { t } = useTranslation();
  const { addAlert } = useAlert();

  const getPayments = async () => {
    setLoading(true);
    try {
      // Get payments
      const { data = [] } = await paymentService.getUserPayments();

      // Filter out payments with status DUE that has a fromDate in the future
      const filteredData = data.filter((p) => {
        if (p.status === 'DUE' && p.fromDate) {
          const fromDate = new Date(p.fromDate);
          const today = new Date();
          return fromDate <= today;
        }
        return true;
      });

      const invoice = filteredData.find((p) => p.paymentSolution === 'invoice');
      setHasInvoice(invoice !== null);
      filteredData.reverse();

      // Sort by newest first
      setPayments([...filteredData].sort((a, b) => (a.created > b.created ? -1 : 1)));
    } catch (error) {
      logger.error('Get payments failed', error);
      addAlert('error', t('alerts.somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  const paymentRows = payments.map((payment, index) => (
    <PaymentRow key={index} payment={payment} />
  ));

  useEffect(() => {
    getPayments();
  }, []);

  const styledPaymentCard: SxProps = {
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginTop: '32px'
    }
  };

  const styledPaymentContentCard: SxProps = {
    p: 0,
    overflowX: 'auto',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0px',
      width: '100%'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '800px'
    }
  };

  const styledPageContainer: SxProps = {
    [theme.breakpoints.down('md')]: {
      minHeight: '80vh'
    }
  };

  return (
    <PageContainer sx={styledPageContainer} centerHorizontal pageCard  pageCardBreakpoint='md' >
      <PaperCard sx={styledPaymentCard} pageCard pageCardBreakpoint='md' >
        <PaperHeader>
          <Typography variant='h4'>{t('payment.payments')}</Typography>
        </PaperHeader>
        <PaperContent sx={styledPaymentContentCard}>
          <Table
            sx={{
              width: '100%'
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>
                    {t('common.receipt')}
                    {hasInvoice && ' / ' + t('common.invoice')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.created')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('payment.orderType')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('payment.servicePeriod')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.status')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('common.price')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{t('payment.solution')}</Typography>
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{paymentRows}</TableBody>
          </Table>
          {payments.length === 0 && !loading && (
            <Stack p={'16px'} justifyContent='center' direction={'row'}>
              <Typography>{t('payment.alerts.cantFindAnyPayments')}</Typography>
            </Stack>
          )}
          {loading && (
            <Stack p={'16px'} justifyContent='center' direction={'row'}>
              <CircularProgress />
            </Stack>
          )}
        </PaperContent>
      </PaperCard>
    </PageContainer>
  );
};

export default Payments;
