import { useEffect, useState } from 'react';
import theme from 'styles/theme';

type Device = 'tablet' | 'mobile' | 'desktop';

const useDevice = () => {
  const [device, setDevice] = useState<Device>(getDevice());

  function getDevice(): Device {
    if (window.innerWidth < theme.breakpoints.values.md) {
      return 'mobile';
    } else if (
      window.innerWidth > theme.breakpoints.values.md &&
      window.innerWidth < theme.breakpoints.values.lg
    ) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }

  useEffect(() => {
    const resizeW = () => setDevice(getDevice());

    window.addEventListener('resize', resizeW); // Update the width on resize

    return () => window.removeEventListener('resize', resizeW);
  });

  return device;
};

export default useDevice;
