import Skin from 'types/Skin';

const avisahemnes: Skin = {
  COLORS: {
    PRIMARY: '#164193',
    PRIMARY_DARK: '#1c3159',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#164193',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://dyplink.no/wp-content/uploads/2024/01/avisahemnes-icon.jpg',
    MAIN: 'https://dyplink.no/wp-content/uploads/2024/01/Avisa-Hemnes-logo-white.png',
  },
};

export default avisahemnes;