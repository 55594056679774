import { AxiosError } from 'axios';
import logger from 'logger/logger';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import settingService from 'services/setting-service';
import { RootState, settingsActions } from 'store';
import useAxiosAlert from './useAxiosAlert';

const useSettings = () => {
  const settings = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();
  const axiosAlert = useAxiosAlert();
  const { t } = useTranslation();

  const syncSettings = async () => {
    try {
      const { data: initSettings } = await settingService.getInitSettings();
      const platformTitle = initSettings.find((s) => s.key === 'PlatformTitle')?.value;

      if (platformTitle) {
        document.title = t('common.myPage') + ' - ' + platformTitle;
      }

      dispatch(settingsActions.set(initSettings));
      return initSettings;
    } catch (error) {
      logger.error('Get init settings failed', error);
      axiosAlert(error as AxiosError);
    }
  };

  function getValueByKey(key: string, defaultValue?: string): string {
    return settings.find((s) => s.key === key)?.value || defaultValue;
  }

  return { settings, syncSettings, getValueByKey };
};

export default useSettings;
