import { Box, SxProps } from '@mui/material';
import React, { FC, ReactNode } from 'react';

type PaperHeaderProps = {
    children: ReactNode;
    sx?: SxProps;
};

const PaperHeader: FC<PaperHeaderProps> = ({ children, sx }) => {
  return (
    <Box
      sx={{
        padding: '16px',
        borderBottom: '1px solid #efefef',
        textAlign: 'center',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default PaperHeader;
