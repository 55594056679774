import Skin from 'types/Skin';

const hustadvikingen: Skin = {
  COLORS: {
    PRIMARY: '#333333',
    PRIMARY_DARK: '#0d5943',
    PRIMARY_CONTRAST: '#ffffff',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff',
    BACKGROUND_DARK: '#008d65',
    BACKGROUND_LIGHT: '#eee',
  },
  LOGO: {
    ICON: 'https://hustadvikingen.no/wp-content/uploads/2023/06/icon.svg',
    MAIN: 'https://hustadvikingen.no/wp-content/uploads/2023/05/logo-light.svg',
  },
};

export default hustadvikingen;