import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

type FlexBoxProps = {
  sx?: SxProps;
  children?: ReactNode;
  justifyContent?: 'space-between' | 'center' | 'flex-start' | 'flex-end';
  alignItems?: 'flex-start' | 'flex-end' | 'center';
  gap?: number;
  direction?: 'column' | 'row';
};

const FlexBox: FC<FlexBoxProps> = ({
  sx,
  children,
  justifyContent = 'center',
  alignItems = 'flex-start',
  gap,
  direction = 'row'
}) => {
  const flexBoxStyles: SxProps = {
    display: 'flex',
    justifyContent,
    alignItems,
    flexDirection: direction,
    gap: `${gap}px` || '5px'
  };

  return <Box sx={{ ...flexBoxStyles, ...sx }}>{children}</Box>;
};

export default FlexBox;
