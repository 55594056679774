import userReducer, { actions as userActions } from './reducers/userReducer';
import alertReducer, { actions as alertActions } from './reducers/alertReducer';
import settingsReducer, { actions as settingsActions } from './reducers/settingsReducer';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import redirectingReducer, { actions as redirectingActions } from './reducers/redirectingReducer';
import newsroomAppReducer, { actions as newsroomAppActions } from './reducers/newsroomAppReducer';

const store = configureStore({
  reducer: {
    user: userReducer.reducer,
    alert: alertReducer.reducer,
    settings: settingsReducer.reducer,
    redirecting: redirectingReducer.reducer,
    newsroomApp: newsroomAppReducer.reducer
  },
  middleware: [thunk]
});

export default store;

export { userActions, alertActions, settingsActions, redirectingActions, newsroomAppActions };

export type RootState = ReturnType<typeof store.getState>;
