/* eslint-disable indent */
import { FC, useEffect, useState } from 'react';
import { PageContainer } from 'components';
import { useNavigate, useLocation, useParams } from 'react-router';
import routes from 'navigation/routes';
import { CircularProgress, Stack, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import OrderSubscriptionState from 'types/OrderSubscriptionState';
import Subscription from 'types/Subscription';
import theme from 'styles/theme';
import orderService from 'services/order.service';

import { useSearchParams } from 'react-router-dom';
import PaperCard from 'components/PaperCard/PaperCard';
import LogoPaperHeader from 'components/LogoPaperHeader/LogoPaperHeader';
import logger from 'logger/logger';
import SplashScreen from 'components/SplashScreen/SplashScreen';
import useUser from 'hooks/useUser';
import GiftSubscriptionPaymentContent from './components/GiftSubscriptionPaymentContent';
import Order, { OrderData } from 'types/Order';
import GiftSubscriptionTimedOut from './components/GiftSubscriptionTimedOut';
import GiftInfo from 'types/GiftInfo';
import getRoutePath from 'services/getRoutePath';
import Alert from 'types/Alert';
import subscriptionService from 'services/subscription.service';

const GiftSubscriptionPayment: FC = () => {
  // Hooks
  const { t } = useTranslation();

  // Nav
  const locationState = useLocation().state as OrderSubscriptionState;
  const [searchParams] = useSearchParams();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const returnUrl = searchParams.get('returnUrl') || locationState?.returnUrl;

  // State
  const [order, setOrder] = useState<Order>();
  const [subscription, setSubscription] = useState<Subscription>();
  const [disableNonSelected, setDisableNoneSelceted] = useState<boolean>();
  const [giftInfo, setGiftInfo] = useState<GiftInfo>();

  const [loading, setLoading] = useState<boolean>();
  const [timedOut, setTimedOut] = useState<boolean>();

  // Store
  const user = useSelector((state: RootState) => state.user);
  const { fetchUser } = useUser();

  const validateGiftInfo = (data: OrderData) => {
    if (data && data.name && data.email && data.phoneNumber && data.from) {
      return true;
    }

    return false;
  };

  const init = async () => {
    setLoading(true);

    try {
      // Fetches and updates the current user
      await fetchUser();

      // Get order
      const {
        data: { order: orderData, hasExpired }
      } = await orderService.getByUuid(uuid);

      const { data: _subscription } = await subscriptionService.getSubscription(
        orderData.subscriptionId
      );

      // Set subscription
      setSubscription(_subscription);

      // Check if order has expired
      if (hasExpired) {
        setTimedOut(true);
        setOrder(orderData);
        return;
      }

      if (orderData.status === 'COMPLETED' || orderData.status === 'CANCELED') {
        const routePath = getRoutePath(routes.ORDER_CONFIRMATION.path, 'uuid', orderData.uuid);
        navigate(`${routePath}${returnUrl ? '?returnUrl=' + returnUrl : ''}`);
      }

      if (!validateGiftInfo(orderData.data)) {
        navigate(getRoutePath(routes.ORDER_CONFIRMATION.path, 'uuid', orderData.uuid), {
          state: {
            alert: { type: 'error', text: t('giftSubscription.error.invalidGiftInfo') } as Alert
          }
        });
      }

      // Set gift info
      const _giftInfo = {
        name: orderData.data.name,
        email: orderData.data.email,
        phoneNumber: orderData.data.phoneNumber,
        from: orderData.data.from
      } as GiftInfo;

      setGiftInfo(_giftInfo);

      // Set order
      setOrder(orderData);
    } catch (error) {
      logger.error('Init order subcription failed', error);
    } finally {
      setLoading(false);
    }
  };

  // Disables most inputs if redirected back from Swedbank
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (disableNonSelected) {
      timeout = setTimeout(() => {
        setDisableNoneSelceted(false);
      }, 1000 * 15);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [disableNonSelected]);

  useEffect(() => {
    if (!uuid) {
      navigate(routes.GIFT_SUBSCRIPTION.path);
    }

    if (!user) {
      navigate(routes.LOGIN.path);
    }

    init();
  }, []);

  const styledPageContainer: SxProps = {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      minHeight: '100%'
    }
  };

  return order ? (
    <>
      <PageContainer centerHorizontal centerVertical sx={styledPageContainer} pageCard>
        <PaperCard pageCard>
          <LogoPaperHeader />
          {!timedOut && !loading && giftInfo && (
            <GiftSubscriptionPaymentContent
              giftInfo={giftInfo}
              order={order}
              subscription={subscription}
            />
          )}
          {timedOut && !loading && <GiftSubscriptionTimedOut uuid={order.uuid} />}
          {loading && (
            <Stack flexDirection={'row'} padding={'16px'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>
          )}
        </PaperCard>
      </PageContainer>
    </>
  ) : (
    <SplashScreen />
  );
};

export default GiftSubscriptionPayment;
