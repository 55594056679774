import { useEffect } from 'react';

type params = {
  url: string;
  onLoadCb?: (url: string) => void;
};

const useScript = ({ url, onLoadCb }: params) => {
  const scriptOnLoad = (cb: () => void) => {
    cb();
  };

  useEffect(() => {
    if (url) {
      const script = document.createElement('script');

      script.src = url;
      script.async = true;

      document.body.appendChild(script);

      if (onLoadCb) {
        script.onload = () => {
          onLoadCb(url);
        };
      }

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [url]);

  return { scriptOnLoad };
};

export default useScript;
