import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { AxiosError } from 'axios';
import { PageContainer } from 'components';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog';
import LogoPaperHeader from 'components/LogoPaperHeader/LogoPaperHeader';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import useAxiosAlert from 'hooks/useAxiosAlert';
import routes from 'navigation/routes';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import orderService from 'services/order.service';
import getRoutePath from 'services/getRoutePath';
import theme from 'styles/theme';
import GiftInfo from 'types/GiftInfo';
import { NewOrder } from 'types/Order';
import GiftSubscriptionForm from './components/GiftSubscriptionForm';
import GiftSubscriptionInformation from './components/GiftSubscriptionInformation';

type GiftSubscriptionFormFields = {
  name: string;
  email: string;
  phoneNumber: string;
  selectedSubscriptionId?: number | string;
  message: string;
  from: string;
};

const GiftSubscription: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<number>(1);
  const axiosAlert = useAxiosAlert();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [existingFields, setExistingFields] = useState<GiftSubscriptionFormFields>();

  const styledPaperCard: SxProps = {
    maxWidth: '800px',
    width: '100%'
  };

  const styledPaperContent: SxProps = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px'
    }
  };

  const init = async () => {
    try {
      const {
        data: { order }
      } = await orderService.getByUuid(uuid);

      const fields: GiftSubscriptionFormFields = {
        name: order?.data?.name,
        email: order?.data?.email,
        phoneNumber: order?.data?.phoneNumber,
        from: order?.data?.from,
        message: order?.data?.message
      };

      setExistingFields(fields);
    } catch (error) {
      console.error('Something went wrong', error);
    } finally {
      setLoading(null);
    }
  };

  useEffect(() => {
    if (uuid) {
      init();
    } else {
      setLoading(null);
      setExistingFields(null);
    }
  }, []);

  const createOrder = async (fields: GiftSubscriptionFormFields) => {
    const giftInfo: GiftInfo = {
      name: fields.name,
      email: fields.email,
      phoneNumber: fields.phoneNumber,
      from: fields.from,
      message: fields.message
    };

    setLoading(2);

    try {
      const newOrder: NewOrder = {
        type: 'GIFT_SUBSCRIPTION',
        subscriptionId: parseInt(fields.selectedSubscriptionId as string),
        paymentSolution: 'swedbank',
        data: giftInfo
      };

      const { data: order } = await orderService.add(newOrder);

      navigate(getRoutePath(routes.GIFT_SUBSCRIPTION_PAYMENT.path, 'uuid', order.uuid));
    } catch (error) {
      axiosAlert(error as AxiosError, [
        {
          status: 403,
          message: t('giftSubscription.error.alreadyHasSubscription')
        }
      ]);
    } finally {
      setLoading(null);
    }
  };

  return (
    <>
      <PageContainer pageCard centerVertical centerHorizontal>
        <PaperCard pageCard sx={styledPaperCard}>
          <LogoPaperHeader />
          <PaperContent sx={styledPaperContent}>
            <Stack gap={2}>
              <Typography sx={{ mb: 2 }} variant={'h4'}>
                {t('giftSubscription.title')}
              </Typography>
              <GiftSubscriptionInformation />
              {(existingFields || existingFields === null) && (
                <GiftSubscriptionForm onSave={createOrder} existingFields={existingFields} />
              )}
            </Stack>
          </PaperContent>
        </PaperCard>
      </PageContainer>
      {loading === 1 && <LoadingDialog title={t('giftSubscription.gettingOrder')} />}
      {loading === 2 && <LoadingDialog title={t('giftSubscription.creatingOrder')} />}
    </>
  );
};

export default GiftSubscription;
