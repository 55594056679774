import axios, { AxiosPromise } from 'axios';
import Campaign from 'types/Campaign';

const campaignService = {
  get: () => {
    return axios.get('/campaign/active');
  },
  getCampaign: (uuid: string): AxiosPromise<Campaign> => {
    return axios.get(`/campaign/${uuid}`);
  },
  getByOrderId: (orderId: number): AxiosPromise<Campaign> => {
    return axios.get(`/campaign/order/${orderId}`);
  }
};

export default campaignService;
