import { SxProps } from '@mui/system';
import theme from 'styles/theme';

const styles: { [k: string]: SxProps } = {
  styledPageContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  styledPaperCard: {
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      maxWidth: '1600px'
    },

    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      width: '100%',
      height: '100%',
      paddingBottom: '20px',
      flex: 1
    }
  },
  styledPaperContent: {
    p: '40px',
    paddingTop: '20px',
    minHeight: '30vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '120px',
      paddingRight: '120px'
    },

    [theme.breakpoints.down('sm')]: {
      p: '12px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  styledTitle: {
    marginBottom: '24px',
    textAlign: 'center',
    marginTop: '16px',
    fontWeight: '400'
  }
};

export default styles;
