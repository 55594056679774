import { CircularProgress, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const RedirectToNewspaperDialog: FC = () => {
  const { t } = useTranslation();

  return (
    <Dialog open={true}>
      <DialogContent sx={{ p: '32px' }}>
        <Stack gap={4} direction={'column'} alignItems={'center'}>
          <Typography variant='h5'>{t('navigation.redirectToNewspaperDialogText')}</Typography>
          <CircularProgress />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default RedirectToNewspaperDialog;
