import useQuery from 'hooks/useQuery';
import useReturnUrl from 'hooks/useRedirect';
import useUser from 'hooks/useUser';
import routes from 'navigation/routes';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { RootState } from 'store';

const Authenticate: FC = () => {
  const query = useQuery();
  const { signOut } = useUser();
  const { setReturnUrl, handleSignOutRedirect, handleNewspaperRedirect } = useReturnUrl();
  const user = useSelector((state: RootState) => state.user);

  const returnUrlParam = query.get('returnUrl');
  const forceRouteParam = query.get('forceRoute');
  const signOutParam = query.get('signOut');

  const handleSignOut = async () => {
    await signOut();
    handleSignOutRedirect();
  }

  if (returnUrlParam) {
    setReturnUrl(returnUrlParam);
  }

  if (signOutParam !== null) {
    handleSignOut();
    return null;
  }

  if (returnUrlParam && user) {
    handleNewspaperRedirect();

    return null;
  }

  if (!user) {
    // If forceRoute, force user redirect even if not logged in.
    if (forceRouteParam && returnUrlParam) {
      window.location.href = returnUrlParam;
      return null;
    }

    return <Navigate to={routes.LOGIN.path} />;
  }

  if (user) {
    return <Navigate to={routes.PROFILE.path} />;
  }

  return null;
};

export default Authenticate;
