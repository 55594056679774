/* eslint-disable indent */
import './OrderPaymentPage.scss';
import { FC, useEffect, useState } from 'react';
import { PageContainer, PaymentPicker } from 'components';
import SelectedSubscriptionPayment from './components/SelectedSubscriptionPayment';
import { useNavigate, useLocation, useParams } from 'react-router';
import routes from 'navigation/routes';
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import OrderSubscriptionState from 'types/OrderSubscriptionState';
import Subscription from 'types/Subscription';
import Order, { OrderType } from 'types/Order';
import PaperContent from 'components/PaperContent/PaperContent';
import theme from 'styles/theme';
import FlexBox from 'components/FlexBox/FlexBox';
import PaymentSolution from 'types/PaymentSolution';
import orderService from 'services/order.service';
import Campaign from 'types/Campaign';
import SubscriptionTermsOfServiceConsent from 'components/SubscriptionTermsOfServiceConsent/SubscriptionTermsOfServiceConsent';
import campaignService from 'services/campaign.service';
import subscriptionService from 'services/subscription.service';
import AlertType from 'types/Alert';

import { useSearchParams } from 'react-router-dom';
import PaperCard from 'components/PaperCard/PaperCard';
import LogoPaperHeader from 'components/LogoPaperHeader/LogoPaperHeader';
import logger from 'logger/logger';
import useSettings from 'hooks/useSettings';

import SplashScreen from 'components/SplashScreen/SplashScreen';
import useUser from 'hooks/useUser';

type OrderProps = {
  changePaymentSolution?: boolean;
};

const OrderPayment: FC<OrderProps> = ({ changePaymentSolution }) => {
  // Hooks
  const { getValueByKey } = useSettings();

  const usingVipps = parseInt(getValueByKey('PaymentSolutionVipps', '1')) ? true : false;
  const usingSwedbank = parseInt(getValueByKey('PaymentSolutionSwedbank', '1')) ? true : false;
  const orderType: OrderType = changePaymentSolution ? 'CHANGE_PAYMENT_SOLUTION' : 'SUBSCRIPTION';
  const { t } = useTranslation();

  // Nav
  const locationState = useLocation().state as OrderSubscriptionState;
  const [searchParams] = useSearchParams();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const returnUrl = searchParams.get('returnUrl') || locationState?.returnUrl;

  // State
  const [order, setOrder] = useState<Order>();
  const [termsOfServiceConsented, setTermsOfServiceConsented] = useState<boolean>();
  const [subscription, setSubscription] = useState<Subscription>();
  const [campaign, setCampaign] = useState<Campaign>();
  const [disableNonSelected, setDisableNoneSelceted] = useState<boolean>();
  const [selectedPaymentSolution, setSelectedPaymentSolution] = useState<PaymentSolution>(
    usingVipps ? 'vipps' : 'swedbank'
  );
  const [loading, setLoading] = useState<boolean>();
  const [timedOut, setTimedOut] = useState<boolean>();

  // Store
  const { userSubscription } = useSelector((state: RootState) => state.user);
  const user = useSelector((state: RootState) => state.user);
  const { fetchUser } = useUser();

  const init = async () => {
    setLoading(true);

    try {
      // Fetches and updates the current user
      const updatedUser = await fetchUser();

      if (updatedUser.hasActiveUserSubscription && orderType === 'SUBSCRIPTION') {
        navigate(routes.PROFILE.path, {
          state: {
            alert: { type: 'info', text: t('alerts.alreadyActiveSubscription') } as AlertType
          }
        });
      }

      // Get order
      const {
        data: { order: orderData, hasExpired }
      } = await orderService.getByUuid(uuid);

      // Check if order has expired
      if (hasExpired) {
        setTimedOut(true);
        setOrder(orderData);
        return;
      }

      if (orderData.status === 'COMPLETED' || orderData.status === 'CANCELED') {
        navigate(
          `${routes.ORDER_CONFIRMATION.path.replace(':uuid', orderData.uuid.toString())}${
            returnUrl ? '?returnUrl=' + returnUrl : ''
          }`
        );
      }

      if (orderType === 'CHANGE_PAYMENT_SOLUTION') {
        // Change payment solution
        initChangePaymentSolution(orderData);
      } else {
        // Order subscription
        initOrderSubcription(orderData);
      }
    } catch (error) {
      logger.error('Init order subcription failed', error);
    } finally {
      setLoading(false);
    }
  };

  const initOrderSubcription = async (orderData: Order) => {
    try {
      // Handle existing paymentsolution
      if (orderData.paymentSolution) {
        if (orderData.paymentSolution === 'vipps') {
          await handleExistingVippsOrder(orderData);
        }

        setTermsOfServiceConsented(true);
        setSelectedPaymentSolution(orderData.paymentSolution);
      }

      // Get campaign from order
      if (orderData?.campaignId) {
        const { data: campaignData } = await campaignService.getByOrderId(orderData.id);
        setCampaign(campaignData);
      }

      // Get subscription from order
      const { data: subscriptionData } = await subscriptionService.getSubscription(
        orderData.subscriptionId
      );

      setSubscription(subscriptionData);

      if (orderData?.orderId && orderData?.paymentSolution === 'swedbank') {
        setDisableNoneSelceted(true);
      }

      setOrder(orderData);
    } catch (error) {
      logger.error('Init order subcription failed', error);
      throw error;
    }
  };

  const initChangePaymentSolution = async (orderData: Order) => {
    try {
      setTermsOfServiceConsented(true);
      if (orderData.paymentSolution) {
        // Handle existing paymentsolution

        if (orderData.paymentSolution) {
          if (orderData.paymentSolution === 'vipps') {
            await handleExistingVippsOrder(orderData);
          }

          setSelectedPaymentSolution(orderData.paymentSolution);
        }

        setSelectedPaymentSolution(orderData.paymentSolution);
      }

      setOrder(orderData);
    } catch (error) {
      logger.error('Init change payment solution failed', error);
      throw error;
    }
  };

  const handleExistingVippsOrder = async (orderData: Order) => {
    try {
      const { data: vippsAgreementStatusData } = await orderService.getVippsAgreementStatus(uuid);
      const { vippsAgreementStatus } = vippsAgreementStatusData;

      // If agreement status is other than pending,
      // Send the user to order confirmation page

      if (vippsAgreementStatus !== 'PENDING') {
        navigate(
          `${routes.ORDER_CONFIRMATION.path.replace(':uuid', uuid.toString())}${
            returnUrl ? '?returnUrl=' + returnUrl : ''
          }`
        );
      } else {
        // If vipps order is not completed then
        // Send user back to vipps-confirmation-site

        // Get vipps confirmation url
        if (orderData?.data && orderData?.data?.vippsConfirmationUrl) {
          setDisableNoneSelceted(true);
          window.location.href = orderData?.data?.vippsConfirmationUrl;
        }
      }
    } catch (error) {
      navigate(
        `${routes.ORDER_CONFIRMATION.path.replace(':uuid', uuid.toString())}${
          returnUrl ? '?returnUrl=' + returnUrl : ''
        }`
      );
    }
  };

  // Disables most inputs if redirected back from Swedbank
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (disableNonSelected && selectedPaymentSolution === 'swedbank') {
      timeout = setTimeout(() => {
        setDisableNoneSelceted(false);
      }, 1000 * 15);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [disableNonSelected]);

  useEffect(() => {
    if (!uuid) {
      navigate(routes.ORDER_SUBSCRIPTION_SELECT.path);
    }

    if (!uuid && changePaymentSolution) {
      navigate(routes.PROFILE.path);
    }

    if (!user) {
      navigate(routes.LOGIN.path);
    }

    init();
  }, []);

  useEffect(() => {
    if (userSubscription?.subscription && changePaymentSolution) {
      setSubscription(userSubscription.subscription);
    }
  }, []);

  const styledPageContainer: SxProps = {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      minHeight: '100%'
    }
  };

  return order ? (
    <>
      <PageContainer centerHorizontal centerVertical sx={styledPageContainer} pageCard>
        <PaperCard pageCard>
          <LogoPaperHeader />
          {!timedOut && !loading && (
            <PaperContent
              sx={{
                padding: '16px',

                [theme.breakpoints.down('sm')]: {
                  borderRadius: 0,
                  marginBottom: '100px'
                }
              }}
            >
              <FlexBox gap={16} direction={'column'} sx={{ width: '100%' }}>
                <Typography variant='h4' fontWeight={'500'}>
                  {orderType === 'CHANGE_PAYMENT_SOLUTION' && t('payment.changePaymentSolution')}
                  {orderType === 'SUBSCRIPTION' && t('common.completeOrder')}
                </Typography>
                {changePaymentSolution && (
                  <>
                    <Typography variant='h6'>
                      {t('payment.changePaymentSolutionDescription', {
                        subscriptionName: subscription?.name
                      })}
                    </Typography>
                    <Divider
                      sx={{
                        width: '100%',
                        marginBottom: orderType === 'CHANGE_PAYMENT_SOLUTION' ? '16px' : '32px'
                      }}
                    />
                  </>
                )}
                {order.type === 'SUBSCRIPTION' && (
                  <>
                    <SelectedSubscriptionPayment
                      subscription={subscription}
                      campaign={campaign}
                      editDisabled={disableNonSelected}
                    />
                    {!termsOfServiceConsented &&
                      !order?.paymentSolution &&
                      !changePaymentSolution && (
                        <SubscriptionTermsOfServiceConsent
                          onComplete={() => {
                            setTermsOfServiceConsented(true);
                          }}
                        />
                      )}
                    {termsOfServiceConsented && (
                      <>
                        <Divider sx={{ width: '100%' }} />
                        <Typography
                          sx={{ marginBottom: '40px', fontWeight: '400' }}
                          textAlign={'right'}
                          width='100%'
                          variant='h6'
                        >{`${t('common.total')}: ${order.price} kr (${t(
                          'payment.includedVat'
                        )})`}</Typography>
                      </>
                    )}
                  </>
                )}
                {termsOfServiceConsented && (
                  <>
                    <Typography variant='h5' fontWeight={'500'}>
                      {orderType === 'CHANGE_PAYMENT_SOLUTION' &&
                        t('payment.chooseNewPaymentSolution')}
                      {orderType === 'SUBSCRIPTION' && t('payment.choosePaymentSolution')}
                    </Typography>
                    <PaymentPicker
                      onRedirect={() => {
                        setDisableNoneSelceted(true);
                      }}
                      usingVipps={usingVipps}
                      usingSwedbank={usingSwedbank}
                      returnUrl={returnUrl}
                      order={order}
                      onChange={setSelectedPaymentSolution}
                      selectedPaymentSolution={selectedPaymentSolution}
                      subscription={subscription}
                      campaign={campaign}
                      user={user}
                      disableNonSelected={disableNonSelected}
                    />
                  </>
                )}
              </FlexBox>
            </PaperContent>
          )}
          {timedOut && !loading && (
            <PaperContent>
              <FlexBox direction='column' gap={16}>
                <Alert sx={{ width: '100%', boxSizing: 'border-box' }} severity='error'>
                  {t('payment.alerts.timedOut')}
                </Alert>
                {(changePaymentSolution && (
                  <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => navigate(routes.PROFILE.path)}
                  >
                    {t('navigation.backToMyPage')}
                  </Button>
                )) || (
                  <Button
                    fullWidth
                    variant='outlined'
                    onClick={() => navigate(routes.ORDER_SUBSCRIPTION_SELECT.path)}
                  >
                    {t('navigation.backToOrderSubscriptionPage')}
                  </Button>
                )}
              </FlexBox>
            </PaperContent>
          )}

          {loading && (
            <Stack flexDirection={'row'} padding={'16px'} justifyContent={'center'}>
              <CircularProgress />
            </Stack>
          )}
        </PaperCard>
      </PageContainer>
    </>
  ) : (
    <SplashScreen />
  );
};

export default OrderPayment;
