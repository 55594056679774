import { CheckCircle } from '@mui/icons-material';
import { CircularProgress, Stack, Typography } from '@mui/material';
import PaperCard from 'components/PaperCard/PaperCard';
import PaperContent from 'components/PaperContent/PaperContent';
import PaperHeader from 'components/PaperHeader/PaperHeader';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';

type OrderCompletedInfoProps = {
  orderType: string;
  loading?: boolean;
};

const OrderCompletedInfo: FC<OrderCompletedInfoProps> = ({ orderType, loading }) => {
  const { t } = useTranslation();

  const getTitle = () => {
    // Change the above to a switch statement and include giftSubscription
    let title = '';
    switch (orderType) {
      case 'SUBSCRIPTION':
        title = t('orderCompletion.subscriptionTitle');
        break;
      case 'CHANGE_PAYMENT_SOLUTION':
        title = t('orderCompletion.changePaymentSolutionDescription');
        break;
      case 'GIFT_SUBSCRIPTION':
        title = t('orderCompletion.giftSubscriptionTitle');
        break;
      default:
        break;
    }

    return title;
  };

  const getMessage = () => {
    let message = '';
    switch (orderType) {
      case 'SUBSCRIPTION':
        message = t('payment.alerts.orderCompleted');
        break;
      case 'CHANGE_PAYMENT_SOLUTION':
        message = t('payment.alerts.changePaymentSolutionOrderCompleted');
        break;
      case 'GIFT_SUBSCRIPTION':
        message = t('payment.alerts.giftSubscriptionOrderCompleted');
        break;
      default:
        break;
    }

    return message;
  };

  const title = getTitle();
  const message = getMessage();

  return (
    <PaperCard
      variant='outlined'
      sx={{
        width: '100%'
      }}
    >
      <PaperHeader>
        <Typography variant='h4'>{title}</Typography>
      </PaperHeader>
      <PaperContent>
        {!loading && (
          <Stack gap={2} alignItems={'center'}>
            <CheckCircle sx={{ fontSize: '5rem', color: theme.palette.success.main }} />
            <Typography variant={'h6'} textAlign={'center'}>{message}</Typography>
          </Stack>
        )}
        {loading && (
          <Stack direction={'row'} justifyContent={'center'}>
            <CircularProgress />
          </Stack>
        )}
      </PaperContent>
    </PaperCard>
  );
};

export default OrderCompletedInfo;
