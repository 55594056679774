import { createTheme } from '@mui/material';
import { COLORS } from 'skin';

const defaultTheme = createTheme();
const theme = createTheme({
  palette: {
    primary: { main: COLORS.PRIMARY },
    secondary: { main: COLORS.SECONDARY }
  },
  typography: {
    fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),
    fontWeightRegular: 300,
    fontWeightMedium: 300,
    fontWeightBold: 400,
    fontSize: 14,
    h4: {
      fontSize: '1.8rem'
    }
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          fontWeight: 400
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '20px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '50px'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: 100002
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 100001,
          ['.MuiPaper-root']: {
            margin: '16px',
            [defaultTheme.breakpoints.down('sm')]: {
              margin: '8px'
            }
          },
          ['.MuiDialogContent-root']: {
            boxSizing: 'border-box',
            [defaultTheme.breakpoints.down('sm')]: {
              width: '100%'
            }
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 100001,
          ['.MuiPaper-root']: {
            margin: '16px',
            [defaultTheme.breakpoints.down('sm')]: {
              margin: '8px'
            }
          },
          ['.MuiDialogContent-root']: {
            boxSizing: 'border-box',
            minWidth: '300px',
            [defaultTheme.breakpoints.down('sm')]: {
              width: '100%'
            }
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          ['.MuiToolbar-root']: {
            paddingLeft: 0
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          ['& .MuiPaper-root']: {
            margin: 0
          }
        }
      }
    }
  }
});

export default theme;
