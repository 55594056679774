import axios, { AxiosPromise } from 'axios';
import UserAddress from 'types/UserAddress';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const userAddressService = {
  getUserAddresse: () => {
    return axios.get('user/address/current-user');
  },
  updateUserAddress: (userAddress: UserAddress): AxiosPromise<UserAddress> => {
    return axios.put('user/address/current-user', userAddress);
  },
  createUserAddress: (userAddress: UserAddress): AxiosPromise<UserAddress> => {
    return axios.post('user/address/current-user', userAddress);
  }
};

export default userAddressService;
