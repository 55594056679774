import { Dashboard } from 'components';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import FaqContent from './components/FaqContent';

const FaqPage: FC = () => {
  const { user } = useSelector((state: RootState) => state);
  // If logged in, show the dashboard
  if (user) {
    return (
      <Dashboard>
        <FaqContent />
      </Dashboard>
    );
  } else {
    return <FaqContent />;
  }
};

export default FaqPage;
