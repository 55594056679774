import './PaperCard.scss';
import { Paper, SxProps } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import theme from 'styles/theme';

type PaperCardProps = {
  children: ReactNode;
  sx?: SxProps;
  width?: number;
  variant?: 'outlined' | 'elevation';
  pageCard?: boolean;
  pageCardBreakpoint?: 'sm' | 'md' | 'lg' | 'xl';
};

const PaperCard: FC<PaperCardProps> = ({
  children,
  sx,
  width = 500,
  variant = 'elevation',
  pageCard,
  pageCardBreakpoint = 'sm'
}) => {
  const paperCardSx: SxProps = {
    width: `${width}px`,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  };

  const styledPagePaperCard: SxProps = {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '500px',

    [theme.breakpoints.down(pageCardBreakpoint)]: {
      borderRadius: 0,
      width: '100%',
      height: '100%',
      boxShadow: 'none'
    }
  };

  const paperCardStyles = pageCard ? styledPagePaperCard : paperCardSx;

  return (
    <Paper
      className='paper-card'
      variant={variant}
      sx={{ ...paperCardStyles, overflow: 'hidden', boxSizing: 'border-box', ...sx }}
    >
      {children}
    </Paper>
  );
};

export default PaperCard;
