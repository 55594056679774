import { merge } from 'lodash';
import Skin from 'types/Skin';
import WindowConfig from 'types/WindowConfig';
import midtimilla from './midtimilla/midtimilla';
import malviknytt from './malviknytt/malviknytt';
import dypnyttSkin from './dypnytt/dypnytt';
import oyblikk from './oyblikk/oyblikk';
import avisalofoten from './avisalofoten/avisalofoten';
import hustadvikingen from './hustadvikingen/hustadvikingen';
import midsundingen from './midsundingen/midsundingen';
import vestnesavisa from './vestnesavisa/vestnesavisa';
import folldalsportalen from './folldalsportalen/folldalsportalen';
import storfjordnytt from './storfjordnytt/storfjordnytt';
import osthavet from './osthavet/osthavet';
import isolor from './isolor/isolor';
import avisahemnes from './avisahemnes/avisahemnes';
import aukranytt from './aukranytt/aukranytt';
import haramsnytt from './haramsnytt/haramsnytt';
import sulaposten from './sulaposten/sulaposten';

declare const window: WindowConfig;

const addThemeFavicon = (iconUrl: string) => {
  let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = iconUrl;
};

let skin: Skin;
switch (window.CONFIG.SKIN) {
  case 'midtimilla':
    skin = midtimilla;
    break;
  case 'malviknytt':
    skin = malviknytt;
    break;
  case 'oyblikk':
    skin = oyblikk;
    break;
  case 'avisalofoten':
    skin = avisalofoten;
    break;
  case 'hustadvikingen':
    skin = hustadvikingen;
    break;
  case 'midsundingen':
    skin = midsundingen;
    break;
  case 'vestnesavisa':
    skin = vestnesavisa;
    break;
  case 'folldalsportalen':
    skin = folldalsportalen;
    break;
  case 'storfjordnytt':
    skin = storfjordnytt;
    break;
  case 'osthavet':
    skin = osthavet;
    break;
  case 'isolor':
    skin = isolor;
    break;
  case 'avisahemnes':
    skin = avisahemnes;
    break;
  case 'aukranytt':
    skin = aukranytt;
    break;
  case 'haramsnytt':
    skin = haramsnytt;
    break;
  case 'sulaposten':
    skin = sulaposten;
    break;
  default:
    skin = dypnyttSkin;
    break;
}

skin = merge<Skin, Skin>(dypnyttSkin, skin);
addThemeFavicon(skin.LOGO.ICON);

export default skin;
export const { COLORS, LOGO } = skin;
