import Skin from 'types/Skin';

const isolor: Skin = {
  COLORS: {
    PRIMARY: '#c50023',
    PRIMARY_CONTRAST: '#ffffff',
    PRIMARY_DARK: '#840017',
    SECONDARY: '#000000',
    SECONDARY_CONTRAST: '#ffffff'
  },
  LOGO: {
    MAIN: 'https://dyplink.no/wp-content/uploads/2023/12/isolor-white.png',
    ICON: 'https://dyplink.no/wp-content/uploads/2023/12/isolor-icon.png'
  }
};

export default isolor;